<mat-card class="gtmt-card-container" [formGroup]="_CampagneTypologieService.EnveloppeFormGroup">
    <mat-card-title>Enveloppe</mat-card-title>

    <mat-card-content>
        <mat-grid-list cols="1" rowHeight="3.5rem" [gutterSize]="'1rem'">
            <mat-radio-group formControlName="CodeEnveloppe" aria-labelledby="dimension-radio-group-label"
                class="dimension-radio-group">
                <mat-grid-tile class="gtmt-checkbox-container" colspan="1" rowspan="0.5"
                    (click)="_CampagneTypologieService.EnveloppeFormGroup.get('CodeEnveloppe').setValue(dataEnveloppe.CodeEnveloppe)"
                    *ngFor="let dataEnveloppe of _CampagneTypologieService.dataEnveloppes">
                    <span> {{dataEnveloppe.enveloppe}}</span>
                    <mat-radio-button [value]="dataEnveloppe.CodeEnveloppe">
                    </mat-radio-button>
                </mat-grid-tile>
            </mat-radio-group>
        </mat-grid-list>
    </mat-card-content>

    <mat-card-actions>
        <button mat-raised-button color="blue" (click)="Valider()">
            Valider la sélection
        </button>
    </mat-card-actions>

</mat-card>