<mat-card class="gtmt-card-container" [formGroup]="personalisationSfService.form">
  <mat-card-title>Champs de personnalisation</mat-card-title>
  <mat-card-content>
    <mat-grid-list cols="2" rowHeight="2rem" [gutterSize]="'0.5rem'" style="width:100%">


      <!-- Liste de Champs de personnalisation -->

      <mat-grid-tile colspan="1" rowspan="4">
        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Liste de Champs de personnalisation</mat-label>
          <input #sharedListNameRef matInput formControlName="sharedListName"
            placeholder="Liste de Champs de personnalisation" type="text" [matAutocomplete]="autoListeCF">
          <button matSuffix mat-icon-button aria-label="Clear">
            <mat-icon svgIcon="Loupe"></mat-icon>
          </button>
          <mat-autocomplete autoActiveFirstOption #autoListeCF="matAutocomplete">
            <mat-option *ngFor="let sharedList of personalisationSfService.sharedLists" [value]="sharedList.dataExtensionName"
              (click)="optionSelected(sharedList)">
              {{ sharedList.dataExtensionName }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile colspan="1" rowspan="4">
      </mat-grid-tile>

      <!-- Champs de personnalisation -->

      <mat-grid-tile colspan="1" rowspan="4">
        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Champs de personnalisation</mat-label>
          <input #champFusionRef ma matInput formControlName="champFusion" placeholder="Champs de personnalisation"
            type="text">
          <button matSuffix mat-icon-button aria-label="Clear">
            <mat-icon svgIcon="Loupe"></mat-icon>
          </button>
        </mat-form-field>
      </mat-grid-tile>


      <mat-grid-tile colspan="1" rowspan="4">
      </mat-grid-tile>

      <mat-grid-tile colspan="1" rowspan="16">
          <div cdkDropList #filteredAvailableChampsFusionRef="cdkDropList" [cdkDropListData]="personalisationSfService.filteredAvailableChampsFusion"
            class="gtmt-drop-list" [cdkDropListConnectedTo]="[selectedChampsFusionRef]"
            (cdkDropListDropped)="drop($event)">
            <cdk-virtual-scroll-viewport appendOnly itemSize="60">
              <div class="gtmt-drop-box" *ngFor="let champFusion of personalisationSfService.filteredAvailableChampsFusion" cdkDrag>
                <li>
                  {{champFusion.ChampFusion}}
                </li>
                <button mat-raised-button color="blue" (click)="selectChampFusion(champFusion)">Ajouter</button>
                <div class="gtmt-drop-box" *cdkDragPreview [src]="champFusion.ChampFusion"
                  [alt]="champFusion.ChampFusion">
                  <li>
                    {{champFusion.ChampFusion}}
                  </li>
                </div>
              </div>
            </cdk-virtual-scroll-viewport>
          </div>
      </mat-grid-tile>


      <mat-grid-tile colspan="1" rowspan="16">
          <div cdkDropList #selectedChampsFusionRef="cdkDropList" [cdkDropListData]="personalisationSfService.selectedChampsFusion"
            class="gtmt-drop-list" [cdkDropListConnectedTo]="[filteredAvailableChampsFusionRef]"
            (cdkDropListDropped)="drop($event)">
            <cdk-virtual-scroll-viewport appendOnly itemSize="60">

              <div class="gtmt-drop-box gtmt-champs-selected" *ngFor="let champFusion of personalisationSfService.selectedChampsFusion" cdkDrag>
                <li>
                  {{champFusion.ChampFusion}}
                </li>
                <mat-icon  (click)="unselectChampFusion(champFusion)">close</mat-icon>
              </div>
              <div class="gtmt-drop-box gtmt-champs-glisser">
                <li>
                  <mat-icon svgIcon="Updown"></mat-icon>
                  Glisser - Déposer pour trier la sélection
                </li>
              </div>
            </cdk-virtual-scroll-viewport>
          </div>
      </mat-grid-tile>

    </mat-grid-list>
  </mat-card-content>


  <mat-card-actions align="end">
    <mat-grid-list cols="2" rowHeight="4rem" [gutterSize]="'2.5rem'" style="width:100%">

      <mat-grid-tile colspan="1" rowspan="1">
      </mat-grid-tile>

      <mat-grid-tile colspan="1" rowspan="1">
        <button mat-raised-button color="blue" (click)="Vider()">
          Vider
        </button>
        <!-- <button mat-raised-button color="blue" (click)="ValiderSelection()">
          Valider la sélection
        </button> -->
      </mat-grid-tile>
      
    </mat-grid-list>
  </mat-card-actions>

</mat-card>

<!-- Stepper navigation  -->
<mat-grid-list class="gtmt-stepper-nav" cols="2" rowHeight="10rem" [gutterSize]="'5rem'">
  <mat-grid-tile class="gtmt-jc-end" colspan="1" rowspan="1">
    <button mat-raised-button color="white" matStepperPrevious>
      Etape précédente
    </button>
  </mat-grid-tile>
  <mat-grid-tile class="gtmt-jc-start" colspan="1" rowspan="1">
    <button mat-raised-button color="yellow" (click)="submit()">
      Etape suivante
    </button>
  </mat-grid-tile>
</mat-grid-list>