<!-- Option selection -->
<div class="option">
  <app-toolbar-title title="Chargement de votre fichier de ciblage via SFTP" type="radio"
    [radioCheck]="importOption === 'sftp'" [stateFromInput]="true" icon="information"
    (radioState)="importSelection('sftp')">
    <ng-container tooltip>
      Cette fonction permet de charger vos fichiers de ciblage dans la campagne
      Cap Mailing via SFTP
    </ng-container>
  </app-toolbar-title>
  <app-toolbar-title title="Téléchargement de votre fichier de personnalisation et de ciblage" type="radio"
    [radioCheck]="importOption === 'upload'" [stateFromInput]="true" icon="information"
    (radioState)="importSelection('upload')">
    <ng-container tooltip>
      Le fichier attendu doit être en format .CSV encodé UTF8. La première ligne
      de votre fichier doit contenir les noms de colonnes avec comme séparateur
      de colonne le caractère suivant <strong>;</strong> . Votre fichier doit
      également contenir les champs obligatoires suivants ; nom, prénom, les
      champs d’adresses, le code postale et la ville.
    </ng-container>
  </app-toolbar-title>
</div>

<!-- Upload -->
<mat-card *ngIf="importOption == 'upload'">
  <h2>
    <span>Fichiers de ciblage</span>
    <mat-icon svgIcon="question" [mdePopoverTriggerFor]="appPopover3" mdePopoverTriggerOn="hover"
      #popoverTrigger="mdePopoverTrigger" style="cursor: pointer"></mat-icon>
  </h2>
  <mat-card-content>
    <mat-card-tile class="gtmt-add-file">
      <span>
        {{ csvFileName || "Fichiers de ciblage format .CSV UTF8" }}
      </span>
      <input type="file" id="file-upload-perso-standalone" style="display: none" (change)="uploadFile($event)"
        accept=".csv" />
      <button mat-raised-button color="blue" *ngIf="csvFileName == undefined" (click)="addFile()">
        Ajouter un fichier
        <mat-icon>control_point</mat-icon>
      </button>
      <button mat-raised-button color="blue" *ngIf="csvFileName" (click)="deleteFile()">
        Supprimer le fichier
      </button>
    </mat-card-tile>
    <mat-card-tile class="gtmt-deleted" *ngIf="isDeleted == true" style="font-size: 1.6rem; font-weight: 700">
      Fichier supprimé avec succès !
    </mat-card-tile>
    <!-- <mat-card-tile class="gtmt-warning" *ngIf="csvFileName == undefined">
      <mat-icon>control_point</mat-icon>
      En sélectionnant cette option, seules les campagnes en “envoi simple” sont
      disponibles.
    </mat-card-tile> -->
    <div *ngIf="csvHeaders && csvRows">
      <app-tab-visualisation [headers]="csvHeaders" [rows]="csvRows"></app-tab-visualisation>
    </div>

    <!-- Personnalisation -->
    <app-campagne-personnalisation *ngIf="csvFileName"></app-campagne-personnalisation>
  </mat-card-content>
</mat-card>

<!-- Popover messages -->
<mde-popover #appPopover3="mdePopover" [mdePopoverOverlapTrigger]="false">
  <mat-card class="gtmt-popover-card">
    <mat-card-content> &nbsp; </mat-card-content>
  </mat-card>
</mde-popover>

<!-- Loader overlay on upload -->
<mat-spinner-overlay [overlay]="true" *ngIf="isUploading || isDeleting">
</mat-spinner-overlay>

<!-- Stepper navigation  -->
  <mat-grid-list class="gtmt-stepper-nav" cols="2" rowHeight="10rem" [gutterSize]="'5rem'">
    <mat-grid-tile class="gtmt-jc-end" colspan="1" rowspan="1">
      <button mat-raised-button color="white" matStepperPrevious>
        Etape précédente
      </button>
    </mat-grid-tile>
    <mat-grid-tile class="gtmt-jc-start" colspan="1" rowspan="1">
      <button mat-raised-button color="yellow" (click)="submit()">
        Etape suivante
      </button>
    </mat-grid-tile>
  </mat-grid-list>