<!-- Créations -->

<mat-toolbar>
  <h1> {{ title }}</h1>
  <!-- <mat-icon svgIcon="info1"></mat-icon> -->
  <mat-icon svgIcon="Infos" [mdePopoverTriggerFor]="appPopover" mdePopoverTriggerOn="click"
    #popoverTrigger="mdePopoverTrigger" style="cursor: pointer;">
  </mat-icon>

</mat-toolbar>

<!-- <cdk-virtual-scroll-viewport class="gtmt-virtual-scroll-viewport-main" autosize itemSize="50"> -->


<!-- Votre sélection -->
<app-typologie-card>
</app-typologie-card>


<!-- Fichiers de créations -->

<app-fichiers-creations></app-fichiers-creations>

<!-- Fichiers de personnalisations -->

<app-fichiers-personnalisations></app-fichiers-personnalisations>

<!-- </cdk-virtual-scroll-viewport> -->



<!-- Stepper navigation  -->
<mat-grid-list class="gtmt-stepper-nav" cols="2" rowHeight="10rem" [gutterSize]="'5rem'">
  <mat-grid-tile class="gtmt-jc-end" colspan="1" rowspan="1">
    <button mat-raised-button color="white" matStepperPrevious>
      Etape précédente
    </button>
  </mat-grid-tile>
  <mat-grid-tile class="gtmt-jc-start" colspan="1" rowspan="1">
    <button mat-raised-button color="yellow" (click)="submit()">
      Etape suivante
    </button>
  </mat-grid-tile>
</mat-grid-list>

<mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false">
  <mat-card class="gtmt-popover-card">
    <mat-card-header> Point d’information
      <mat-icon svgIcon="Infos"></mat-icon>
    </mat-card-header>
    <mat-card-content>
      <p>
        Veuillez joindre tous les éléments nécessaires à l’impression de votre commande courrier :
      </p>

      <p>
        <li>
          fichiers haute définition (PDF HD, 600 DPI),
        </li>
        <li>
          fichiers basse définition avec repères pour la personnalisation,
        </li>
        <li>
          fichiers sources (type InDesign),
        </li>
        <li>
          polices de caractères (OTF, TTF) si nécessaire
        </li>
        <li>
          images en haute définition
        </li>
      </p>

      <p>
        Merci de vous assurer que vous avez bien chargé les fichiers concernant l’enveloppe.
        Consultez <span (click)="openHelp('new_campaign_step3')" style="cursor: pointer;"><strong>l’Aide</strong> </span> pour obtenir plus
        d’informations.
      </p>

      <p>
        Consultez <span (click)="openHelp('new_campaign_step3')" style="cursor: pointer;"><strong>l’Aide</strong> </span> pour obtenir plus
        d’informations sur le guide de fabrication, le modèle de message pour l’équipe de production et le simulateur de
        tarif.
      </p>
    </mat-card-content>
  </mat-card>
</mde-popover>