<mat-card>
    <mat-card-title>{{statutCommande.Title}}</mat-card-title>
    <mat-card-content
    [ngClass]="{
        'gtmt-border-dashed-bleu': statutCommande.Title === 'En cours',
        'gtmt-border-bleu-clair': statutCommande.Title === 'A venir'
        }">
        <mat-grid-list cols="1" rowHeight="8.5rem">
            <mat-grid-tile class="gtmt-ai-start gtmt-jc-space-arround gtmt-fd-col"  rowspan="1">
                <span class="gtmt-type" *ngIf="statutCommande.codeStatut !='BAT_AVAILABLEOK'">
                    Production
                </span>
                <span class="gtmt-type" *ngIf="statutCommande.codeStatut ==='BAT_AVAILABLEOK'">
                    Bon à tirer
                </span>
                <span class="gtmt-statut">
                    {{statutCommande.StatutLibelle }}
                </span>
            </mat-grid-tile>
            <mat-grid-tile class="gtmt-jc-space-between" rowspan="1">
                <span class="gtmt-date">
                    Date
                </span>
                <span>
                    {{ statutCommande.dateCreation != 'A venir' ? (statutCommande.dateCreation  | date :"dd/MM/yyyy") : 'A venir' }}
                </span>
            </mat-grid-tile>
            <mat-grid-tile class="gtmt-ai-end gtmt-jc-end" rowspan="1">
              <span *ngIf="(!_GlobalDataService.Campagne.isPurged)">
                <button mat-raised-button color="blue" *ngIf="statutCommande.codeStatut=='BAT_AVAILABLEOK'" (click)="VisualiserBAT()">
                    Voir
                </button>
              </span>

              <span *ngIf="(_GlobalDataService.Campagne.isPurged)" [mdePopoverTriggerFor]="purgePopover" (mouseenter)="$event.stopPropagation()" style="cursor:pointer">
                <button mat-raised-button color="blue" *ngIf="statutCommande.codeStatut=='BAT_AVAILABLEOK'" [disabled]="true">
                    Voir
                </button>
              </span>

            </mat-grid-tile>
        </mat-grid-list>
    </mat-card-content>
</mat-card>

<mde-popover #purgePopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdeFocusTrapAutoCaptureEnabled]="false" mdePopoverPositionY="above">
  <mat-card class="gtmt-popover-card">
    Le BAT a été purgé
  </mat-card>
</mde-popover>
