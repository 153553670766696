import { Enveloppe } from './enveloppe.model'
import { Support } from './support.model'
import { AdresseRetour } from './adresseRetour.model'
import { ChampsFusion } from './champsFusion.model'
import { FichiersCreation } from './FichiersCreation.model'
import { Annonceur } from './annonceur.model'
import { Adresse } from './adresse.model'
import { AnyMxRecord } from 'dns'

export class Campagne {
    IdAnnonceur: string ="";
    EloquaInstallId: string = "";
    EloquaInstanceId: string = "";
    EloquaSiteId: string ="";
    EloquaSiteName: string = "";
    EloquaAppId: string = "";

    oauth_consumer_key: string = "";
    oauth_nonce: string = "";
    oauth_signature: string = "";
    oauth_signature_method: string = "";
    oauth_timestamp: string = "";
    oauth_version: string = "";
    user: string = "";
    username: string = "";
    AssetId: string = "";

    IdCampagne?: string;
    IdProduit?: string;
    IdAdresse?: any;//string or number
    IdCampagneCopie?: string;
    NomCampagneParente?: string;
    NomCampagne: string;
    Description?: string;
    IdStatut?: string;//response : IdStatutCampagne
    CreeParLogin?: string;
    DateCreation?: string;
    ModifieParLogin?: string;
    DateModification?: string;
    BonDeCommande?: string;

    CodeTypologie?: string;
    CodeSupport?: string;
    CodeDimensionsSupport?: string;
    CodeGrammageSupport?: string;
    CodeFinitionSupport?: string;
    CodeEncreSupport?: string;

    CodeEnveloppe?: string;
    CodeDimensionsEnveloppe?: string;
    CodeGrammageEnveloppe?: string;
    CodeFinitionEnveloppe?: string;
    CodeEncreEnveloppe?: string;

    CodeProduitAdditionnel?: string;
    CodeVitesse?: string;
    CodeZoneDestination?: string;
    CodeModeProduction?: string;
    DateRemiseImprimeur?: any;
    DateTheoriqueDepotLaPoste?: any;

    FrequenceEnvoi?: string;//une seule fois/récurrente
    Recurrence?: string; //Quotidienne/Hebdomadaire/Mensuelle
    DetailRecurrence?: string;   //Jours de la semaine... /Le premier Lundi du mois/Le dernier Lundi du mois
    DateDeDebut?: any;
    DateDeFin?: any;

    Alliage?: boolean;
    EmailNotification?: string;
    URLValidationBAT?: string;


    LoginBAT?: string;
    MotDePasseBAT?: string;
    BATValide?: string;
    DateValidationBAT: any;


    // FichiersCreation: Array<File>;
    FichiersCreation?: Array<FichiersCreation>;
    NbFichiersCreation: number;
    NbFichiersPersonnalisation: number;
    NbFichiersLogo: Number;
    ChampsFusion?: Array<ChampsFusion>;
    Produit?: any;
    Adresse?: AdresseRetour;
    ExtensionId?: string;

    Support?: Support;
    Enveloppe?: Support;
    GTMTCommande?: string;
    /**
     * Champs calculés
     */
    Nbre_commandes?: string;
    Nbre_courriers?: number;
    Nbre_PND?: number;
    StatutCampagne?: string;//le statut de la campagne//lecture seule
    CodeStatut?: string; // mapped via statutConfig WHERE statutConfig.Entite = "Campagne"
    StatutCampagneLibelle!: string // found via CodeStatut

    VisualisationAdresse?: boolean;
    VisualisationStep2?: boolean;

    CommentaireAnnulation?: string;

    volume: string;
    unitAmountWithoutTax: string = "";
    amountWithoutTax: string = "";
    showInfo?: boolean = false;
    showRefresh?: boolean = false;
    showunitAmountWithoutTax?: boolean = false;
    showamountWithoutTax?: boolean = false;

    completedSteps = [];

    selectedAdresses = new Array<AdresseRetour>();
    FichiersCreationsURL = {};
    FichiersCreationsType = {}

    OptionRetour: string;

    Percentage: string = "0";

    isDirty: boolean = false;

    // Creation Type
    creationType = 'upload';

    // Standalone: Import type and file
    importOption: 'sftp' | 'upload';
    importFile: string;
    isPurged: boolean;

    constructor(Annonceur?: Annonceur) {
        this.FichiersCreation = [];
        this.ChampsFusion = new Array<ChampsFusion>();
        this.NomCampagne = "";
        this.Description = "";
        this.EmailNotification = "";
        this.AssetId = "";
        this.volume = "";

        if (Annonceur) {
            this.IdAnnonceur = Annonceur.IdAnnonceur;
            this.EloquaInstallId = Annonceur.EloquaInstallId;
            this.EloquaInstanceId = Annonceur.EloquaInstanceId;
            this.EloquaSiteId = Annonceur.EloquaSiteId;
            this.EloquaSiteName = Annonceur.EloquaSiteName;
            this.EloquaAppId = Annonceur.EloquaAppId;

            this.oauth_consumer_key = Annonceur.oauth_consumer_key;
            this.oauth_nonce = Annonceur.oauth_nonce;
            this.oauth_signature = Annonceur.oauth_signature;
            this.oauth_signature_method = Annonceur.oauth_signature_method;
            this.oauth_timestamp = Annonceur.oauth_timestamp;
            this.oauth_version = Annonceur.oauth_version;
            this.user = Annonceur.user;
            this.username = Annonceur.username;
        }
    }



}



export enum isteps {
    'identification' = 0,
    'typologie' = 1,
    'creations' = 2,
    'personalisation' = 3,
    'planification' = 4,
    'adresses' =  5,
    'recapitulatif' = 6
}


export enum isteps_standalone {
    'identification' = 0,
    'typologie' = 1,
    'creations' = 2,
    'personalisation' = 3,
    'planification' = 4,
    'adresses' =  5,
    'recapitulatif' = 6
}
