import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  AdressesDestinatairesService,
  GlobalDataService,
  PersonalisationService,
} from '@core/services';
import { MatStepper } from '@angular/material/stepper';
import { CampagneService } from '@core/services/campagne.service';
import { FichierPersoStandaloneService } from '@core/services/fichier-perso-standalone.service';
import { ChampsFusionService } from '@core/services/champs-fusion.service';
import { ChampsFusion } from '@core/models/champsFusion.model';
import { Subscription, skip } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-campagne-personnalisation-standalone',
  templateUrl: './campagne-personnalisation-standalone.component.html',
  styleUrls: ['./campagne-personnalisation-standalone.component.scss'],
})
export class CampagnePersonnalisationStandaloneComponent
  implements OnInit, OnDestroy
{
  importOption: 'sftp' | 'upload';

  isUploading: boolean = false;
  isDeleting: boolean = false;
  isDeleted: boolean = false;
  persoStandaloneForm: FormGroup;
  segmentFileCSV: any;

  csvFileName: string;
  csvHeaders: string[] = undefined;
  csvRows: any[];

  availableStandaloneChampsFusion: Array<any> = [];
  private obsUpdated: Subscription;

  constructor(
    private _CampagneService: CampagneService,
    public _GlobalDataService: GlobalDataService,
    private _FichierPersoStandalone: FichierPersoStandaloneService,
    private _ChampsFusionService: ChampsFusionService,
    public personalisationService: PersonalisationService,
    public adressesDestinatairesService: AdressesDestinatairesService,
    private _stepper: MatStepper
  ) {
    this.persoStandaloneForm = new FormGroup({
      file: new FormControl('', Validators.required),
    });
  }

  public ngOnInit(): void {
    this._GlobalDataService.availableEloquaChampsFusion = [];
    this.personalisationService.updateChampFusion();

    // Initial load
    this.loadData();

    // Subscribe to updated campaign
    this.obsUpdated = this._GlobalDataService.changedCampagneObs$
      .pipe(skip(1))
      .subscribe((_) => {
        this.importOption = this._GlobalDataService.Campagne.importOption;
        this.loadData();
      });
  }

  public ngOnDestroy(): void {
    this.obsUpdated?.unsubscribe();
    this.emptyChampsFusion();
  }

  public addFile() {
    const inputFile = this.getInputFile();
    if (inputFile) {
      inputFile.click();
    }
  }

  public uploadFile(event: Event) {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const inputFile = this.getInputFile();
    this.isUploading = true;
    this.isDeleted = false;
    const segmentFileCSV = target.files[0];
    const IdCampagne = this._GlobalDataService.Campagne.IdCampagne;
    this._FichierPersoStandalone.upload(segmentFileCSV, IdCampagne).subscribe({
      next:  (res: { importedLines: number }) => {
        this.isUploading = false;
        this.csvFileName = segmentFileCSV.name;
        this.getFile(false);

        // Initialiser champs fusion sur personnalisation
        this.personalisationService.updateChampFusion();

        // Initialiser champs fusion sur Pavé adresse
        this.adressesDestinatairesService.initialise();

        // MAJ Campagne
        this._GlobalDataService.Campagne.importFile = this.csvFileName;

        // Display number of lines imported 
        this._GlobalDataService.confirm({
          titre: "Fichier importé avec succès !",
          message: "Votre fichier comporte " + res.importedLines + " lignes."
        })
      },
      error: (e: HttpErrorResponse) => {
        this.isUploading = false;
        console.log(e);
        const error: { errors?: string[]; message?: string } = e.error;
        if (e.status == 400 && error.errors) {
          let formattedError =
            'Impossible de charger le document ' +
            target.files[0].name +
            '<br>';
          if (error.errors.length > 0) {
            formattedError +=
              'Attention ! Le fichier sélectionné contient des erreurs :<br><ul>';
            for (const message of error.errors) {
              formattedError += '<li class="truncate">' + message + '</li>';
            }
            formattedError += '</ul>';
          }
          formattedError += '<br>Veuillez télécharger un autre fichier.';
          this._GlobalDataService.alert(formattedError);
        } else {
          this._GlobalDataService.alert(
            "Une erreur technique s'est produite."
          );
        }
        inputFile.value = '';
      },
    });
  }

  public getFile(isInit: boolean) {
    const IdCampagne = this._GlobalDataService.Campagne.IdCampagne;
    this._FichierPersoStandalone.readContact(IdCampagne).subscribe({
      next: (response) => {
        this.csvFileName = response['fileName'];
        this.readCsv(response['data']);
      },
      error: (e) => {
        console.log(e);
        if (!isInit) {
          this._GlobalDataService.alert(
            "Une erreur technique s'est produite."
          );
          this.isDeleted = false;
        }
      },
    });
  }

  public deleteFile() {
    const onSuccess = () => {
      this.deleteFileCall(this.importOption);
    };

    this._GlobalDataService.confirm({
      titre: 'Supprimer?',
      message:
        'Votre configuration sera perdue, êtes-vous sûr de vouloir supprimer votre fichier ?',
      BouttonConfirmation: true,
      onConfirm: onSuccess.bind(this),
      onCancel: () => console.log('onCancel'),
    });
  }

  public importSelection(selectedOption: 'sftp' | 'upload') {
    // Display confirm message if try to change from upload to sftp option when already uploaded
    if (
      this.importOption === 'upload' &&
      this.csvFileName &&
      selectedOption === 'sftp'
    ) {
      const onSuccess = () => {
        this.deleteFileCall(selectedOption);
        this._GlobalDataService.Campagne.importOption = selectedOption;
        this.updateCampagne();
      };

      this._GlobalDataService.confirm({
        titre: '',
        message:
          "Votre configuration sera perdue, êtes-vous sûr de vouloir changer d’option ?",
        BouttonConfirmation: true,
        onConfirm: onSuccess.bind(this),
        onCancel: () => console.log('onCancel'),
      });

      return;
    }

    // Else keep update the import option
    this.importOption = selectedOption;
    this._GlobalDataService.Campagne.importOption = selectedOption;
    this.updateCampagne();

    /* 
      Bugfix: après duplication des contacts, le fichier ne s'affiché
      pas dans la fenêtre Personnalisation.
    */
    if (this._GlobalDataService.Campagne.importFile) {
      this.getFile(true);
    }
  }

  private getInputFile(): HTMLInputElement {
    return document.getElementById(
      'file-upload-perso-standalone'
    ) as HTMLInputElement;
  }

  private readCsv(data: Object) {
    if (!data) {
      return;
    }
    let line = 0;
    let headers = [];
    let rows = [];
    for (const rowIndex in data) {
      line++;
      if (line === 1) {
        headers = headers.concat(['N°'], data[rowIndex]);
      } else {
        let csvRow = {};
        csvRow['N°'] = rowIndex;
        for (let i = 1; i < headers.length; i++) {
          csvRow[headers[i]] = data[rowIndex][i - 1];
        }
        rows.push(csvRow);
      }
    }
    this.csvHeaders = headers;
    this.csvRows = rows;
  }

  private deleteFileCall(selectedOption) {
    this.isDeleting = true;
    const IdCampagne = this._GlobalDataService.Campagne.IdCampagne;
    this._FichierPersoStandalone.delete(IdCampagne).subscribe({
      next: (_) => {
        // Reinit values
        const inputFile = this.getInputFile();
        inputFile.value = '';
        this.csvFileName = undefined;
        this.csvHeaders = undefined;
        this.csvRows = undefined;
        this.isDeleted = true;

        // Update selectedOption
        this.importOption = selectedOption;

        // Empty champs fusion
        this.emptyChampsFusion();

        // Update DB
        this.ValiderSelection();

        // Suprimer pavé adresse
        this.adressesDestinatairesService.deletePaveAdresse();

        this.isDeleting = false;
      },
      error: (e) => {
        console.log(e);
        this._GlobalDataService.alert("Une erreur techniques s'est produite.");
        this.isDeleting = false;
      },
    });
  }

  private updateCampagne() {
    this._CampagneService.put(this._GlobalDataService.Campagne).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  ValiderSelection() {
    // this.personalisationService.setChampFusionType();
    this.personalisationService.selectedChampsFusion.forEach((value, index) => {
      console.log(value.ChampFusion + ' : ' + index);
      this.personalisationService.selectedChampsFusion[index].Sequence = index;
      this.personalisationService.selectedChampsFusion[index].IsPaveAdresse =
        'false';
    });

    this._GlobalDataService.CampagneExt.ChampsFusion =
      this.personalisationService.selectedChampsFusion.concat(
        this.adressesDestinatairesService.selectedChampsFusion
      );

    console.log(this._GlobalDataService.CampagneExt.ChampsFusion);

    // call API
    this._ChampsFusionService
      .upsert({
        IdCampagne: this._GlobalDataService.Campagne.IdCampagne,
        ChampsFusion: this._GlobalDataService.CampagneExt.ChampsFusion,
      })
      .subscribe({
        next: () => {
          console.log('Done');
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  emptyChampsFusion() {
    this._GlobalDataService.availableEloquaChampsFusion = [];
    this.personalisationService.filteredAvailableChampsFusion = [];
    this.personalisationService.selectedChampsFusion = [];
  }

  submit() {
    this.ValiderSelection();
    this._stepper.next();
  }

  private loadData() {
    this.importOption = this._GlobalDataService.Campagne.importOption;
    if (this._GlobalDataService.Campagne.importFile) {
      this.getFile(true);
    }
  }
}
