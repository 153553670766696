import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { GlobalDataService, PopoutService } from '@core/services';

declare const window: any;

@Component({
  selector: 'app-message-confirmation',
  templateUrl: './message-confirmation.component.html',
  styleUrls: ['./message-confirmation.component.scss']
})
export class MessageConfirmationComponent implements OnInit {
  titre = "Confirmation";
  NomCampagne?: string;
  
  context = "Eloqua";

  constructor(private activeRoute: ActivatedRoute, 
     public _GlobalDataService: GlobalDataService,
     private popoutService: PopoutService
  ) {
    this.activeRoute.paramMap.subscribe((params: ParamMap) => {
      this.NomCampagne = params.get('NomCampagne') as string;
    });

    if (this._GlobalDataService.CONTEXT)
      this.context = this._GlobalDataService.CONTEXT.charAt(0) + this._GlobalDataService.CONTEXT.slice(1).toLowerCase();
  }

  ngOnInit(): void {
  }

  closePopOut() {
    window.top.close();

  }

}
