<h2 mat-dialog-title>{{data.titre}}</h2>
<mat-dialog-content class="mat-typography">

  <div [innerHTML]="trustHtml(data.message)"></div>
  <br>

  <!-- voir le détail -->
  <ng-container *ngIf="data.errorMessage">
    <button mat-raised-button color="blue" style="margin-bottom:2rem" (click)="toggle(errorMessage)">Voir le détail</button>
    
    <div class="hidden" #errorMessage  [innerHTML]="trustHtml(data.errorMessage)"></div>
  </ng-container>



</mat-dialog-content>
<div mat-dialog-actions align="end">
  <button *ngIf="data.BouttonConfirmation" mat-raised-button mat-dialog-close (click)="onCancel()">{{ data.cancelMsg || "Annuler" }}</button>
  <button *ngIf="data.BouttonConfirmation" mat-raised-button [mat-dialog-close]="true" cdkFocusInitial (click)="onConfirm()">{{ data.confirmMsg || "Je confirme" }}</button>
  <button *ngIf="!data.BouttonConfirmation" mat-raised-button [mat-dialog-close]="true" cdkFocusInitial (click)="onConfirm()">OK</button>
</div>
