import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { NavigationEnd, Router } from '@angular/router';
import { FichierCreaService } from '@core/services/fichier-crea.service';

import { GlobalDataService } from '@core/services/global-data.service';
import { ProduitService } from '@core/services/produit.service';
import { HelpComponent } from '../help/help.component';

@Component({
  selector: 'app-campagne-creation',
  templateUrl: './campagne-creation.component.html',
  styleUrls: ['./campagne-creation.component.scss']
})
export class CampagneCreationComponent implements OnInit {

  title: string = 'Créations';
  
  // Max file size allowed for uploading creation file
  public static MAX_SIZE_FILE = 1073741824;

  navigationSubscription;

  constructor(private router: Router,
    private _stepper: MatStepper,
    public _GlobalDataService: GlobalDataService,
    public _HelpComponent: HelpComponent
  ) {

    // subscribe to the router events. Store the subscription so we can
    // unsubscribe later.
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        // this.initialise();
      }
    });

  }


  ngOnInit(): void {
  }



  submit() {
    //call API
    this._stepper.next();
  }

  openHelp(signet: string) {
    this._GlobalDataService.referenceDocHelp = "#" + signet;
    this._HelpComponent.OpenHelpFile();
  }  

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

}
