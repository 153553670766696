import { Support } from './support.model'
import { AdresseRetour } from './adresseRetour.model'
import { ChampsFusion } from './champsFusion.model'
import { FichiersCreation } from './FichiersCreation.model'
import { Commande } from './commande.model';
import { StatutCommande } from './statut-commande.model';

export class CampagneExt {
    IdCampagne?: string;

    // FichiersCreation: Array<File>;
    FichiersCreation?: Array<FichiersCreation>;
    NbFichiersCreation: number;
    NbFichiersPersonnalisation: number;
    NbFichiersLogo: Number;
    ChampsFusion?: Array<ChampsFusion>;
    Produit?: any;
    Adresse?: AdresseRetour;
    ExtensionId?: string;

    Support?: Support;
    Enveloppe?: Support;

    GTMTCommande?: string;

    /**
     * Champs calculés
     */
    Nbre_commandes?: string;
    Nbre_courriers?: number;
    Nbre_PND?: number;
    StatutCampagne?: string;//le statut de la campagne//lecture seule
    CodeStatut?: string; // mapped via statutConfig WHERE statutConfig.Entite = "Campagne"
    StatutCampagneLibelle!: string // found via CodeStatut

    VisualisationAdresse?: boolean;
    VisualisationStep2?: boolean;

    CommentaireAnnulation?: string;

    volume: string;
    completedSteps = [];

    selectedAdresses = new Array<AdresseRetour>();
    FichiersCreationsURL = {};
    FichiersCreationsType = {}

    OptionRetour: string;
    isDirty: boolean = false;

    CurrentCommande: Commande;
    CurrentStatutCommande: StatutCommande;
    currentStatutCommandeArray =  new Array<StatutCommande>();
    Commandes = new Array<Commande>();

    VitessesProduit: Array<string>;

}


