<mat-card [formGroup]="form">
    <mat-card-header>
        <mat-card-title>
            Connectez vous à votre espace<br>Cap Mailing Plateforme
        </mat-card-title>
        <mat-card-subtitle>
            Veuillez entrer vos identifiants de connexion pour accéder au service Cap Mailing Plateforme
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <mat-form-field appearance="fill" floatLabel="always">
            <mat-label>Utilisateur</mat-label>
            <input matInput placeholder="dupont" formControlName="loginName"
            (keydown.enter)="login()">
        </mat-form-field>
        <mat-form-field appearance="fill" floatLabel="always">
            <mat-label>Mot de passe</mat-label>
            <input matInput placeholder="........." formControlName="password" type="password"
            [type]="hide ? 'password' : 'text'"
            (keydown.enter)="login()"
            >
            <mat-icon matSuffix (click)="myFunction()">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-hint align="end" (click)="ChangePassword()">Mot de passe oublié ?</mat-hint>

        </mat-form-field>        
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="blue" (click)="login()">Connexion</button>
    </mat-card-actions>
</mat-card>


