import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { NavigationEnd, Router } from '@angular/router';
import { Adresse } from '@core/models/adresse.model';
import { isteps, isteps_standalone } from '@core/models/campagne.model';
import { Criteria, EstimationRequest } from '@core/models/estimationRequest.model';
import { Support } from '@core/models/support.model';
import { AdressesDestinatairesService, AdressesDestinatairesSfService, GlobalDataService, PersonalisationService, PersonalisationSfService } from '@core/services';
import { CampagneService } from '@core/services/campagne.service';
import { EstimationService } from '@core/services/estimation.service';
import { ProduitService } from '@core/services/produit.service';
import { map, Observable, of, startWith, Subject, takeUntil } from 'rxjs';
import { DialogConfirmCampagneComponent, HelpComponent } from '..';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-campagne-recapitulatif',
  templateUrl: './campagne-recapitulatif.component.html',
  styleUrls: ['./campagne-recapitulatif.component.scss']
})
export class CampagneRecapitulatifComponent implements OnInit, OnDestroy {

  @Input() stepper!: MatStepper;

  title: string = 'Récapitulatif';

  isteps = isteps;

  isteps_standalone = isteps_standalone;

  navigationSubscription;

  support: Support = new Support();
  adresse: Adresse = null;

  ConfirmationForm!: FormGroup;

  campagne_confirmation!: boolean;

  public EmailNotificationForm!: FormGroup;
  public controls: any;

  emails = new Array<string>();

  filteredEmails: Observable<string[]>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  emailtCtrl = new FormControl("", [Validators.email]);
  addressNotifCtrl = new FormControl([this._GlobalDataService.EmailNotification]);


  @ViewChild('emailInput') emailInput: ElementRef<HTMLInputElement>;

  private destroy$ = new Subject<void>();

  constructor(private _formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private _CampagneService: CampagneService,
    public _GlobalDataService: GlobalDataService,
    public _HelpComponent: HelpComponent,
    public _ProduitService: ProduitService,
    public adressesDestinatairesSfService: AdressesDestinatairesSfService,
    public personalisationSfService: PersonalisationSfService,
    public adressesDestinatairesService: AdressesDestinatairesService,
    public personalisationService: PersonalisationService,
    private _EstimationService: EstimationService,
  ) {

    // subscribe to the router events. Store the subscription so we can
    // unsubscribe later.
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialise();
      }
    });

    this.filteredEmails = this.emailtCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => (fruit ? this._filter(fruit) : this.emails.slice())),
    );


  }

  initialise() {
    this.support.support = this._GlobalDataService.getLibelleProduit('CodeSupport', this._GlobalDataService.Campagne.CodeSupport, 'support')
    this.support.dimension = this._GlobalDataService.getLibelleProduit('CodeDimensionsSupport', this._GlobalDataService.Campagne.CodeDimensionsSupport, 'DimensionsSupport')
    this.support.grammage = this._GlobalDataService.getLibelleProduit('CodeGrammageSupport', this._GlobalDataService.Campagne.CodeGrammageSupport, 'GrammageSupport')
    this.support.finition = this._GlobalDataService.getLibelleProduit('CodeFinitionSupport', this._GlobalDataService.Campagne.CodeFinitionSupport, 'FinitionSupport')
    this.support.Encre = this._GlobalDataService.getLibelleProduit('CodeEncreSupport', this._GlobalDataService.Campagne.CodeEncreSupport, 'EncreSupport');
  }

  ngOnInit(): void {
    // if (this._GlobalDataService.isStandaloneContext() && !this._GlobalDataService.Campagne.EmailNotification)
    //   this._GlobalDataService.Campagne.EmailNotification = this._GlobalDataService.EmailNotification;

    this.ConfirmationForm = this._formBuilder.group({
      campagne_confirmation: new FormControl(this.campagne_confirmation, [
        Validators.required]),
    });

    this.controls = {
      EmailNotification: new FormControl(this._GlobalDataService.Campagne.EmailNotification, [
        Validators.required,
        Validators.email,
      ])
    }

    this.EmailNotificationForm = this._formBuilder.group(this.controls);

    if (this._GlobalDataService.Campagne.EmailNotification != '') {
      this.emails = this._GlobalDataService.Campagne.EmailNotification.split(',');
    }

    this.filteredEmails = of(this.emails);

    console.log(this.emails);

    //this.emailtCtrl.setValue(this._GlobalDataService.EmailNotification);

    this.initialise();
  }

  ngAfterViewInit() {
    this.controls.EmailNotification.valueChanges.subscribe((value: string) => {
      // if valid
      if (this.controls.EmailNotification.valid) {
        // update model
        this._GlobalDataService.Campagne.EmailNotification = value;
        this.saveCampagne();
      }
    });

    this.ConfirmationForm.get('campagne_confirmation').valueChanges.subscribe((value: boolean) => {
      // if valid
      if (this.ConfirmationForm.get('campagne_confirmation').valid) {
        // update model
        this.campagne_confirmation = value && !this._GlobalDataService.Campagne.isPurged;
      }
    });

  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    
    // Check if valid email
    if(value.length > 0 && !this.emailtCtrl.valid){
      return;
    }
    // Add new email
    if (value) {
      this.emails.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.emailtCtrl.setValue(null);

    this.filteredEmails = of(this.emails);

    this._GlobalDataService.Campagne.EmailNotification = this.emails.join(',');
    this._GlobalDataService.Campagne.isDirty = true;

  }

  remove(email: string): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
    }

    this._GlobalDataService.Campagne.EmailNotification = this.emails.join(',');
    this._GlobalDataService.Campagne.isDirty = true;

  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.emails.push(event.option.viewValue);
    this.emailInput.nativeElement.value = '';
    this.emailtCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.emails.filter(email => email.toLowerCase().includes(filterValue));
  }






  // addEmailFromInput(event: MatChipInputEvent) {
  //   if (event.value) {
  //     this.emails.add(event.value);
  //     event.chipInput!.clear();
  //   }
  // }

  // removeEmail(keyword: string) {
  //   this.emails.delete(keyword);
  // }


  saveCampagne() {
    //call API
    this._CampagneService.put(this._GlobalDataService.Campagne)
      .subscribe(
        response => console.log(response),
        error => console.error(error)
      );
  }

  submit() {
    //call API
    this.stepper.next();
  }

  gotoStep(index: number) {
    this.stepper.selectedIndex = index;
  }

  onConfirmClick(): any {
    let request: EstimationRequest = new EstimationRequest();

    request.custAccNumber = this._GlobalDataService.Annonceur.NumeroClient;
    request.contractNumber = this._GlobalDataService.Annonceur.NumeroContrat;
    request.quantity = "1";

    request.criteria = new Criteria();

    request.criteria.item_type = "ITE002";
    request.criteria.folding = "FOL016";
    request.criteria.printFormatValue = "PFV006";
    request.criteria.paperGrammageCode = "350";
    request.criteria.glossLevel = "GLO001";
    request.criteria.printingInkType = "INK001";
    request.criteria.windowEnvelope = "WIN005";
    request.criteria.envelopType = "NC001";
    request.criteria.envelope_paperGrammage = "NC";
    request.criteria.envelope_glossLevel = "GLO003";
    request.criteria.envelope_printingInkType = "INK002";
    request.criteria.deliveryPriority = "NUR001";
    request.criteria.campaignProductionMode = "CPM004";

    console.log(request);

    this._EstimationService.put(request)
      .subscribe(
        response => this.responseHandler(response),
        error => this.responseHandler(error)
      );


  }

  responseHandler(response: any) {
    console.log(response);

    if (typeof response.response === 'object' && response.response.status === 'SUCCESS') {
      this.validate();
    }
    else if (response.status === 500) {
      this._GlobalDataService.alert("Le serveur de vérification de La Poste est indisponible pour le moment. Veuillez réessayer ultérieurement.");
    }
    else if (response.response.errorCode === 'INVALID_CONTRACT') {
      this._GlobalDataService.alert("Le numéro de contrat saisi  " + this._GlobalDataService.Annonceur.NumeroContrat + "  est invalide");
    }
    else {
      this._GlobalDataService.alert("Le serveur de vérification de La Poste est indisponible La Poste pour le moment. Veuillez réessayer ultérieurement.");
    }
  }
  errorHandler(error: any) {
    console.log('errorHandler :' + error);
    return false;
  }


  validate(): any {
    // this._AuditLogService.add(this._GlobalDataService.Campagne.IdCampagne, 0, `Validation d'une campagne`).subscribe();

    if (this._GlobalDataService.didCampagneProductChange(this._GlobalDataService.Campagne)) {
      this._GlobalDataService.alert("Vous avez opté pour un autre produit, merci de remplacer les fichiers à envoyer en production.");
      this._GlobalDataService.campagneBeforeChanges = Object.assign({}, this._GlobalDataService.Campagne);
      return false;
    }

    /**
     * 05/10/2020 desactiver le contrôle en attendant la validation finale!
    if(this._GlobalDataService.Campagne.AssetId == ""){
      this._GlobalDataService.alert("Merci de sauvegarder la campagne Eloqua avant de confirmer la campagne Cap Mailing");
      return false;
    }
   */

    if (this._GlobalDataService.isEloquaContext()) {
      if (!this._GlobalDataService.objectParams.AssetId || this._GlobalDataService.objectParams.AssetId == "null" || this._GlobalDataService.objectParams.AssetId == "0000") {
        this._GlobalDataService.alert("Attention, votre canvas de campagne n’est pas enregistré côté Eloqua.<br>Merci de fermer cette fenêtre, d’enregistrer la campagne dans Eloqua puis de rouvrir l’étape CAP Mailing Plateforme pour pouvoir Envoyer la campagne.");
        return false;
      }
    }

    if (this._GlobalDataService.isSalesforceContext()) {
      if (!this._GlobalDataService.getinteractionIdSF()) {
        this._GlobalDataService.alert("Attention, votre campagne n’est pas enregistrée côté Salesforce.<br>Merci de fermer cette fenêtre, d’enregistrer la campagne dans Salesforce puis de rouvrir l’étape CAP Mailing Plateforme pour pouvoir Envoyer la campagne.");
        return false;
      }
    }

    console.log("this._GlobalDataService.nomCampagneIsValid", this._GlobalDataService.nomCampagneIsValid)
    //contrôle de la campagne
    if (!this._GlobalDataService.nomCampagneIsValid) {
      // this._GlobalDataService.alert("Le nom de la campagne '" + this._GlobalDataService.Campagne.NomCampagne + "' existe déjà.");
      // CTBEB-410 (2020-10-21 CFZ)
      this._GlobalDataService.alert("Le nom de la campagne est obligatoire et doit-être unique. Merci de renseigner un nouveau nom de campagne");

      return false;
    }
    if (!this._GlobalDataService.descriptionCampagneIsValid) {
      this._GlobalDataService.alert('La description de la campagne contient des caractères invalides (")');
      return false;
    }
    if (!this._GlobalDataService.refCampagneIsValid) {
      this._GlobalDataService.alert('La référence de la campagne contient des caractères invalides (")');
      return false;
    }
    if (this._GlobalDataService.Campagne.NomCampagne == "") {
      this._GlobalDataService.alert("Veuillez renseigner le nom de la campagne");
      return false;
    }

    if (this._GlobalDataService.Campagne.EmailNotification == "") {
      this._GlobalDataService.alert("Veuillez renseigner une adresse de notification");
      return false;
    }

    //contrôle du produit
    if (this._GlobalDataService.Campagne.IdProduit === undefined || this._GlobalDataService.Campagne.IdProduit == "" || this._GlobalDataService.Campagne.IdProduit == "0") {
      this._GlobalDataService.alert("Veuillez sélectionner un Support/Enveloppe");
      return false;
    }

    if (this._GlobalDataService.Campagne.Alliage && !this._GlobalDataService.logo) {
      this._GlobalDataService.alert("Veuillez fournir le logo (obligatoire) dans votre page de paramétrage à côté de votre numéro de contrat Alliage.");
      return false;
    }

    //controle de l'adresse
    if (!this._GlobalDataService.Campagne.Alliage && (!this._GlobalDataService.Campagne.IdAdresse || this._GlobalDataService.Campagne.IdAdresse == "0")) {
      this._GlobalDataService.alert("Veuillez sélectionner une adresse de retour.");
      return false;
    }

    //controle de logo
    // if (this._GlobalDataService.Campagne.Alliage && this._GlobalDataService.CampagneExt.NbFichiersLogo == 0) {
    //   this._GlobalDataService.alert("Veuillez sélectionner un logo de votre organisationr.");
    //   return false;
    // }

    //contrôle des fichiers créa
    if (this._GlobalDataService.CampagneExt.NbFichiersCreation == 0) {
      this._GlobalDataService.alert("Veuillez sélectionner au moins un fichier Création.");
      return false;
    }
    //contrôle des champs de fusion
    let isChampsFusionAvailableContext = !(this._GlobalDataService.isStandaloneContext() && this._GlobalDataService.Campagne.importOption ==='sftp');
    if (isChampsFusionAvailableContext && this._GlobalDataService.CampagneExt.ChampsFusion!.length == 0) {
      this._GlobalDataService.alert("Veuillez sélectionner au moins un champ de personnalisation");
      return false;
    }

    //contrôle de la fréquence d'envoi
    if (this._GlobalDataService.Campagne.FrequenceEnvoi == "Récurrente") {
      if ((!this._GlobalDataService.Campagne.DateDeDebut || this._GlobalDataService.Campagne.DateDeDebut == "null")
        ||
        (!this._GlobalDataService.Campagne.DateDeFin || this._GlobalDataService.Campagne.DateDeFin == "null")
      ) {
        this._GlobalDataService.alert("Merci de renseigner l'ensemble des champs de l'écran Planification");
        return false;
      }
    } else if (this._GlobalDataService.Campagne.FrequenceEnvoi == "Une seule fois") {
      if ((this._GlobalDataService.Campagne.DateRemiseImprimeur === undefined || this._GlobalDataService.Campagne.DateRemiseImprimeur == "null" || this._GlobalDataService.Campagne.DateRemiseImprimeur == "")) {
        this._GlobalDataService.alert("Merci de renseigner l'ensemble des champs de l'écran Planification");
        return false;

      }
    } else {
      this._GlobalDataService.alert("Merci de renseigner l'ensemble des champs de l'écran Planification");
      return false;
    }

    if (this.ConfirmationForm.value.campagne_confirmation !== true) {
      this._GlobalDataService.alert("Merci de confirmer que la campagne de courrier postal est de nature 'MARKETING PUBLICITAIRE'");
      return false;
    } else {
      const dialogRef = this.dialog.open(DialogConfirmCampagneComponent);

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);

        if (result === true) {
          // Save campaign
          this._CampagneService
          .put(this._GlobalDataService.Campagne)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (_) => {
              // Activate campaign
              this._CampagneService
              .activate(this._GlobalDataService.Campagne.IdCampagne)
              .pipe(takeUntil(this.destroy$))
              .subscribe({
                next: (res) => this.successResponse(res),
                error: (err) => this.errorResonse(err),
              });
            },
            error: (err) => this.errorResonse(err),
          });
          console.info(this._GlobalDataService.Campagne);
        }

      });
    }
  }

  successResponse(response: any) {
    console.log("Success!", response);
    this._GlobalDataService.Campagne.isDirty = false;
    const path = this._GlobalDataService.isStandaloneContext() ? '/standalone/home' : '/campagne/home';
    this.router.navigate([path, { outlets: { 'navview': ['message-confirmation', this._GlobalDataService.Campagne.NomCampagne] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }

  errorResonse(HttpErrorResponse: { error: { message: string; }; }) {
    console.error("Error!", HttpErrorResponse);
    if (HttpErrorResponse.error.message && HttpErrorResponse.error.message != "") {
      this._GlobalDataService.openSnackBarInfo("une erreur c'est produite : " + HttpErrorResponse.error.message);
    }
  }

  openHelpMarketing() {
    this._GlobalDataService.referenceDocHelp = "#advertising_marketing";
    this._HelpComponent.OpenHelpFile();
    return false;
  }

}
