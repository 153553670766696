<div
  *ngIf="!waitingForServer; then thenBlock; else elseBlock"
></div>
<ng-template #thenBlock>
  <button
    mat-raised-button
    class="gtmt-btn-save"
    color="blue"
    (click)="save()"
    [disabled]="campaignName === ''"
  >
    Enregistrer
  </button>
</ng-template>
<ng-template #elseBlock>
  <button mat-raised-button class="gtmt-btn-save" color="blue">
    <mat-progress-spinner
      mode="indeterminate"
      diameter="20"
      style="margin: 0 auto"
    ></mat-progress-spinner>
  </button>
</ng-template>
