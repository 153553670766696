import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';

import { GlobalDataService } from '@core/services';

@Component({
  selector: 'app-campagne-planification',
  templateUrl: './campagne-planification.component.html',
  styleUrls: ['./campagne-planification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampagnePlanificationComponent implements OnInit {


  constructor(public _GlobalDataService: GlobalDataService,
    private _stepper: MatStepper,
  ) {


  }

  ngOnInit(): void {
  }

  submit() {
    if (this._GlobalDataService.Campagne.CodeVitesse) {
      if (this._GlobalDataService.Campagne.FrequenceEnvoi == "Une seule fois") {
        if (!this._GlobalDataService.isValidDate(this._GlobalDataService.Campagne.DateRemiseImprimeur)) {
          this._GlobalDataService.alert("Merci de sélectionner la date de remise imprimeur");
          return;
        }
      }
      else if (this._GlobalDataService.Campagne.FrequenceEnvoi == "Récurrente") {
        if (this._GlobalDataService.Campagne.Recurrence == "") {
          this._GlobalDataService.alert("Merci de sélectionner une fréquence d'envoi")
          return;
        }
        if (!this._GlobalDataService.isValidDate(this._GlobalDataService.Campagne.DateRemiseImprimeur)) {
          this._GlobalDataService.alert("Merci de sélectionner la date de remise imprimeur");
          return;
        }
        if (this._GlobalDataService.Campagne.Recurrence == "Hebdomadaire") {
          if (this._GlobalDataService.Campagne.DetailRecurrence == null || this._GlobalDataService.Campagne.DetailRecurrence == "null") {
            this._GlobalDataService.alert("Merci de sélectionner le jour de la semaine");
            return;
          }
        } else if (this._GlobalDataService.Campagne.Recurrence == "Mensuelle") {
          if (this._GlobalDataService.Campagne.DetailRecurrence == null || this._GlobalDataService.Campagne.DetailRecurrence == "null") {
            this._GlobalDataService.alert("Merci de sélectionner le jour du mois");
            return;
          }
        } else if (this._GlobalDataService.Campagne.Recurrence == 'null') {
          if (this._GlobalDataService.Campagne.DetailRecurrence == null || this._GlobalDataService.Campagne.DetailRecurrence == "null") {
            this._GlobalDataService.alert("Merci de sélectionner une récurrence");
            return;
          }
        }
        if (!this._GlobalDataService.isValidDate(this._GlobalDataService.Campagne.DateDeDebut)) {
          this._GlobalDataService.alert("Merci de sélectionner la date de début");
          return;
        }
        if (!this._GlobalDataService.isValidDate(this._GlobalDataService.Campagne.DateDeFin)) {
          this._GlobalDataService.alert("Merci de sélectionner la date de fin");
          return;
        }
        if (new Date(this._GlobalDataService.Campagne.DateDeFin).getTime() <= new Date(this._GlobalDataService.Campagne.DateDeDebut).getTime()) {
          this._GlobalDataService.alert("Merci de corriger la date de fin");
          return;
        }
      }
    }
    else {
      this._GlobalDataService.alert("Merci de sélectionner la vitesse d'envoi")
      return;
    }
    //call API
    this._stepper.next();
  }
}
