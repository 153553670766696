import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { GlobalDataService } from './global-data.service';
import { SharedListSalesforceService } from './shared-list-salesforce.service';
import { ChampsFusionService } from './champs-fusion.service';
import { ChampsFusion } from '@core/models/champsFusion.model';


@Injectable({
  providedIn: 'root'
})
export class PersonalisationService {

  public form!: FormGroup;

  public controls: any;

  champFusion: string;

  availableChampsFusion: Array<any> = [];
  filteredAvailableChampsFusion: Array<any> = [];
  selectedChampsFusion: Array<any> = [];

  constructor(
    private _formBuilder: FormBuilder,
    public _GlobalDataService: GlobalDataService,
    private _ChampsFusionService: ChampsFusionService
  ) {

    // if (this._GlobalDataService.isStandaloneContext()) return;

    this.controls = {
      champFusion: new FormControl(this.champFusion, [
      ]),
    };
    this.form = this._formBuilder.group(this.controls);
    this.initialise();
   }

  public initialise() {
    this.selectedChampsFusion = this._GlobalDataService.CampagneExt.ChampsFusion.filter(cf => cf.IsPaveAdresse == '0' || cf.IsPaveAdresse == 'false');
    this.availableChampsFusion = this._GlobalDataService.availableEloquaChampsFusion;
    if (this._GlobalDataService.isStandaloneContext()) {
      this.filteredAvailableChampsFusion = this.availableChampsFusion.filter(acf => !this.selectedChampsFusion.map(scf => scf.ChampFusion).includes(acf.ChampFusion));
    } else {
      this.filteredAvailableChampsFusion = this.availableChampsFusion.filter(acf => !this._GlobalDataService.CampagneExt.ChampsFusion.map(scf => scf.Id).includes(acf.Id));
    }

    // Récupérer liste des champs dispo sur Standalone ou Eloqua
    // if (this._GlobalDataService.isStandaloneContext()){
    //   // this.updateChampFusion();
    //   this.availableChampsFusion = this.availableStandaloneChampsFusion;
    //   console.log(this.availableStandaloneChampsFusion)
    //   console.log(this.availableChampsFusion);

    //   // filteredAvailableChampsFusion
    //   this.filteredAvailableChampsFusion = this.availableChampsFusion.filter(acf => !this._GlobalDataService.CampagneExt.ChampsFusion.map(scf => scf.ChampFusion).includes(acf.FieldName));
    //   console.log(this.filteredAvailableChampsFusion)

    // } else if (this._GlobalDataService.isEloquaContext()){
    //   this.availableChampsFusion = this._GlobalDataService.availableEloquaChampsFusion;
    //   this.filteredAvailableChampsFusion = this.availableChampsFusion.filter(acf => !this._GlobalDataService.CampagneExt.ChampsFusion.map(scf => scf.Id).includes(acf.Id));
    // }
  }

  public setChampFusionType() {
    this.selectedChampsFusion.forEach((value, index) => {
      this.selectedChampsFusion[index].Sequence = index;
      this.selectedChampsFusion[index].IsPaveAdresse = 'false';
    });
  }

  public updateChampFusion() {
    this._ChampsFusionService.listChampsDisponibleStandalone().subscribe({
      next: (response) => {
        this.availableChampsFusion = [];
        this.filteredAvailableChampsFusion = []
        console.log(response);
        for (var i = 0; i < response.ContactFields.length; i++) {
          console.log(response.ContactFields[i]);
          let CF: ChampsFusion = {
            Id: response.ContactFields[i].Id,
            ChampFusion: response.ContactFields[i].FieldName,
            internalName: response.ContactFields[i].internalName,
          }
          this.availableChampsFusion.push(CF);
        }
        this._GlobalDataService.availableEloquaChampsFusion = this.availableChampsFusion;
        this.initialise();
      },
      error: (e) => {
        console.log('updateChampFusion works but not correctly');
      }
    });
  }

}
