import { Component, OnInit } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { CampagneTypologieService, GlobalDataService } from '@core/services';

@Component({
  selector: 'app-campagne-typologie-selection',
  templateUrl: './campagne-typologie-selection.component.html',
  styleUrls: ['./campagne-typologie-selection.component.scss']
})
export class CampagneTypologieSelectionComponent implements OnInit {

  constructor(
    private _stepper: MatStepper,
    public _GlobalDataService: GlobalDataService,
    public _CampagneTypologieService: CampagneTypologieService,
  ) { }

  submit() {
    //call API
    this._stepper.next();
  }

  ngOnInit(): void {
  }

}
