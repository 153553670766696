import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-dialog-message',
  templateUrl: './dialog-message.component.html',
  styleUrls: ['./dialog-message.component.scss']
})
export class DialogMessageComponent implements OnInit {

  onConfirm?;
  onCancel?;
  confirmMsg?: any;
  cancelMsg?: any;






  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _sanitizer: DomSanitizer) {
    this.onConfirm = data.onConfirm || function () { };
    this.onCancel = data.onCancel || function () { };
  }

  ngOnInit(): void {
  }

  trustHtml(htmlText: string) {
    return this._sanitizer.bypassSecurityTrustHtml(htmlText);
  }

  toggle(e: HTMLElement) {
    e.classList.toggle('hidden');
  }


}
export interface DialogData {
  message: string;
  errorMessage?: string;
  titre: string;
  BouttonConfirmation: string;
  onConfirm?: any;
  onCancel?: any;
  confirmMsg?: string;
  cancelMsg?: string;
  campagne?: string;
}
