<h3>Visualisation des données</h3>

<mat-table mat-table class="tab-visualisation" [class.rowSeparator]="rows.length>=30" [dataSource]="rows">
  <ng-container matColumnDef="position">
    <mat-header-cell *matHeaderCellDef>
      <div class="headerText"> N° </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element; let i = index"> {{i + 1}} </mat-cell>
  </ng-container>

  <ng-container
    *ngFor="let disCol of headers; let colIndex = index"
    matColumnDef="{{ disCol }}"
  >
    <mat-header-cell *matHeaderCellDef>
      <div class="headerText">
        {{ disCol }}
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element" [title]="element[disCol]">
      <div class="cellText">
        {{ element[disCol] }}
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row
    *matHeaderRowDef="headers; sticky: true;"
  ></mat-header-row>
  <mat-row
    *matRowDef="let row; columns: headers"
  ></mat-row>
</mat-table>
