import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { CampagnePlanificationService, GlobalDataService } from '@core/services';
import { FormErrorStateMatcher } from 'app/campagne/FormErrorStateMatcher';
import { Subscription } from 'rxjs';

import * as moment from 'moment';


@Component({
  selector: 'app-campagne-une-seule-fois',
  templateUrl: './campagne-une-seule-fois.component.html',
  styleUrls: ['./campagne-une-seule-fois.component.scss'],
})
export class CampagneUneSeuleFoisComponent implements OnInit {

  DateRemiseImprimeurSub: Subscription;

  errorMatcher = new FormErrorStateMatcher();

  constructor(
    public _GlobalDataService: GlobalDataService,
    private _formBuilder: FormBuilder,
    private _datePipe: DatePipe,
    public _CampagnePlanificationService: CampagnePlanificationService,
  ) {
   }

  ngOnInit(): void {
    console.log(this._GlobalDataService.Campagne.DateRemiseImprimeur);

    if (this._GlobalDataService.isValidDate(this._GlobalDataService.Campagne.DateRemiseImprimeur)) {
      // let dateRetourBAT = new Date();
      // dateRetourBAT.setDate(new Date(this._GlobalDataService.Campagne.DateRemiseImprimeur).getDate() + 5);
      let DateEstimeeDistribution = new Date();
      DateEstimeeDistribution.setDate(new Date(this._GlobalDataService.Campagne.DateRemiseImprimeur).getDate());      
      if (this._GlobalDataService.Campagne.FrequenceEnvoi == "Classique") {
        DateEstimeeDistribution.setDate(DateEstimeeDistribution.getDate() + 12 + 5);
      } else {
        DateEstimeeDistribution.setDate(DateEstimeeDistribution.getDate() + 7 + 5);
      }
      this._CampagnePlanificationService.form.get('DateEstimeeDistribution').setValue(this._datePipe.transform(DateEstimeeDistribution, 'dd/MM/yyyy'));
      console.debug(this._CampagnePlanificationService.form.get('DateEstimeeDistribution').value);
    }
    else {
      this._CampagnePlanificationService.form.get('DateTheoriqueDepotLaPoste').setValue(null,{ emitEvent: false });
      this._GlobalDataService.Campagne.DateTheoriqueDepotLaPoste = 'null';
      this._CampagnePlanificationService.form.get('DateEstimeeDistribution').setValue(null,{ emitEvent: false });
      this._GlobalDataService.Campagne.DateRemiseImprimeur = 'null';
      this._GlobalDataService.Campagne.isDirty = true;
    }
  }

  ngAfterViewInit() {

    this.DateRemiseImprimeurSub = this._CampagnePlanificationService.form.get('DateRemiseImprimeur').valueChanges
      .subscribe((value: string) => {
        // if valid     
        if (this._CampagnePlanificationService.form.get('DateRemiseImprimeur').valid) {
          // update model
          this._GlobalDataService.Campagne.DateRemiseImprimeur = this._datePipe.transform(value, 'yyyy-MM-dd HH:mm:ss');
          let dateTheorique = new Date(value);
          if (this._GlobalDataService.Campagne.FrequenceEnvoi == "Classique") {
            dateTheorique.setDate(dateTheorique.getDate() + 12);
          } else {
            dateTheorique.setDate(dateTheorique.getDate() + 7);
          }
          this._GlobalDataService.Campagne.DateTheoriqueDepotLaPoste = this._datePipe.transform(dateTheorique, 'yyyy-MM-dd HH:mm:ss');
          this._CampagnePlanificationService.form.get('DateTheoriqueDepotLaPoste').setValue(this._datePipe.transform(dateTheorique, 'dd/MM/yyyy'));


          // let dateRetourBAT = new Date(value);
          // dateRetourBAT.setDate(dateRetourBAT.getDate() + 5);
          let DateEstimeeDistribution = new Date(value);
          if (this._GlobalDataService.Campagne.FrequenceEnvoi == "Classique") {
            DateEstimeeDistribution.setDate(DateEstimeeDistribution.getDate() + 12 + 5);
          } else {
            DateEstimeeDistribution.setDate(DateEstimeeDistribution.getDate() + 7 + 5);
          }
          this._CampagnePlanificationService.form.get('DateEstimeeDistribution').setValue(this._datePipe.transform(DateEstimeeDistribution, 'dd/MM/yyyy'));

          this._GlobalDataService.Campagne.isDirty = true;

          console.debug(this._GlobalDataService.Campagne.DateRemiseImprimeur);
        }
        else {
          this._GlobalDataService.Campagne.DateRemiseImprimeur = 'null';
          this._CampagnePlanificationService.form.get('DateTheoriqueDepotLaPoste').setValue(null);
          this._GlobalDataService.Campagne.DateTheoriqueDepotLaPoste = 'null';
          this._CampagnePlanificationService.form.get('DateEstimeeDistribution').setValue(null);
          this._GlobalDataService.Campagne.isDirty = true;

          console.debug("Date d’activation n'est pas valide :" + value);

        }
      });
  }

  validateDate(form: FormGroup) {
    const now = new Date();;
    if (form.controls['DateRemiseImprimeur'].value < now) {
      return { isBeforeToday: true }; //date is before today's date
    }
    return null;
  }

  disablePastDatesFilter = (d: Date | null): boolean => {
    return moment().diff(d, 'days') >= 1 ? false : true;
  }

  ngOnDestroy() {
    this.DateRemiseImprimeurSub.unsubscribe();
  }

}
