import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-tab-visualisation',
  templateUrl: './tab-visualisation.component.html',
  styleUrls: ['./tab-visualisation.component.scss'],
})
export class TabVisualisationComponent {
  @Input()
  public headers: string[] = [];

  @Input()
  public rows: any[] = [];

  public form: FormGroup;
  public position: string[] = ["position"];

  constructor() {
    this.form = new FormGroup({
      filter: new FormControl(),
    });
  }
}
