import { Component, OnInit } from '@angular/core';

import { GlobalDataService, SharedListSalesforceService } from '@core/services';
import { Router } from '@angular/router';
import { CampagneService } from '@core/services/campagne.service';
import { forkJoin, from, Subscription } from 'rxjs';
import { HelpComponent } from '../help/help.component';

import { environment } from 'environments/environment';
import { CampagneExt } from '@core/models/campagne-ext.model';
import { Support } from '@core/models/support.model';
import { AdresseRetour } from '@core/models/adresseRetour.model';
import { FichiersCreation } from '@core/models/FichiersCreation.model';
import { ChampsFusion } from '@core/models/champsFusion.model';
import { Commande } from '@core/models/commande.model';
import { FichierCreaService } from '@core/services/fichier-crea.service';
import { CommandeService } from '@core/services/commande.service';
import { StatutCommandeService } from '@core/services/statut-commande.service';
import { ChampsFusionService } from '@core/services/champs-fusion.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Campagne } from '@core/models/campagne.model';

import { Annonceur } from '@core/models/annonceur.model';


export interface sidenavItem {
  title: string;
  route?: string;
  icon?: string;
  id?: boolean;
  action(path?: string): void;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  sidenavWidth: string = "8.2rem;";
  logoWidth: string = "4rem;";

  logomargin: string = "2rem;";
  helpmargin: string = "2rem;";


  ngStyle: string = "";

  isLinear = true;

  public sidenavItems: sidenavItem[] = [];
  isExpanded: boolean = false;
  selectedItem = 0;
  version = environment.version;

  getAdminDetailsCampagneByIdSub: Subscription | undefined;

  constructor(
    public router: Router,
    private domSanitizer: DomSanitizer,
    public _GlobalDataService: GlobalDataService,
    private _CampagneService: CampagneService,
    public _HelpComponent: HelpComponent,
    private _FichierCreaService: FichierCreaService,
    private _SharedListSalesforceService: SharedListSalesforceService,
    private _CommandeService: CommandeService,
    private _StatutCommandeService: StatutCommandeService,
    private _ChampsFusionService: ChampsFusionService
  ) {
    console.log('Campagne home page');
  }

  ngOnInit(): void {
    this.sidenavItems = [
      {
        title: 'Detail Campagne     ',
        icon: 'lettre1',
        action: this.gotoDetailCampagne.bind(this),
      },
      {
        title: 'Espace Client           ',
        icon: 'EspaceClient',
        action: this.gotoParametrage.bind(this),
      },
      {
        title: 'Favoris         ',
        icon: 'EtoileVide',
        action: this.gotoGestionFavoris.bind(this),
      },      
      // {
      //   title: 'Bon à tirer     ',
      //   icon: 'bloc-notes2',
      //   action: this.gotoValidationBat.bind(this),
      // },
      {
        title: 'Suivi de campagne',
        icon: 'SuiviCampagnes',
        action: this.gotoSuiviCampagne.bind(this),
      },
    ];
  }

  // async navigate(index: number) {
  //   console.log(index);
  // }

  gotoSuiviCampagne() {
    console.log('gotoSuiviCampagne');
    // this.router.navigate([{ outlets: {  navview: ['/administration/admin-campagnes/9'] }}]).then((e) => {
    this.router.navigate(["/campagne/home", { outlets: { 'navview': ['suivi-campagnes', this._GlobalDataService.Annonceur.IdAnnonceur] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }

  navigate(view: string, IdCampagne: string) {
    let path = "/campagne/home";

    if (this._GlobalDataService.isStandaloneContext()) {
      path = "/standalone/home"
    }

    this.router.navigate([path, { outlets: { 'navview': [view, IdCampagne] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }


  __gotoDetailCampagne() {
    console.log('gotoDetailCampagne');
    if (this._GlobalDataService.Campagne.CodeStatut != "EN_CREATION" &&
      this._GlobalDataService.Campagne.CodeStatut != "A_CORRIGER") {
      //redirection vers détail campagne       
      // this.router.navigate(['/campagne/home', { outlets: { 'navview': ['suivi-detail-campagne', this._GlobalDataService.Campagne.IdCampagne] } }], {
      //   queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      //   skipLocationChange: true
      // }).then((e) => {
      //   if (e) {
      //     console.log("Navigation is successful!");
      //   } else {
      //     console.log("Navigation has failed!");
      //   }
      // });

      if (this._GlobalDataService.Campagne.FrequenceEnvoi === 'Récurrente') {
        this.navigate('suivi-campagne-recurrente', this._GlobalDataService.Campagne.IdCampagne);
      }
      else {
        this.navigate('suivi-campagne-une-seule-fois', this._GlobalDataService.Campagne.IdCampagne);
      }             
      


    }
    else {
      this.router.navigate(['/campagne/home', { outlets: { 'navview': ['campagne-details'] } }], {
        queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
        skipLocationChange: true
      }).then((e) => {
        if (e) {
          console.log("Navigation is successful!");
        } else {
          console.log("Navigation has failed!");
        }
      });
    }
  }

  downloadFile(fileName: string) {
    // console.log("download file " + fileName);
    return this._FichierCreaService.download(fileName, this._GlobalDataService.Campagne.IdCampagne)
      .subscribe(response => {
        var blob = new Blob([response], { type: response.type });
        //Create a link element, hide it, direct 
        //it towards the blob, and then 'click' it programatically

        //Create a DOMString representing the blob 
        //and point the link element towards it


        if (blob.type.startsWith('image')) {
          let objectURL = URL.createObjectURL(blob);
          this._GlobalDataService.CampagneExt.FichiersCreationsURL[fileName] = this.domSanitizer.bypassSecurityTrustUrl(objectURL);
          this._GlobalDataService.CampagneExt.FichiersCreationsType[fileName] = 'image';

        }
        else {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            this._GlobalDataService.CampagneExt.FichiersCreationsURL[fileName] = new Uint8Array(fileReader.result as ArrayBuffer);
            this._GlobalDataService.CampagneExt.FichiersCreationsType[fileName] = blob.type;
          };
          fileReader.readAsArrayBuffer(blob);
        }
      });
  }



  gotoDetailCampagne() {

    if (typeof  this._GlobalDataService.Campagne .EmailNotification === "undefined")  this._GlobalDataService.Campagne .EmailNotification = "";

    // this._GlobalDataService.Campagne = campagne;

    this._GlobalDataService.CampagneExt = new CampagneExt();

    this._GlobalDataService.Campagne.isDirty = false;

    // this.showSpinner = true;

    console.log("Current campagne = {} ",  this._GlobalDataService.Campagne )

    this._GlobalDataService.CampagneExt.Produit = {};
    this._GlobalDataService.CampagneExt.Support = new Support()
    this._GlobalDataService.CampagneExt.Enveloppe = new Support()

    this._GlobalDataService.CampagneExt.Adresse = new AdresseRetour(this._GlobalDataService.Annonceur);
    this._GlobalDataService.CampagneExt.FichiersCreation = new Array<FichiersCreation>();
    this._GlobalDataService.CampagneExt.ChampsFusion = new Array<ChampsFusion>();
    this._GlobalDataService.CampagneExt.FichiersCreationsURL = {};
    this._GlobalDataService.CampagneExt.FichiersCreationsType = {};

    this._GlobalDataService.CampagneExt.Commandes = new Array<Commande>();
    this._GlobalDataService.CampagneExt.CurrentCommande = new Commande();

    this._GlobalDataService.Campagne.Percentage = "0";

    this._GlobalDataService.CampagneExt.VitessesProduit = new Array<string>();

    this._GlobalDataService.CampagneExt.VitessesProduit.push("Classique");
    this._GlobalDataService.CampagneExt.VitessesProduit.push("Rapide");

    // init des dates
    if (!this._GlobalDataService.Campagne.DateRemiseImprimeur) this._GlobalDataService.Campagne.DateRemiseImprimeur = "null";
    if (!this._GlobalDataService.Campagne.DateDeDebut) this._GlobalDataService.Campagne.DateDeDebut = "null";
    if (!this._GlobalDataService.Campagne.DateDeFin) this._GlobalDataService.Campagne.DateDeFin = "null";
    if (!this._GlobalDataService.Campagne.DateTheoriqueDepotLaPoste) this._GlobalDataService.Campagne.DateTheoriqueDepotLaPoste = "null";

    // Produit
    if (this._GlobalDataService.Campagne.IdProduit != '0') {

      this._GlobalDataService.CampagneExt.VitessesProduit = this._GlobalDataService.getVitessesProduit();

      const filteredProduits = this._GlobalDataService.produits.filter((p: any) => p.IdProduit === this._GlobalDataService.Campagne.IdProduit)
      if (filteredProduits.length > 0) this._GlobalDataService.CampagneExt.Produit = filteredProduits[0];

      const LibelleGrammageSupport = this._GlobalDataService.getLibelleProduit('CodeGrammageSupport', this._GlobalDataService.Campagne.CodeGrammageSupport, 'GrammageSupport');
      const LibelleFinitionSupport = this._GlobalDataService.getLibelleProduit('CodeFinitionSupport', this._GlobalDataService.Campagne.CodeFinitionSupport, 'FinitionSupport');
      const LibelleSupport = this._GlobalDataService.getLibelleProduit('CodeSupport', this._GlobalDataService.Campagne.CodeSupport, 'support');
      const LibelleDimensionsSupport = this._GlobalDataService.getLibelleProduit('CodeDimensionsSupport', this._GlobalDataService.Campagne.CodeDimensionsSupport, 'DimensionsSupport');

      const LibelleGrammageEnveloppe = this._GlobalDataService.getLibelleProduit('CodeGrammageEnveloppe', this._GlobalDataService.Campagne.CodeGrammageEnveloppe, 'GrammageEnveloppe');
      const LibelleFinitionEnveloppe = this._GlobalDataService.getLibelleProduit('CodeFinitionEnveloppe', this._GlobalDataService.Campagne.CodeFinitionEnveloppe, 'FinitionEnveloppe');
      const LibelleEnveloppe = this._GlobalDataService.getLibelleProduit('CodeEnveloppe', this._GlobalDataService.Campagne.CodeEnveloppe, 'enveloppe');
      const LibelleDimensionsEnveloppe = this._GlobalDataService.getLibelleProduit('CodeDimensionsEnveloppe', this._GlobalDataService.Campagne.CodeDimensionsEnveloppe, 'DimensionsEnveloppe');

      this._GlobalDataService.CampagneExt.Support = {
        support: LibelleSupport,
        dimension: LibelleDimensionsSupport,
        grammage: LibelleGrammageSupport,
        finition: LibelleFinitionSupport,
        typologie: "",
        Encre: "",
      } as Support

      this._GlobalDataService.CampagneExt.Enveloppe = {
        support: LibelleEnveloppe,
        dimension: LibelleDimensionsEnveloppe,
        grammage: LibelleGrammageEnveloppe,
        finition: LibelleFinitionEnveloppe,
        typologie: "",
        Encre: "",
      } as Support
    }

    // Adress Retour
    if (this._GlobalDataService.Campagne.IdAdresse != '0') {
      const filteredAdresses = this._GlobalDataService.availableAdresses.filter((a: AdresseRetour) => a.IdAdresse === this._GlobalDataService.Campagne.IdAdresse)
      if (filteredAdresses.length > 0) this._GlobalDataService.CampagneExt.Adresse = filteredAdresses[0];
    }


    // Campagne parente Duplication
    if (this._GlobalDataService.Campagne.IdCampagneCopie != '0') {
      const CampagneParente = this._GlobalDataService.availableCamapgnes.filter((c: Campagne) => c.IdCampagne == this._GlobalDataService.Campagne.IdCampagneCopie);
      if (CampagneParente.length > 0) this._GlobalDataService.Campagne.NomCampagneParente = CampagneParente[0].NomCampagne;
    }

    // Option retour
    this._GlobalDataService.setOptionRetour();

    // Fil d'Ariane
    this._GlobalDataService.checkStepCompletion();

    let fichiersCreation$ = from(this._FichierCreaService.list(this._GlobalDataService.Campagne.IdCampagne));
    let champsFusion$ = from(this._ChampsFusionService.list(this._GlobalDataService.Campagne.IdCampagne));
    let sharedLists$ = from(this._SharedListSalesforceService.getSharedLists());

    let commandes$$ = from(this._CommandeService.getListByIdCampagne(this._GlobalDataService.Campagne.IdCampagne));

    let joindMap = {};
    let joined$: any;
    if (this._GlobalDataService.isSalesforceContext()) {
      joindMap = { fichiersCreation: fichiersCreation$, champsFusion: champsFusion$, sharedLists: sharedLists$ };
      //joined$ = forkJoin({ fichiersCreation: fichiersCreation$, champsFusion: champsFusion$, sharedLists: sharedLists$ })
    }
    else if (this._GlobalDataService.isEloquaContext()) {
      joindMap = { fichiersCreation: fichiersCreation$, champsFusion: champsFusion$ };
      // joined$ = forkJoin({ fichiersCreation: fichiersCreation$, champsFusion: champsFusion$ })
    }
    else if (this._GlobalDataService.isStandaloneContext()) {
      joindMap = { fichiersCreation: fichiersCreation$ };
      // joined$ = forkJoin({ fichiersCreation: fichiersCreation$ })
    }

    if (this._GlobalDataService.Campagne.CodeStatut != "EN_CREATION" &&
      this._GlobalDataService.Campagne.CodeStatut != "A_CORRIGER") {
      joindMap['commandes'] = commandes$$;
    }

    joined$ = forkJoin(joindMap);


    joined$.subscribe(
      (result: any) => {
        console.log('init data = ', result);

        if (typeof result.fichiersCreation.fichierCreation == 'object') this._GlobalDataService.CampagneExt.FichiersCreation = result.fichiersCreation.fichierCreation;
        else this._GlobalDataService.CampagneExt.FichiersCreation = new Array<FichiersCreation>();

        this._GlobalDataService.CampagneExt.NbFichiersCreation = this._GlobalDataService.getNbFichiersCreation();
        this._GlobalDataService.CampagneExt.NbFichiersPersonnalisation = this._GlobalDataService.getNbFichiersPersonnalisation();
        this._GlobalDataService.CampagneExt.NbFichiersLogo = this._GlobalDataService.getNbFichiersLogo();

        this._GlobalDataService.CampagneExt.FichiersCreation.forEach(f => {
          this.downloadFile(f.NomFichier);
        })

        if (this._GlobalDataService.isSalesforceContext()) {
          this._GlobalDataService.sharedLists = result.sharedLists.entry;
        }

        if (this._GlobalDataService.isEloquaContext() || this._GlobalDataService.isSalesforceContext()) {
          if (result.champsFusion.ChampsFusion.length > 0 && result.champsFusion.ChampsFusion[0].Sequence >= 0) {
            result.champsFusion.ChampsFusion.sort((a, b) => parseInt(a.Sequence) - parseInt(b.Sequence));
            let CF: any;
            for (var i = 0; i < result.champsFusion.ChampsFusion.length; i++) {
              CF = {
                Id: result.champsFusion.ChampsFusion[i].Id,
                ChampFusion: result.champsFusion.ChampsFusion[i].ChampFusion,
                internalName: result.champsFusion.ChampsFusion[i].InternalName,
                Sequence: result.champsFusion.ChampsFusion[i].Sequence,
                IsPaveAdresse: result.champsFusion.ChampsFusion[i].IsPaveAdresse,
              }
              this._GlobalDataService.CampagneExt.ChampsFusion.push(CF);
            }
          }
        }

        this._GlobalDataService.isLoaded = true;

        if (this._GlobalDataService.Campagne.CodeStatut != "EN_CREATION" &&
          this._GlobalDataService.Campagne.CodeStatut != "A_CORRIGER" && this._GlobalDataService.Campagne.FrequenceEnvoi === 'Récurrente') {
          if (result.commandes.commande.length > 0) {
            this._GlobalDataService.CampagneExt.Commandes =  result.commandes.commande;
            this._GlobalDataService.CampagneExt.CurrentCommande = result.commandes.commande[0];
            console.log(this._GlobalDataService.CampagneExt.CurrentCommande);

            this._StatutCommandeService.getStatutsByIdCommandeAdmin(this._GlobalDataService.CampagneExt.CurrentCommande.IdCommande)
              .subscribe(
                response => {
                  if (Array.isArray(response.StatutCommande)) {
                    let listeStatutCommande = response.StatutCommande;

                    let currentStatutCommandeArray = listeStatutCommande.filter((s: any) => ['CONFORMITEOK', 'BAT_AVAILABLEOK', 'BAT_ACCEPTEDOK', 'BAT_REJECTEDOK'].indexOf(s.codeStatut) > -1)
                    let currentStatutCommandeBATOKArray =  currentStatutCommandeArray.filter((s: any) => ['BAT_ACCEPTEDOK'].indexOf(s.codeStatut) > -1 );

                    if (currentStatutCommandeBATOKArray.length >= 1)
                      this._GlobalDataService.Campagne.DateValidationBAT = currentStatutCommandeBATOKArray[0].dateModification;

                    console.log(currentStatutCommandeArray);
                    if (currentStatutCommandeArray.length >= 1) {
                      this._GlobalDataService.CampagneExt.CurrentStatutCommande = currentStatutCommandeArray[currentStatutCommandeArray.length - 1];

                      this._GlobalDataService.CampagneExt.CurrentStatutCommande.StatutLibelle = this._GlobalDataService.mappingStatut[this._GlobalDataService.CampagneExt.CurrentStatutCommande.codeStatut];

                      console.log(this._GlobalDataService.CampagneExt.CurrentStatutCommande);
                    }
                  }
                },
                () => { }
              );
          }
          //redirection vers détail campagne      
          this.navigate('suivi-campagne-recurrente', this._GlobalDataService.Campagne.IdCampagne);
        }
        else if (this._GlobalDataService.Campagne.CodeStatut != "EN_CREATION" &&
          this._GlobalDataService.Campagne.CodeStatut != "A_CORRIGER" && this._GlobalDataService.Campagne.FrequenceEnvoi === 'Une seule fois') {
          if (result.commandes.commande.length > 0) {
            this._GlobalDataService.CampagneExt.CurrentCommande = result.commandes.commande[0];
            this._StatutCommandeService.getStatutsByIdCommandeAdmin(this._GlobalDataService.CampagneExt.CurrentCommande.IdCommande)
              .subscribe(
                response => {
                  if (Array.isArray(response.StatutCommande)) {
                    let listeStatutCommande = response.StatutCommande;
                    this._GlobalDataService.CampagneExt.currentStatutCommandeArray = listeStatutCommande;
                    console.log(listeStatutCommande);
                    for (var i = listeStatutCommande.length - 1; i > 0; i--) {
                      const percentage = this._GlobalDataService.mappingPercentage[listeStatutCommande[i].codeStatut];
                      if (percentage != '0') {
                        this._GlobalDataService.Campagne.Percentage = percentage;
                        break;
                      }
                    }
                  }
                },
                () => { }
              );
          }
          console.log(this._GlobalDataService.CampagneExt.CurrentCommande);
          //redirection vers détail campagne    
          this.navigate('suivi-campagne-une-seule-fois', this._GlobalDataService.Campagne.IdCampagne);
        }
        else {
          this.navigate('campagne-details', this._GlobalDataService.Campagne.IdCampagne);
        }

      },
      error => { console.log(error); }
    );
  }


  gotoParametrage() {
    console.log('gotoParametrage');
    this.router.navigate(["/campagne/home", { outlets: { 'navview': ['parametrage'] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }

  gotoGestionFavoris() {
    console.log('gotoParametrage');
    this.router.navigate(["/campagne/home", { outlets: { 'navview': ['gestion-favoris'] } }], {
      queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
      skipLocationChange: true
    }).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }

  gotoValidationBat() {
    console.log('gotoValidationBat');
    this.getAdminDetailsCampagneByIdSub = this._CampagneService.getAdminDetailsCampagneById(this._GlobalDataService.Campagne.IdCampagne)
      .subscribe(
        response => {
          this._GlobalDataService.Campagne = response.campagne[0];
          console.log(response.campagne[0]);
          this.router.navigate(["/campagne/home", { outlets: { 'navview': ['validation-bat'] } }], {
            queryParams: this._GlobalDataService.objectParams, queryParamsHandling: "merge",
            skipLocationChange: true
          }).then((e) => {
            if (e) {
              console.log("Navigation is successful!");
            } else {
              console.log("Navigation has failed!");
            }
          });
        } ,
      );
  }

  increase() {
    this.isExpanded = true;
  }
  decrease() {
    this.isExpanded = false;
  }

  openHelp() {
    this._GlobalDataService.referenceDocHelp = '';
    this._HelpComponent.OpenHelpFile();
  }

  gotoMentionsLegales(){
    console.log('gotoMentionsLegales()')
    let path = "/campagne/home";
    this.router.navigate([path, {outlets: {'navview': ['mentions-legales']}}]);
  }

  ngOnDestroy() {
    if (this.getAdminDetailsCampagneByIdSub) this.getAdminDetailsCampagneByIdSub.unsubscribe();
  }

}
