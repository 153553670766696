import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ChampsFusion } from '@core/models/champsFusion.model';
import { AdressesDestinatairesSfService, GlobalDataService, PersonalisationSfService, SalesforceService, SharedListSalesforceService } from '@core/services';
import { CampagneService } from '@core/services/campagne.service';
import { ChampsFusionService } from '@core/services/champs-fusion.service';
import { debounceTime, distinctUntilChanged, fromEvent, map, Observable } from 'rxjs';



@Component({
  selector: 'app-campagne-personnalisation-sf',
  templateUrl: './campagne-personnalisation-sf.component.html',
  styleUrls: ['./campagne-personnalisation-sf.component.scss']
})
export class CampagnePersonnalisationSfComponent implements OnInit {

  @ViewChild('sharedListNameRef') private sharedListNameRef!: ElementRef;
  @ViewChild('champFusionRef') private champFusionRef!: ElementRef;

  constructor(
    private _stepper: MatStepper,
    public _GlobalDataService: GlobalDataService,
    public _ChampsFusionService: ChampsFusionService,
    private _SharedListSalesforceService: SharedListSalesforceService,
    public personalisationSfService: PersonalisationSfService,
    public  adressesDestinatairesSfService: AdressesDestinatairesSfService,
  ) {

  }

  ngOnInit() {
    if (!this._GlobalDataService.isSalesforceContext())
      return;

    this.personalisationSfService.initialise();
  }

  ngAfterViewInit() {
    // streams
    const keyup1$ = fromEvent(this.sharedListNameRef.nativeElement, 'keyup');
    // wait .5s between keyups to emit current value
    keyup1$
      .pipe(
        map((event: any) => event.currentTarget.value),
        debounceTime(300),
        distinctUntilChanged(),
        map(value => this.filtersharedLists(value)),
      )
      .subscribe((result) => {
        console.log(result);
        this.personalisationSfService.sharedLists = result;
      });

    // streams
    const keyup2$ = fromEvent(this.champFusionRef.nativeElement, 'keyup');

    // wait .5s between keyups to emit current value
    keyup2$
      .pipe(
        map((event: any) => event.currentTarget.value),
        debounceTime(300),
        distinctUntilChanged(),
        map(value => this.filterChampsFusion(value)),
      )
      .subscribe((result) => {
        console.log(result);
        this.personalisationSfService.filteredAvailableChampsFusion = result;
      });
  }
  private filtersharedLists(sharedListName: string): any[] {
    return this._GlobalDataService.sharedLists.filter(ListName => ListName.dataExtensionName.toLowerCase().includes(sharedListName.toLowerCase()));
  }

  private filterChampsFusion(champFusionName: string): any[] {
    return this.personalisationSfService.availableChampsFusion
      .filter(cf => cf.ChampFusion.toLowerCase().includes(champFusionName.toLowerCase()))
      .filter(cf => this.personalisationSfService.selectedChampsFusion.indexOf(cf) < 0);
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  optionSelected(sharedList: any) {
    console.log(sharedList);

    this.personalisationSfService.availableChampsFusion = [];
    this.personalisationSfService.filteredAvailableChampsFusion = [];
    this.personalisationSfService.selectedChampsFusion = [];

    this._GlobalDataService.Campagne.ExtensionId = sharedList.id;

    this._SharedListSalesforceService.getDataExtensionList(sharedList.id, '', sharedList.dataExtensionName)
      .subscribe(async (response) => {
        console.log("SF response CF", response);
        if (response === null) {
          this.personalisationSfService.availableChampsFusion = [];
        } else {
          this.personalisationSfService.availableChampsFusion = response;
          this.personalisationSfService.filteredAvailableChampsFusion = this.personalisationSfService.availableChampsFusion;
        }
      });
  }


  selectChampFusion(champsFusion: ChampsFusion) {
    transferArrayItem(
      this.personalisationSfService.filteredAvailableChampsFusion,
      this.personalisationSfService.selectedChampsFusion,
      this.personalisationSfService.filteredAvailableChampsFusion.indexOf(champsFusion),
      this.personalisationSfService.selectedChampsFusion.length - 1
    );
  }

  unselectChampFusion(champsFusion: ChampsFusion) {
    transferArrayItem(
      this.personalisationSfService.selectedChampsFusion,
      this.personalisationSfService.filteredAvailableChampsFusion,
      this.personalisationSfService.selectedChampsFusion.indexOf(champsFusion),
      this.personalisationSfService.filteredAvailableChampsFusion.length - 1
    );
  }

  ValiderSelection() {
    this.personalisationSfService.selectedChampsFusion.forEach((value, index) => {
      console.log(value.ChampFusion + ' : ' + index);
      this.personalisationSfService.selectedChampsFusion[index].Sequence = index;
      this.personalisationSfService.selectedChampsFusion[index].IsPaveAdresse = "false";
    });

    this._GlobalDataService.CampagneExt.ChampsFusion = this.personalisationSfService.selectedChampsFusion.concat(this.adressesDestinatairesSfService.selectedChampsFusion);
    //call API
    this._ChampsFusionService.upsert({
      IdCampagne: this._GlobalDataService.Campagne.IdCampagne,
      ChampsFusion: this._GlobalDataService.CampagneExt.ChampsFusion
    }).subscribe(() => {
       console.log('Done');
    }, (error) => { console.error(error) });
  }

  Vider() {
    console.log('Vider');
    this.personalisationSfService.filteredAvailableChampsFusion = this.personalisationSfService.filteredAvailableChampsFusion.concat(this.personalisationSfService.selectedChampsFusion);
    this.personalisationSfService.selectedChampsFusion = [];
    this.ValiderSelection();
  }

  submit() {
    this.ValiderSelection();
    this._stepper.next();
  }

  ngOnDestroy() {
  }

}
