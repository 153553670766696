import { FormBuilder, FormGroup } from '@angular/forms';

import { Component, HostListener } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { GlobalDataService } from '@core/services';

export interface sidenavItem {
  title: string;
  route?: string;
  icon?: string;
  id?: boolean;
  subview?: boolean;
  viewname?: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private titleService: Title
  ) {
    this.titleService.setTitle('Cap Mailing Plateforme');

    this.loadIcons(iconRegistry, sanitizer);
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler(event: any) {
    console.log('window:unload');
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: any) {
    console.log('window:beforeunload');
  }

  private loadIcons(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'lettre1',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/lettre 1.svg')
    );
    iconRegistry.addSvgIcon(
      'feuille',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/page.svg')
    );
    iconRegistry.addSvgIcon(
      'bloc-notes2',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/bloc-notes 2.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'analytics1',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/analytics 1.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'logo',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/logo.svg')
    );
    iconRegistry.addSvgIcon(
      'Ellipse2',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/Ellipse2.svg')
    );
    iconRegistry.addSvgIcon(
      'pencil1',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/pencil 1.svg')
    );
    iconRegistry.addSvgIcon(
      'maximize1',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/maximize 1.svg')
    );

    iconRegistry.addSvgIcon(
      'Vector',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/Vector.svg')
    );

    ////////////////////////////

    iconRegistry.addSvgIcon(
      'EspaceClient',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/300622_CAPMAILING_PICTOS_ESPACECLIENT.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'SuiviCampagnes',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/300622_CAPMAILING_PICTOS-SUIVIDECAMPAGNE.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'Crayon',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/240622_PISTOS_CAPMAILING_CRAYON.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'Infos',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/300622_CAPMAILING_PICTOS-INFOS.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'Aide',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/300622_CAPMAILING_PICTOS-AIDE.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'Croix',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/240622_PISTOS_CAPMAILING_CROIX.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'Refresh',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/240622_PISTOS_CAPMAILING_REFRESH.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'Refresh-bleu',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/240622_PISTOS_CAPMAILING_REFRESH-bleu.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'Ouvrir',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/080722_CAPMAILING_PICTOS_OUVRIR-69.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'Telecharger',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/240622_PISTOS_CAPMAILING_TELECHARGER.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'FlecheBas',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/150722_CAP_MAILING_PICTO_FLECHE_BAS.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'FlecheHaut',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/150722_CAP_MAILING_PICTO_FICHIER_HAUT.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'Updown',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/Updown.svg')
    );

    iconRegistry.addSvgIcon(
      'Loupe',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/240622_PISTOS_CAPMAILING_LOUPE.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'format',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/format.svg')
    );
    iconRegistry.addSvgIcon(
      'Carte',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/300622_CAPMAILING_PICTOS-CARTE.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'Envoi sous enveloppe',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/080722_CAPMAILING_PICTOS_ENVELOPPE+LETTRE-68.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'Tout en un',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/300622_CAPMAILING_PICTOS-TOUTENUN.svg'
      )
    );
    iconRegistry.addSvgIcon(
      '1 lettre',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/300622_CAPMAILING_PICTOS-FEUILLE.svg'
      )
    );

    iconRegistry.addSvgIcon(
      '1 enveloppe',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/300622_CAPMAILING_PICTOS-ENVELOPPE.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'dimension',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/300622_CAPMAILING_PICTOS-EQUERRE.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'grammage',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/300622_CAPMAILING_PICTOS-GRAMMAGE.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'finition',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/300622_CAPMAILING_PICTOS-FINITIONS.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'encre',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/300622_CAPMAILING_PICTOS-ENCRE.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'EtoileVide',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/240622_PISTOS_CAPMAILING_ETOILEVIDE.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'EtoilePleine',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/240622_PISTOS_CAPMAILING_ETOILEPLEINE.svg'
      )
    );

    /// Icones fichiers CREA
    iconRegistry.addSvgIcon(
      'Adobe',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/240622_PISTOS_CAPMAILING_ADOBE.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'Word',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/240622_PISTOS_CAPMAILING_WORD.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'Excel',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/240622_PISTOS_CAPMAILING_EXCEL.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'PPT',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/240622_PISTOS_CAPMAILING_PPT.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'Zip',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/240622_PISTOS_CAPMAILING_ZIP.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'Autre',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/240622_PISTOS_CAPMAILING_SANSCATEGORISATION.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'Typo',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/240622_PISTOS_CAPMAILING_TYPO.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'image',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/150722_CAP_MAILING_PICTO_FICHIER_IMG.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'FichierPDF',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/150722_CAP_MAILING_PICTO_FLECHE_PDF.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'send',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/send.svg')
    );
    iconRegistry.addSvgIcon(
      'espace_client',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/ESPACE_CLIENT.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'eye_open',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/eye-open.svg')
    );
    iconRegistry.addSvgIcon(
      'eye_shut',
      sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/eye-shut.svg')
    );

    iconRegistry.addSvgIcon(
      'information',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/information.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'question',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/question.svg'
      )
    );

    iconRegistry.addSvgIcon(
      'radio_check',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/radio_check.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'radio_uncheck',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/radio_uncheck.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'new',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/new.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'go-to',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/go-to.svg'
      )
    );
  }
}
