
import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { forkJoin, from, fromEvent, Observable, Subscription } from 'rxjs';

import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';


import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { Campagne } from '@core/models/campagne.model';
import { CampagneService } from '@core/services/campagne.service';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { GlobalDataService } from '@core/services/global-data.service';
import { CampagneDuplicationDialogComponent, HelpComponent } from '..';
import { MatDialog } from '@angular/material/dialog';
import { ChampsFusionService } from '@core/services/champs-fusion.service';
import { AdresseService } from '@core/services/adresse.service';
import { ProduitService } from '@core/services/produit.service';
import { FichierCreaService } from '@core/services/fichier-crea.service';
import { ChampsFusion } from '@core/models/champsFusion.model';
import { CampagneIdentificationService } from '@core/services/campagne-identification.service';
// import {NgSelectModule, NgOption} from '@ng-select/ng-select';

@Component({
  selector: 'app-campagne-identification',
  templateUrl: './campagne-identification.component.html',
  styleUrls: ['./campagne-identification.component.scss'],
})
export class CampagneIdentificationComponent implements OnInit {

  @ViewChild('IdCampagneCopie') private IdCampagneCopieRef!: ElementRef;
  KeyupSubscriptionIdCampagneCopie: Subscription = new Subscription;
  isLoading: boolean = false;

  selectedParentCampagne = '';
  filteredCampagnes!: Observable<Campagne[]>;
  campagnes: Campagne[] = [];//campagnes parentes
  filteredcampagnes: Campagne[] = [];

  isLoaded = false;


  animal!: string;
  name!: string;

  @Output() nameChangeEvent = new EventEmitter<string>();

  template!: FormControl;
  constructor(
    private _CampagneService: CampagneService,
    private _stepper: MatStepper,
    public snackBar: MatSnackBar,
    public _GlobalDataService: GlobalDataService,
    public dialog: MatDialog,
    public _ChampsFusionService: ChampsFusionService,
    public _AdresseService: AdresseService,
    public _HelpComponent: HelpComponent,
    public _CampagneIdentificationService: CampagneIdentificationService
  ) {
    if (typeof this._GlobalDataService.Campagne === 'object') {
      this._CampagneIdentificationService.form.get('NomCampagne').patchValue(this._GlobalDataService.Campagne.NomCampagne, { emeitEvent: false });
      this._CampagneIdentificationService.form.get('NomCampagneParente').patchValue(this._GlobalDataService.Campagne.NomCampagneParente, { emeitEvent: false });
      this._CampagneIdentificationService.form.get('Description').patchValue(this._GlobalDataService.Campagne.Description, { emeitEvent: false });
      this._CampagneIdentificationService.form.get('BonDeCommande').patchValue(this._GlobalDataService.Campagne.BonDeCommande, { emeitEvent: false });
    }
  }




  ngOnInit() {
    this.filteredcampagnes = this._GlobalDataService.availableCamapgnes;
    if (this._GlobalDataService.Campagne.NomCampagne)
      this.verifIsUnique();
  }

  ngAfterViewInit() {

    this._CampagneIdentificationService.form.get('NomCampagne').valueChanges.subscribe((value: string) => {
      // if valid
      if (this._CampagneIdentificationService.form.get('NomCampagne').valid) {
        // update model
        this._GlobalDataService.Campagne.NomCampagne = value;
        this._GlobalDataService.Campagne.isDirty = true;
        // this._CampagneService.put(this._GlobalDataService.Campagne)
        // .subscribe(
        //   () => {
        //     console.log('Done');
        //   },
        //   error => {
        //     console.log(error.error.message);
        //     this._GlobalDataService.openSnackBarInfo(error.error.message);
        //   }
        // );
      }
    });

    this._CampagneIdentificationService.form.get('BonDeCommande').valueChanges.subscribe((value: string) => {
      // if valid
      if (this._CampagneIdentificationService.form.get('BonDeCommande').valid) {
        // update model
        this._GlobalDataService.Campagne.BonDeCommande = value;
        this._GlobalDataService.Campagne.isDirty = true;
        // this._CampagneService.put(this._GlobalDataService.Campagne)
        // .subscribe(
        //   () => {
        //     console.log('Done');
        //   },
        //   error => {
        //     console.log(error.error.message);
        //     this._GlobalDataService.openSnackBarInfo(error.error.message);
        //   }
        // );
      }
    });

    this._CampagneIdentificationService.form.get('Description').valueChanges.subscribe((value: string) => {
      // if valid
      if (this._CampagneIdentificationService.form.get('Description').valid) {
        // update model
        this._GlobalDataService.Campagne.Description = value;
        this._GlobalDataService.Campagne.isDirty = true;
        // this._CampagneService.put(this._GlobalDataService.Campagne)
        // .subscribe(
        //   () => {
        //     console.log('Done');
        //   },
        //   error => {
        //     console.log(error.error.message);
        //     this._GlobalDataService.openSnackBarInfo(error.error.message);
        //   }
        // );      
      }
    });

    if (this._GlobalDataService.Campagne.IdCampagneCopie === "0") {
      // streams
      const keyup$ = fromEvent(this.IdCampagneCopieRef.nativeElement, 'keyup');

      // wait .5s between keyups to emit current value
      keyup$
        .pipe(
          map((event: any) => event.currentTarget.value),
          // map((i: any) => i.currentTarget.value),
          debounceTime(300),
          distinctUntilChanged(),
          map(value => this._filterCampagnes(value)),
        )
        .subscribe((filterCampagnes) => {
          this.filteredcampagnes = (<Campagne[]><unknown>filterCampagnes)
        });
    }
  }

  private _filterCampagnes(value: string): Campagne[] {
    const filterValue = value.toLowerCase();

    const filteredcampagnes: Campagne[] = this._GlobalDataService.availableCamapgnes.filter(campagne => campagne.NomCampagne.toLowerCase().indexOf(filterValue) != -1);

    return filteredcampagnes;
  }

  submit() {
    //call API
    this._stepper.next();
  }

  duplicateCampagne() {
    if (this.selectedParentCampagne == '') {
      this._GlobalDataService.alert("Merci de sélectionner une campagne parente.");
      return;
    } else if (this._CampagneIdentificationService.form.value.NomCampagne == '' || this._GlobalDataService.Campagne.NomCampagne == '') {
      this._GlobalDataService.alert("Merci de saisir le nom de la campagne.");
      return;
    }
    let data = {
      message: "Etes-vous sûr de vouloir copier cette campagne?",
      titre: 'Confirmation',
      BouttonConfirmation: true,
    }
    const confirmdialogRef = this._GlobalDataService.confirm(data);
    confirmdialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);

      if (result === true) {
        let request = {
          request: {
            NomCampagne: this._CampagneIdentificationService.form.value.NomCampagne,
            Description: this._CampagneIdentificationService.form.value.Description
          }
        }
        this._CampagneService.duplicate(this.selectedParentCampagne, request)
          .subscribe(
            response => this.SuccessDuplicate(response),
            error => this.errorDuplicate(error)
          );
        return true;
      } else {
        return false;
      }
    });

  }

  SuccessDuplicate(response: { IdCampagneDuplicate: string; }) {
    if (response.IdCampagneDuplicate != '') {
      this._GlobalDataService.alert("La campagne '" + this._CampagneIdentificationService.form.value.NomCampagne + "' a été initialisée avec succès");
    }
  }

  errorDuplicate(error: any) {
    this._GlobalDataService.alert("Une erreur s'est produite lors de la duplication");
  }

  verifIsUnique() {
    let request = {
      "NomCampagne": this._CampagneIdentificationService.form.value.NomCampagne,
      "IdCampagne": this._GlobalDataService.Campagne.IdCampagne,
    }
    this._CampagneService.verifNomCampagne(this._GlobalDataService.Campagne.IdAnnonceur, request)
      .subscribe(
        response => {
          if (response.result) {//true => le nom existe déjà
            this._GlobalDataService.alert("Le nom de la campagne est obligatoire et doit-être unique. Merci de renseigner un nouveau nom de campagne");

            this._CampagneIdentificationService.form.controls['NomCampagne'].setErrors({ 'incorrect': true });
            this._GlobalDataService.nomCampagneIsValid = false;
          } else {
            this._GlobalDataService.nomCampagneIsValid = true;
          }
        },
        () => {
          this._GlobalDataService.nomCampagneIsValid = true;
        }
      );
  }

  verifForbiddenCharactersNom(event: any) {
    console.log("verifForbiddenCharactersNom", event);
    if (!event || !event.target || !event.target.value)
      return;
    if (event.target.value.indexOf('"') !== -1) {
      this._GlobalDataService.alert(`Vous ne pouvez pas utiliser le caractère " dans ce champ`);
      this._GlobalDataService.nomCampagneIsValid = false;
    }
  }

  verifForbiddenCharactersRef(event: { target: { value: string | string[]; }; }) {
    console.log("verifForbiddenCharacters", event);
    if (!event || !event.target || !event.target.value)
      return;
    if (event.target.value.indexOf('"') !== -1) {
      this._GlobalDataService.alert(`Vous ne pouvez pas utiliser le caractère " dans ce champ`);
      this._GlobalDataService.refCampagneIsValid = false;
    }
  }

  verifForbiddenCharactersDescription(event: { target: { value: string | string[]; }; }) {
    console.log("verifForbiddenCharacters", event);
    if (!event || !event.target || !event.target.value)
      return;
    if (event.target.value.indexOf('"') !== -1) {
      this._GlobalDataService.alert(`Vous ne pouvez pas utiliser le caractère " dans ce champ`);
      this._GlobalDataService.descriptionCampagneIsValid = false;
    }
  }


  async openCampagneDuplicationDialog(campagne: Campagne) {


    if (this._CampagneIdentificationService.form.value.NomCampagne == '') {
      this._GlobalDataService.alert("Merci de saisir le nom de la campagne.");
      return;
    }

    let config = {
      width: "900px",
      // height: "550px",
      data: {
        campagne: campagne,
        NomCampagne: this._CampagneIdentificationService.form.value.NomCampagne,
        Description: this._CampagneIdentificationService.form.value.Description,
        BonDeCommande: this._CampagneIdentificationService.form.value.BonDeCommande
      }
    };

    const dialogRef = this.dialog.open(CampagneDuplicationDialogComponent, config);

    dialogRef.afterOpened().subscribe(() => {
      console.log('The dialog was openned');
      if (this._GlobalDataService.Campagne.isDirty) {
        this._CampagneService.put(this._GlobalDataService.Campagne)
          .subscribe(
            () => {
              console.log('Done');
              this._GlobalDataService.Campagne.isDirty = false;          
            },
            error => {
              console.log(error.error.message);
              this._GlobalDataService.openSnackBarInfo(error.error.message);
            }
          );
      }      
    });

    dialogRef.beforeClosed().subscribe(async () => {
      console.log('The dialog before close');
      if (this._GlobalDataService.Campagne.IdCampagneCopie != '0') {
        this._CampagneIdentificationService.form.get('NomCampagneParente').setValue(this._GlobalDataService.Campagne.NomCampagneParente, { emitEvent: false });
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
    });
  }

  ErrorCurrentCampagne(error: any) {
    console.log('CreationCampagneComponent : ErrorCurrentCampagne', error)
  }

  dumpValue(field: string) {
    if (this._CampagneIdentificationService.form.get(field)?.dirty) {
      console.log(this._CampagneIdentificationService.form.get(field)?.value);
      console.log(this._GlobalDataService.Campagne.Description);

    }
  }

  openHelpMarketing() {
    this._GlobalDataService.referenceDocHelp = "#Trousse_outils";
    this._HelpComponent.OpenHelpFile();
    return false;
  }

  public emitCampaignName(event: any): void {
    this.nameChangeEvent.emit(event.target.value);
  }
}