import { Component, Input, OnInit } from '@angular/core';
import { Adresse } from '@core/models/adresse.model';
import { AdresseRetour } from '@core/models/adresseRetour.model';

@Component({
  selector: 'app-adresse-retour',
  templateUrl: './adresse-retour.component.html',
  styleUrls: ['./adresse-retour.component.scss']
})
export class AdresseRetourComponent implements OnInit {

  @Input() Adresse!: AdresseRetour;

  @Input() IdAdresse!: string;


  constructor(
  ) {
    console.debug(this.Adresse);
  }

  ngOnInit(): void {
  }

}


