import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { GlobalDataService } from '@core/services';


@Component({
  selector: 'app-campagne-adresses',
  templateUrl: './campagne-adresses.component.html',
  styleUrls: ['./campagne-adresses.component.scss']
})
export class CampagneAdressesComponent implements OnInit, OnDestroy {

  constructor(
    public _GlobalDataService: GlobalDataService,
    private _stepper: MatStepper,    
  ) {

  }

  ngOnInit(): void {
  }

  submit() {
    //call API
    this._stepper.next();
  }

  PaveAdresseSelect() : boolean {
    return (this._GlobalDataService.CampagneExt.ChampsFusion.filter(cf => cf.IsPaveAdresse == '1' || cf.IsPaveAdresse == 'true').length > 0 );
  }
  ngOnDestroy() {
  }
}
