import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Campagne } from '@core/models/campagne.model';
import { CampagneService } from '@core/services/campagne.service';
import { ChartsService } from '@core/services/charts.service';
import { CampagneDetailsService, GlobalDataService, SharedListSalesforceService } from '@core/services';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin, from, Observable, Subscription } from 'rxjs';
import { ChampsFusionService } from '@core/services/champs-fusion.service';
import { FichierCreaService } from '@core/services/fichier-crea.service';
import { ProduitService } from '@core/services/produit.service';
import { AdresseService } from '@core/services/adresse.service';
import { Adresse } from '@core/models/adresse.model';
import { FichiersCreation } from '@core/models/FichiersCreation.model';
import { AdresseRetour } from '@core/models/adresseRetour.model';
import { ChampsFusion } from '@core/models/champsFusion.model';
import { DomSanitizer } from '@angular/platform-browser';
import { CampagneExt } from '@core/models/campagne-ext.model';
import { CommandeService } from '@core/services/commande.service';
import { Commande } from '@core/models/commande.model';
import { StatutCommandeService } from '@core/services/statut-commande.service';
import { Support } from '@core/models/support.model';

@Component({
  selector: 'app-suivi-campagnes',
  templateUrl: './suivi-campagnes.component.html',
  styleUrls: ['./suivi-campagnes.component.scss']
})
export class SuiviCampagnesComponent implements OnInit, AfterViewInit, OnDestroy {
  _campagneDataSub: any;
  campagneData: any[] = [];
  _campagneYearsSub: any;
  campagneYears: any[] = [];
  _campagneUsersSub: any;
  campagneUsers: any[] = [];

  _campagneOrdersSub: any;
  campagneOrders: any[] = [];

  titre = "Suivi d’une campagne courrier";
  displayedColumns: string[] =
    ['NomCampagne',
      'GTMTCommande',
      'CreeParLogin',
      // 'CodeVitesse',
      // 'FrequenceEnvoi',
      'DateRemiseImprimeur',
      'DateTheoriqueDepotLaPoste',
      'Recurrence',
      'DetailRecurrence',
      'DateDeDebut',
      'DateDeFin',
      'Alliage',
      'StatutCampagne',
      'NumberDeCommande', 'NombrePli', 'Nbre_PND'];


  dataSource: any = new MatTableDataSource<Campagne>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator | null | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;

  pageLength = 100;
  pageSize = 10;
  pageChangeEvent(event) {console.log(event)}


  showSpinner: boolean = false;

  IdAnnonceur: any;
  constructor(
    private activeRoute: ActivatedRoute,
    private _CampagneService: CampagneService,
    public _GlobalDataService: GlobalDataService,
    public _CommandeService: CommandeService,
    public _CampagneDetailsService: CampagneDetailsService,
  ) {
    this.activeRoute.paramMap.subscribe((params: ParamMap) => {
      this.IdAnnonceur = params.get('IdAnnonceur');
      if (this.IdAnnonceur != "") {
        this._CampagneService.getListAdminCampagneByIdAnnonceur(this.IdAnnonceur)
          .subscribe(
            response => this.successResponse(response),
            error => this.errorResonse(error)
          );
      }

    });
    this._campagneDataSub = this._CampagneService.campagneDataSub.subscribe((value) => {
      this.campagneData = value;
      this.dataSource.data = this.campagneData;
    });
    this._campagneYearsSub = this._CampagneService.campagneYearsSub.subscribe((value) => {
      this.campagneYears = value;
    });
    this._campagneUsersSub = this._CampagneService.campagneUsersSub.subscribe((value) => {
      this.campagneUsers = value;
    });
    this._campagneOrdersSub = this._CampagneService.campagneOrdersSub.subscribe((value) =>{
      this.campagneOrders = value;
    })    
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (filterValue) this.dataSource.filter = filterValue.trim().toLowerCase();
    else this.dataSource.filter = "";

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  sortByOrder(order: any) {
    this._CampagneService.sortCampagneByOrder(order);
  }

  resetSortByOrder(){
    this._CampagneService.resetSortByOrder();
  }


  filterByYears(year: any) {
    this._CampagneService.filterCampagneByYear(year);
  }

  resetFilterByYears() {
    this._CampagneService.resetFilterByYears();
  }

  filterByUsers(user: any) {
    this._CampagneService.filterCampagneByUser(user);
  }

  resetFilterByUsers() {
    this._CampagneService.resetFilterByUsers();
  }

  gotoDetailCampagne(campagne: Campagne) {
    this._GlobalDataService.Campagne = campagne;
    this._CampagneDetailsService.gotoDetailCampagne();
  }
  
  unclearNull(campagne: Campagne) {
    const keys = Object.keys(campagne);
    keys.forEach((key) => {
      if (campagne[key] === "") campagne[key] === "null";
    });
  }

  successResponse(response: { campagne: Campagne[]; }) {
    console.log("Success!");
  }

  errorResonse(HttpErrorResponse: { error: { message: string; }; }) {
    console.error("Error!", HttpErrorResponse);
    if (HttpErrorResponse.error.message && HttpErrorResponse.error.message != "") {
      this._GlobalDataService.openSnackBarInfo("une erreur c'est produite : " + HttpErrorResponse.error.message);
    }
  }

  rafraichir() {
    console.log("rafraichir")
    if (this.IdAnnonceur != "") {
      this._CampagneService.getListAdminCampagneByIdAnnonceur(this.IdAnnonceur)
        .subscribe(
          response => this.successResponse(response),
          error => this.errorResonse(error)
        );
    }
  }
  paginationChange(paginationDetails) {
    console.log('Page Changed: ', paginationDetails);
  }
  ngOnDestroy() {
    this._CampagneService.onComponentDestroy();
    this._campagneDataSub.unsubscribe();
    this._campagneYearsSub.unsubscribe();
    this._campagneUsersSub.unsubscribe();
    this._campagneOrdersSub.unsubscribe();
  }
}
