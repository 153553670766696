<div class="gtmt-container">

    <!-- Suivi de campagne courrier -->
    <mat-toolbar style="background: #F9FAFE; border-radius: 0.3rem;">
        <h1> Suivi de campagne courrier</h1>
        <button mat-raised-button color="blue" (click)="gotoSuiviCampagne()" style=" width: auto;margin-right: 3rem">
            Toutes vos campagnes courrier
        </button>
    </mat-toolbar>

    <!-- Statut de la campagne courrier -->
    <app-suivi-campagne-progression></app-suivi-campagne-progression>


    <mat-accordion multi="true">
        <mat-expansion-panel expanded="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Evolution de la campagne courrier
                </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- ÉVOLUTION DE LA CAMPAGNE COURRIER -->
            <app-suivi-campagne-caroussel *ngIf="_GlobalDataService.isLoaded"></app-suivi-campagne-caroussel>

            <!-- Info Commande -->
            <app-suivi-campagne-commande></app-suivi-campagne-commande>
        </mat-expansion-panel>

        <mat-expansion-panel expanded="true" *ngIf="(_GlobalDataService.isStandaloneContext() && _GlobalDataService.Campagne.importOption === 'upload')">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Téléchargement des fichiers
            </mat-panel-title>
          </mat-expansion-panel-header>

          <app-suivi-campagne-telechargement></app-suivi-campagne-telechargement>
        </mat-expansion-panel>

        <mat-expansion-panel expanded="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Récapitulatif de la campagne courrier
                </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- RÉCAPITULATIF DE LA CAMPAGNE COURRIER -->
            <app-suivi-campagne-recapilatif></app-suivi-campagne-recapilatif>
        </mat-expansion-panel>
    </mat-accordion>

</div>
