import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CampagnePlanificationService, GlobalDataService } from '@core/services';
import { FormErrorStateMatcher } from 'app/campagne/FormErrorStateMatcher';
import { Subscription } from 'rxjs';

import * as moment from 'moment';

@Component({
  selector: 'app-campagne-recuerrente',
  templateUrl: './campagne-recuerrente.component.html',
  styleUrls: ['./campagne-recuerrente.component.scss']
})
export class CampagneRecuerrenteComponent implements OnInit {

  RecurrenceSub: Subscription;
  DateRemiseImprimeurSub: Subscription;
  DateDeDebutSub: Subscription;
  DateDeFinSub: Subscription;
  DetailRecurrenceSub: Subscription;

  errorMatcher = new FormErrorStateMatcher();


  days = ['Lu', 'Ma', 'Me', 'Je', 'Ve'];

  typeRecurrences = ['Quotidienne', 'Hebdomadaire', 'Mensuelle'];

  constructor(
    public _GlobalDataService: GlobalDataService,
    private _datePipe: DatePipe,
    public _CampagnePlanificationService: CampagnePlanificationService,
  ) {
  }

  ngOnInit(): void {
    console.log(this._GlobalDataService.Campagne.DateRemiseImprimeur);

    if (this._GlobalDataService.isValidDate(this._GlobalDataService.Campagne.DateRemiseImprimeur)) {
      let dateRetourBAT = new Date();
      dateRetourBAT.setDate(new Date(this._GlobalDataService.Campagne.DateRemiseImprimeur).getDate() + 5);
      let DateEstimeeDistribution = new Date();
      if (this._GlobalDataService.Campagne.FrequenceEnvoi == "Classique") {
        DateEstimeeDistribution.setDate(dateRetourBAT.getDate() + 12);
      } else {
        DateEstimeeDistribution.setDate(dateRetourBAT.getDate() + 7);
      }
      this._CampagnePlanificationService.form.get('DateEstimeeDistribution').setValue(this._datePipe.transform(DateEstimeeDistribution, 'dd/MM/yyyy'));
      console.log(this._CampagnePlanificationService.form.get('DateEstimeeDistribution').value);
    }
    else {
      this._GlobalDataService.Campagne.DateRemiseImprimeur = 'null';
      this._CampagnePlanificationService.form.get('DateEstimeeDistribution').setValue(null,{ emitEvent: false });
      this._CampagnePlanificationService.form.get('DateTheoriqueDepotLaPoste').setValue(null,{ emitEvent: false });
      this._GlobalDataService.Campagne.DateTheoriqueDepotLaPoste = 'null';
    }
  }

  ngAfterViewInit() {

    // Recurrence
    this.RecurrenceSub = this._CampagnePlanificationService.form.get('Recurrence').valueChanges
      .subscribe((recurrence: string) => {
        // if valid     
        if (this._CampagnePlanificationService.form.get('Recurrence').valid) {
          // update model
          console.info("Recurrence selectionnée :" + recurrence);
          this._GlobalDataService.Campagne.Recurrence = recurrence;
          this._CampagnePlanificationService.form.get('DetailRecurrence').reset();
          this._GlobalDataService.Campagne.DetailRecurrence = "";
          this._GlobalDataService.Campagne.isDirty = true;
        }
      });

    // DetailRecurrence
    this.DetailRecurrenceSub = this._CampagnePlanificationService.form.get('DetailRecurrence').valueChanges
      .subscribe((detailRecurrence: string) => {
        // if valid     
        if (this._CampagnePlanificationService.form.get('DetailRecurrence').valid) {
          // update model
          console.debug("Jour selectionné :" + detailRecurrence);
          this._GlobalDataService.Campagne.DetailRecurrence = detailRecurrence;
          this._GlobalDataService.Campagne.isDirty = true;
        }
      });

    // DateRemiseImprimeur
    this.DateRemiseImprimeurSub = this._CampagnePlanificationService.form.get('DateRemiseImprimeur').valueChanges
      .subscribe((value: string) => {
        // if valid     
        if (this._CampagnePlanificationService.form.get('DateRemiseImprimeur').valid) {
          // update model
          this._GlobalDataService.Campagne.DateRemiseImprimeur = this._datePipe.transform(value, 'yyyy-MM-dd HH:mm:ss');
          let dateTheorique = new Date(value);
          if (this._GlobalDataService.Campagne.FrequenceEnvoi == "Classique") {
            dateTheorique.setDate(dateTheorique.getDate() + 12);
          } else {
            dateTheorique.setDate(dateTheorique.getDate() + 7);
          }
          this._GlobalDataService.Campagne.DateTheoriqueDepotLaPoste = this._datePipe.transform(dateTheorique, 'yyyy-MM-dd HH:mm:ss');
          this._CampagnePlanificationService.form.get('DateTheoriqueDepotLaPoste').setValue(this._datePipe.transform(dateTheorique, 'dd/MM/yyyy'));
          this._GlobalDataService.Campagne.isDirty = true;
        }
        else {
          console.debug("Date d’activation n'est pas valide :" + value);
          this._GlobalDataService.Campagne.DateRemiseImprimeur = 'null';
          this._CampagnePlanificationService.form.get('DateTheoriqueDepotLaPoste').setValue(null);
          this._GlobalDataService.Campagne.DateTheoriqueDepotLaPoste = 'null';
          this._GlobalDataService.Campagne.isDirty = true;
        }
      });

    // DateDeDebut
    this.DateDeDebutSub = this._CampagnePlanificationService.form.get('DateDeDebut').valueChanges
      .subscribe(dateDeDebut => {
        if (dateDeDebut != "Invalid Date")
          this._GlobalDataService.Campagne.DateDeDebut = this._datePipe.transform(dateDeDebut, 'yyyy-MM-dd HH:mm:ss');
        else {
          this._GlobalDataService.Campagne.DateDeDebut = null;
        }          
        this._GlobalDataService.Campagne.isDirty = true;
      })

    // DateDeFin
    this.DateDeFinSub = this._CampagnePlanificationService.form.get('DateDeFin').valueChanges
      .subscribe(dateDeFin => {
        if (dateDeFin != "Invalid Date")
          this._GlobalDataService.Campagne.DateDeFin = this._datePipe.transform(dateDeFin, 'yyyy-MM-dd HH:mm:ss');
        else
          this._GlobalDataService.Campagne.DateDeFin = null;
        this._GlobalDataService.Campagne.isDirty = true;
      })
  }





  validateDate(form: FormGroup) {
    const now = new Date();;
    if (form.controls['DateRemiseImprimeur'].value < now) {
      return { isBeforeToday: true }; //date is before today's date
    }
    if (form.controls['DateDeDebut'].value < now) {
      return { isBeforeToday: true }; //date is before today's date
    }
    if (form.controls['DateDeFin'].value < form.controls['DateDeDebut'].value) {
      return { isBeforeStartDate: true }; //date is before today's start date
    }



    return null;
  }

  disablePastDatesFilter = (d: Date | null): boolean => {
    return moment().diff(d, 'days') >= 1 ? false : true;
  }

  disablePastDatesFilterForDatedeFin = (d: Date | null): boolean => {
    let DateDeDebut = this._CampagnePlanificationService.form.value.DateDeDebut
    return moment(DateDeDebut).isBefore(d, "day");
  }


  ngOnDestroy() {
    this.RecurrenceSub.unsubscribe();
    this.DateDeDebutSub.unsubscribe();
    this.DateDeFinSub.unsubscribe();
    this.DetailRecurrenceSub.unsubscribe();
  }

}
