<app-rythme-envoi></app-rythme-envoi>
<app-vitesse-distribution></app-vitesse-distribution>
<app-choix-recurrence *ngIf="_GlobalDataService.Campagne.CodeVitesse"></app-choix-recurrence>
<app-campagne-une-seule-fois *ngIf="_GlobalDataService.Campagne.FrequenceEnvoi == 'Une seule fois'">
</app-campagne-une-seule-fois>
<app-campagne-recuerrente *ngIf="_GlobalDataService.Campagne.FrequenceEnvoi == 'Récurrente' ">
</app-campagne-recuerrente>


<!-- Stepper navigation  -->
<mat-grid-list class="gtmt-stepper-nav" cols="2" rowHeight="10rem" [gutterSize]="'5rem'">
  <mat-grid-tile class="gtmt-jc-end" colspan="1" rowspan="1">
    <button mat-raised-button color="white" matStepperPrevious>
      Etape précédente
    </button>
  </mat-grid-tile>
  <mat-grid-tile class="gtmt-jc-start" colspan="1" rowspan="1">
    <button mat-raised-button color="yellow" (click)="submit()">
      Etape suivante
    </button>
  </mat-grid-tile>
</mat-grid-list>