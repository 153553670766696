<mat-list>
    <mat-list-item>
        <span>  {{Adresse.NomAdresse}} </span>
    </mat-list-item>
    <mat-list-item>
        <span> {{ Adresse.Ville }} </span>
        <span> {{ Adresse.CodePostal }} </span>
    </mat-list-item>
    <mat-list-item>
        <span> {{Adresse.Numero}} </span>
    </mat-list-item>
    <mat-list-item>
        <span> {{ Adresse.RaisonSociale }} </span>
    </mat-list-item>
</mat-list>