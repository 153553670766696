import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute,  NavigationEnd,  Router } from '@angular/router';
import { Enveloppe } from '@core/models/enveloppe.model';
import { navPaths, Support } from '@core/models/support.model';
import { CampagneTypologieService, GlobalDataService } from '@core/services';
import { ProduitService } from '@core/services/produit.service';

@Component({
  selector: 'app-typologie-enveloppe-card',
  templateUrl: './typologie-enveloppe-card.component.html',
  styleUrls: ['./typologie-enveloppe-card.component.scss']
})
export class TypologieEnveloppeCardComponent implements OnInit {


  @Input() EditMode?: boolean;
  @Input() showEnveloppe?: boolean;
  @Input() showFormat?: boolean;
  @Input() showDimension?: boolean;
  @Input() showGrammage?: boolean ;
  @Input() showFinition?: boolean;
  @Input() showEncre?: boolean;

  enveloppe: Enveloppe = new Enveloppe();

  navigationOption = {};

  navigationSubscription;

  constructor(
    private router: Router,
    private readonly activatedRoute: ActivatedRoute,
    public _GlobalDataService: GlobalDataService,
    private _ProduitService: ProduitService,
    private _CampagneTypologieService: CampagneTypologieService,

  ) {
    // subscribe to the router events. Store the subscription so we can
    // unsubscribe later.
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialise();
      }
    });
  }

  initialise() {
    this.navigationOption = { typologie: 'enveloppe' };

    this.enveloppe.enveloppe = this._GlobalDataService.getLibelleProduit('CodeEnveloppe', this._GlobalDataService.Campagne.CodeEnveloppe, 'enveloppe')
    this.enveloppe.dimension = this._GlobalDataService.getLibelleProduit('CodeDimensionsEnveloppe', this._GlobalDataService.Campagne.CodeDimensionsEnveloppe, 'DimensionsEnveloppe')
    this.enveloppe.grammage = this._GlobalDataService.getLibelleProduit('CodeGrammageEnveloppe', this._GlobalDataService.Campagne.CodeGrammageEnveloppe, 'GrammageEnveloppe')
    this.enveloppe.finition = this._GlobalDataService.getLibelleProduit('CodeFinitionEnveloppe', this._GlobalDataService.Campagne.CodeFinitionEnveloppe, 'FinitionEnveloppe')
    this.enveloppe.Encre = this._GlobalDataService.getLibelleProduit('CodeEncreEnveloppe', this._GlobalDataService.Campagne.CodeEncreEnveloppe, 'EncreEnveloppe');


  }


  ngOnInit(): void {
    this.initialise();
  }

  changeFormat(): void {
    console.log('changeFormat');
    this._CampagneTypologieService.navigate(navPaths.format, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  changeEnveloppe() {
    console.log('changeEnveloppe');
    this._CampagneTypologieService.navigate(navPaths.enveloppe, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  changeDimension(): void {
    console.log('changeDimension');
    this._CampagneTypologieService.navigate(navPaths.dimension, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  changeGrammage(): void {
    console.log('changeGrammage');
    console.log('this._CampagneTypologieService.dataGrammagesSupports');
    console.log(this._CampagneTypologieService.dataGrammagesSupports);
    this._CampagneTypologieService.navigate(navPaths.grammage, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  changeFinition(): void {
    console.log('changeFinition');
    this._CampagneTypologieService.navigate(navPaths.finition, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  changeEncre(): void {
    console.log('changeEncre - this.navigationOption');
    console.log(this.navigationOption)
    this._CampagneTypologieService.navigate(navPaths.encre, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}
