import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { NavigationEnd, Router } from '@angular/router';
import { ChampsFusion } from '@core/models/champsFusion.model';
import { AdressesDestinatairesSfService, GlobalDataService, PersonalisationSfService, SalesforceService, SharedListSalesforceService } from '@core/services';
import { CampagneService } from '@core/services/campagne.service';
import { ChampsFusionService } from '@core/services/champs-fusion.service';
import { debounceTime, distinctUntilChanged, fromEvent, map, Observable, Subscription } from 'rxjs';



@Component({
  selector: 'app-campagne-adresses-destinataires-sf',
  templateUrl: './campagne-adresses-destinataires-sf.component.html',
  styleUrls: ['./campagne-adresses-destinataires-sf.component.scss']
})
export class CampagneAdressesDestinatairesSfComponent implements OnInit {

  @ViewChild('sharedListNameRef') private sharedListNameRef!: ElementRef;
  @ViewChild('champFusionRef') private champFusionRef!: ElementRef;


  constructor(
    private _stepper: MatStepper,
    public _GlobalDataService: GlobalDataService,
    public _ChampsFusionService: ChampsFusionService,
    public  adressesDestinatairesSfService: AdressesDestinatairesSfService,
    public personalisationSfService: PersonalisationSfService,
    private _SharedListSalesforceService: SharedListSalesforceService  ) {
  }

  ngOnInit() {
    if (!this._GlobalDataService.isSalesforceContext())
      return;
    this.adressesDestinatairesSfService.initialise();

  }

  ngAfterViewInit() {
    // streams
    const keyup1$ = fromEvent(this.sharedListNameRef.nativeElement, 'keyup');
    // wait .5s between keyups to emit current value
    keyup1$
      .pipe(
        map((event: any) => event.currentTarget.value),
        debounceTime(300),
        distinctUntilChanged(),
        map(value => this.filtersharedLists(value)),
      )
      .subscribe((result) => {
        console.log(result);
        this.adressesDestinatairesSfService.sharedLists = result;
      });

    // streams
    const keyup2$ = fromEvent(this.champFusionRef.nativeElement, 'keyup');

    // wait .5s between keyups to emit current value
    keyup2$
      .pipe(
        map((event: any) => event.currentTarget.value),
        debounceTime(300),
        distinctUntilChanged(),
        map(value => this.filterChampsFusion(value)),
      )
      .subscribe((result) => {
        console.log(result);
        this.adressesDestinatairesSfService.filteredAvailableChampsFusion = result;
      });
  }

  private filtersharedLists(sharedListName: string): any[] {
    return this._GlobalDataService.sharedLists.filter(ListName => ListName.dataExtensionName.toLowerCase().includes(sharedListName.toLowerCase()));
  }

  private filterChampsFusion(champFusionName: string): any[] {
    return this.adressesDestinatairesSfService.availableChampsFusion
      .filter(cf => cf.ChampFusion.toLowerCase().includes(champFusionName.toLowerCase()))
      .filter(cf => this.adressesDestinatairesSfService.selectedChampsFusion.indexOf(cf) < 0);
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  optionSelected(sharedList: any) {
    console.log(sharedList);

    this.adressesDestinatairesSfService.availableChampsFusion = [];
    this.adressesDestinatairesSfService.filteredAvailableChampsFusion = [];
    this.adressesDestinatairesSfService.selectedChampsFusion = [];

    this._GlobalDataService.Campagne.ExtensionId = sharedList.id;

    this._SharedListSalesforceService.getDataExtensionList(sharedList.id, '', sharedList.dataExtensionName)
      .subscribe(async (response) => {
        console.log("SF response CF", response);
        if (response === null) {
          this.adressesDestinatairesSfService.availableChampsFusion = [];
        } else {
          this.adressesDestinatairesSfService.availableChampsFusion = response;
          this.adressesDestinatairesSfService.filteredAvailableChampsFusion = this.adressesDestinatairesSfService.availableChampsFusion;
        }
      });
  }


  selectChampFusion(champsFusion: ChampsFusion) {
    transferArrayItem(
      this.adressesDestinatairesSfService.filteredAvailableChampsFusion,
      this.adressesDestinatairesSfService.selectedChampsFusion,
      this.adressesDestinatairesSfService.filteredAvailableChampsFusion.indexOf(champsFusion),
      this.adressesDestinatairesSfService.selectedChampsFusion.length - 1
    );
  }

  unselectChampFusion(champsFusion: ChampsFusion) {
    transferArrayItem(
      this.adressesDestinatairesSfService.selectedChampsFusion,
      this.adressesDestinatairesSfService.filteredAvailableChampsFusion,
      this.adressesDestinatairesSfService.selectedChampsFusion.indexOf(champsFusion),
      this.adressesDestinatairesSfService.filteredAvailableChampsFusion.length - 1
    );
  }

  ValiderSelection() {
    this.adressesDestinatairesSfService.selectedChampsFusion.forEach((value, index) => {
      console.log(value.ChampFusion + ' : ' + index);
      this.adressesDestinatairesSfService.selectedChampsFusion[index].Sequence = index;
      this.adressesDestinatairesSfService.selectedChampsFusion[index].IsPaveAdresse = "true";
    });

    this._GlobalDataService.CampagneExt.ChampsFusion = this.adressesDestinatairesSfService.selectedChampsFusion.concat(this.personalisationSfService.selectedChampsFusion);
    this._ChampsFusionService.upsert({
      IdCampagne: this._GlobalDataService.Campagne.IdCampagne,
      ChampsFusion: this._GlobalDataService.CampagneExt.ChampsFusion 
    }).subscribe(() => {
      // this.personalisationSfService.initialise();
    }, (error) => { console.error(error) });
  }

  Vider() {
    console.log('Vider');
    this.adressesDestinatairesSfService.filteredAvailableChampsFusion = this.adressesDestinatairesSfService.filteredAvailableChampsFusion.concat(this.adressesDestinatairesSfService.selectedChampsFusion);
    this.adressesDestinatairesSfService.selectedChampsFusion = [];  
  }

  submit() {
    //call API
    this._stepper.next();
//    this.ValiderSelection();
  }

  ngOnDestroy() {
  }

}
