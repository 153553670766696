<!-- Votre sélection -->
<ng-container *ngIf="_CampagneTypologieService.dataSupports.length == 1">
  <app-typologie-card [EditMode]=true [showFormat]=true
    [showDimension]="_GlobalDataService.Campagne.CodeDimensionsSupport != ''"
    [showGrammage]="_GlobalDataService.Campagne.CodeGrammageSupport != ''"
    [showFinition]="_GlobalDataService.Campagne.CodeFinitionSupport != ''"
    [showEncre]="_GlobalDataService.Campagne.CodeEncreSupport != ''">
  </app-typologie-card>
</ng-container>

<!-- Votre sélection Lettre & Enveloppe-->
<ng-container *ngIf="_CampagneTypologieService.dataSupports.length > 1">
  <app-selection-lettre-enveloppe-card [EditMode]=true></app-selection-lettre-enveloppe-card>
</ng-container>

<ng-container *ngIf="!_GlobalDataService.isEmpty(_GlobalDataService.CampagneExt.Produit)">
  <p>
    Le volume requis pour cette commande est de <B> {{ _GlobalDataService.CampagneExt.Produit.MinFabrication}} PLIS
      MINIMUM </B> et <B>{{_GlobalDataService.CampagneExt.Produit.MaxFabrication === '0' ? 'PAS DE ' : 'de ' +
      _GlobalDataService.CampagneExt.Produit.MaxFabrication}} PLIS MAXIMUM </B>
  </p>
</ng-container>

<!-- Stepper navigation  -->
<div  class="gtmt-stepper-nav">
  <mat-grid-list cols="2" rowHeight="10rem" [gutterSize]="'5rem'">
    <mat-grid-tile class="gtmt-jc-end" colspan="1" rowspan="1">
      <button mat-raised-button color="white" matStepperPrevious>
        Etape précédente
      </button>
    </mat-grid-tile>
    <mat-grid-tile class="gtmt-jc-start" colspan="1" rowspan="1">
      <button mat-raised-button color="yellow" (click)="submit()">
        Etape suivante
      </button>
    </mat-grid-tile>
  </mat-grid-list>
</div>
