import { AccountService } from "./account.service";
import { AdressesDestinatairesSfService } from "./adresses-destinataires-sf.service";
import { AdressesDestinatairesService } from "./adresses-destinataires.service";
import { AnnonceurService } from "./annonceur.service";
import { AuthGuardService } from "./auth-guard.service";
import { AuthenticationService } from "./authentication.service";
import { CampageResolverService } from "./campage-resolver.service";
import { CampagneAdressesService } from "./campagne-adresses.service";
import { CampagneDetailsService } from "./campagne-details.service";
import { CampagnePlanificationService } from "./campagne-planification.service";
import { CampagneTypologieService } from "./campagne-typologie.service";
import { GlobalDataService } from "./global-data.service";
import { PersonalisationSfService } from "./personalisation-sf.service";
import { PersonalisationService } from "./personalisation.service";
import { PopoutService } from "./popout.service";
import { SalesforceService } from "./salesforce.service";
import { SharedListSalesforceService } from "./shared-list-salesforce.service";
import { StdUserRouteAccessService } from "./StdUserRouteAccess.service";
import { UserRouteAccessService } from "./user-route-access.service";

export const services: any[] = [
    GlobalDataService,
    AnnonceurService,
    AuthenticationService,
    StdUserRouteAccessService,
    UserRouteAccessService,
    PopoutService,
    AccountService,
    SalesforceService,
    SharedListSalesforceService,
    CampageResolverService,
    CampagnePlanificationService,
    AuthGuardService,
    AdressesDestinatairesSfService,
    PersonalisationSfService,
    AdressesDestinatairesService,
    PersonalisationService,
    CampagneAdressesService,
    CampagneTypologieService,
    CampagneDetailsService
];

export * from "./annonceur.service";
export * from "./global-data.service";
export * from "./authentication.service";
export * from "./StdUserRouteAccess.service";
export * from "./user-route-access.service";
export * from "./popout.service";
export * from "./campagne-typologie.service";
export * from "./account.service";
export * from "./salesforce.service";
export * from "./shared-list-salesforce.service";
export * from "./campage-resolver.service";
export * from "./campagne-planification.service";
export * from "./auth-guard.service";
export * from "./adresses-destinataires-sf.service";
export * from "./personalisation-sf.service";
export * from "./adresses-destinataires.service";
export * from "./personalisation.service";
export * from "./campagne-adresses.service";
export * from "./campagne-details.service";









