// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: "dev",
  production: false,
  url_entrypoint: '',
  url_api: '/service/backend',
  url_scheduler: '/service/scheduler',
  url_gateway: '/service/gateway',
  url_standalone: '/service/sftp',
  version: require('../../package.json').version,
  AppIdv10: 'd1ebc284-761a-40bb-bcf4-ca13e8609969',
  InstallIdv10: "e06ecb1d-1986-4ea0-bc2b-7a43e8f6b0f6",


  // url_entrypoint: 'https://d3qy8ae9s3e2dm.cloudfront.net',
  // url_api:'https://d3qy8ae9s3e2dm.cloudfront.net/service/backend',
  // url_scheduler:'https://d3qy8ae9s3e2dm.cloudfront.net/service/scheduler',
  // url_gateway: 'https://d3qy8ae9s3e2dm.cloudfront.net/service/gateway',
  // url_standalone: 'https://d3qy8ae9s3e2dm.cloudfront.net/service/sftp',
  // version: require('../../package.json').version,
  // AppIdv10: 'd1ebc284-761a-40bb-bcf4-ca13e8609969',
  // InstallIdv10: "e06ecb1d-1986-4ea0-bc2b-7a43e8f6b0f6",

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
