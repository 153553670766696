<mat-sidenav-container fullscreen>
	<mat-sidenav mode="side" #sidenav opened [fixedInViewport]="true" [fixedTopGap]="0" autosize opened="true"
		(mouseenter)="increase()" (mouseleave)="decrease()" [ngClass]="{
		'mat-sidenav-expanded': isExpanded,
		'mat-sidenav-shrinked': !isExpanded
		}">

		<div class="logo-container">
			<div class="log">

			</div>
		</div>

		<mat-nav-list>
			<mat-nav-list>
				<mat-list-item *ngFor='let sidenavitem of sidenavItems; let index = index'
					[class.mat-list-item-selected]='selectedItem === index'
					(click)='selectedItem = index; $event.stopPropagation();sidenavitem.action()'>
					<mat-icon mat-list-icon svgIcon="{{ sidenavitem.icon }}"></mat-icon>
					<h4 mat-line *ngIf="isExpanded"> {{ sidenavitem.title }} </h4>
				</mat-list-item>
			</mat-nav-list>
		</mat-nav-list>

		<div class="help-container">
			<div styles="{cursor: pointer}" [mdePopoverTriggerFor]="appPopover" mdePopoverTriggerOn="click"
				#popoverTrigger="mdePopoverTrigger">
				<mat-icon svgIcon="Infos">Informations</mat-icon>
				<span *ngIf="isExpanded"> Informations </span>
			</div>
			<mat-divider></mat-divider>
			<div (click)="openHelp()">
				<mat-icon svgIcon="Aide">Aide</mat-icon>
				<span *ngIf="isExpanded"> Aide </span>
			</div>
		</div>

		<div class="version-container"  *ngIf="isExpanded">
			<span> Version de l'application : <strong>{{version}}</strong> </span>     
		</div>

		<div class="version-container"  *ngIf="!isExpanded">
			<span> <strong>v{{version}}</strong> </span>     
		</div>		

	</mat-sidenav>
	<mat-sidenav-content>
		<router-outlet name="navview"></router-outlet>
	</mat-sidenav-content>
</mat-sidenav-container>


<mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false">
	<mat-card class="gtmt-popover-card">
		<mat-card-header> Cap Mailing Plateforme
		</mat-card-header>
		<mat-card-content>

			<p>
				Information application :
				<br>
				Cap Mailing est une application créée par La Poste.
				<br>
				Version de l'application : <strong>{{version}}</strong>
				<br>
				Un problème avec cette application ?
				<br>
			</p>

			<p>
				N'hésitez pas à contacter le SAV ou à consulter <span (click)="openHelp()"
					style="cursor: pointer;"><strong>FAQ</strong> </span>
			</p>

		</mat-card-content>
	</mat-card>
</mde-popover>