<div class="gtmt-btn-save" style="text-align: right">
  <app-campagne-save [campaignName]="this.campaignName">
  </app-campagne-save>
</div>

<mat-horizontal-stepper [@.disabled]="true" [selectedIndex]="selectedIndex" labelPosition="end" [linear]="false"
  #stepper color="accent" (selectionChange)="selectionChange($event)">
  <!-- Debut -->

  <!-- Identification -->
  <mat-step completed="false">
    <ng-template matStepLabel>Identification </ng-template>

    <mat-grid-list cols="10" rowHeight="12rem" [gutterSize]="'2rem'">
      <mat-grid-tile class="gtmt-ai-start" colspan="10" rowspan="10">
        <app-campagne-identification style="width: 100%" *ngIf="_GlobalDataService.isLoaded"
          (nameChangeEvent)="campaignNameChange($event)">
        </app-campagne-identification>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-step>

  <!-- Typologie -->
  <mat-step completed="false">
    <ng-template matStepLabel> Typologie </ng-template>

    <mat-grid-list cols="10" rowHeight="12rem" [gutterSize]="'2rem'">
      <mat-grid-tile class="gtmt-ai-start" colspan="8" rowspan="10">
        <app-campagne-typologie style="width: 100%" *ngIf="_GlobalDataService.isLoaded"></app-campagne-typologie>
      </mat-grid-tile>
      <mat-grid-tile class="gtmt-ai-start" colspan="2" rowspan="20">
        <app-estimation-card style="width: 100%" *ngIf="_GlobalDataService.isLoaded"
          [showInfo]="_GlobalDataService.Campagne.showInfo" [showRefresh]="_GlobalDataService.Campagne.showRefresh"
          [showunitAmountWithoutTax]="
            _GlobalDataService.Campagne.showunitAmountWithoutTax
          " [showamountWithoutTax]="
            _GlobalDataService.Campagne.showamountWithoutTax
          ">
        </app-estimation-card>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-step>

  <!-- Créations -->
  <mat-step completed="false">
    <ng-template matStepLabel> Créations </ng-template>

    <mat-grid-list cols="10" rowHeight="12rem" [gutterSize]="'2rem'">
      <mat-grid-tile class="gtmt-ai-start" colspan="8" rowspan="100">
        <app-campagne-creation style="width: 100%" *ngIf="_GlobalDataService.isLoaded"></app-campagne-creation>
      </mat-grid-tile>
      <mat-grid-tile class="gtmt-ai-start" colspan="2" rowspan="100">
        <app-estimation-card style="width: 100%" *ngIf="_GlobalDataService.isLoaded"
          [showInfo]="_GlobalDataService.Campagne.showInfo" [showRefresh]="_GlobalDataService.Campagne.showRefresh"
          [showunitAmountWithoutTax]="
            _GlobalDataService.Campagne.showunitAmountWithoutTax
          " [showamountWithoutTax]="
            _GlobalDataService.Campagne.showamountWithoutTax
          ">
        </app-estimation-card>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-step>

  <!-- Personnalisation -->
  <mat-step completed="false">
    <ng-template matStepLabel> Personnalisation </ng-template>

    <mat-grid-list cols="10" rowHeight="14rem" [gutterSize]="'2rem'">
      <mat-grid-tile class="gtmt-ai-start" colspan="8" rowspan="10">
        <app-campagne-personnalisation
          style="width: 100%"
          *ngIf="
            _GlobalDataService.isLoaded && _GlobalDataService.isEloquaContext()
          "
        >
        </app-campagne-personnalisation>
        <app-campagne-personnalisation-sf
          style="width: 100%"
          *ngIf="
            _GlobalDataService.isLoaded &&
            _GlobalDataService.isSalesforceContext()
          "
        >
        </app-campagne-personnalisation-sf>
        <app-campagne-personnalisation-standalone style="width: 100%" *ngIf=" 
            _GlobalDataService.isLoaded &&
           _GlobalDataService.isStandaloneContext()
        ">
        </app-campagne-personnalisation-standalone>
      </mat-grid-tile>
      <mat-grid-tile class="gtmt-ai-start" colspan="2" rowspan="10">
        <app-estimation-card style="width: 100%" *ngIf="_GlobalDataService.isLoaded"
          [showInfo]="_GlobalDataService.Campagne.showInfo" [showRefresh]="_GlobalDataService.Campagne.showRefresh"
          [showunitAmountWithoutTax]="
            _GlobalDataService.Campagne.showunitAmountWithoutTax
          " [showamountWithoutTax]="
            _GlobalDataService.Campagne.showamountWithoutTax
          ">
        </app-estimation-card>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-step>


  <!-- TODO : a supprimer -->
  <!-- Ciblage -->
  <!-- <mat-step completed="false" *ngIf="_GlobalDataService.isStandaloneContext()">
    <ng-template matStepLabel> Personnalisation </ng-template>
    <app-fichiers-ciblage></app-fichiers-ciblage>
  </mat-step> -->

  <!-- Planification -->
  <mat-step completed="false">
    <ng-template matStepLabel> Planification </ng-template>

    <mat-grid-list cols="10" rowHeight="14rem" [gutterSize]="'2rem'">
      <mat-grid-tile class="gtmt-ai-start" colspan="8" rowspan="16">
        <app-campagne-planification style="width: 100%" *ngIf="_GlobalDataService.isLoaded">
        </app-campagne-planification>
      </mat-grid-tile>
      <mat-grid-tile class="gtmt-ai-start" colspan="2" rowspan="16">
        <app-estimation-card style="width: 100%" *ngIf="_GlobalDataService.isLoaded"
          [showInfo]="_GlobalDataService.Campagne.showInfo" [showRefresh]="_GlobalDataService.Campagne.showRefresh"
          [showunitAmountWithoutTax]="
            _GlobalDataService.Campagne.showunitAmountWithoutTax
          " [showamountWithoutTax]="
            _GlobalDataService.Campagne.showamountWithoutTax
          ">
        </app-estimation-card>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-step>

  <!-- Adresses -->
  <mat-step completed="false">
    <ng-template matStepLabel> Adresses </ng-template>

    <mat-grid-list cols="10" rowHeight="18rem" [gutterSize]="'2rem'">
      <mat-grid-tile class="gtmt-ai-start" colspan="8" rowspan="10">
        <app-campagne-adresses style="width: 100%" *ngIf="_GlobalDataService.isLoaded"></app-campagne-adresses>
      </mat-grid-tile>
      <mat-grid-tile class="gtmt-ai-start" colspan="2" rowspan="10">
        <app-estimation-card style="width: 100%" *ngIf="_GlobalDataService.isLoaded"
          [showInfo]="_GlobalDataService.Campagne.showInfo" [showRefresh]="_GlobalDataService.Campagne.showRefresh"
          [showunitAmountWithoutTax]="
            _GlobalDataService.Campagne.showunitAmountWithoutTax
          " [showamountWithoutTax]="
            _GlobalDataService.Campagne.showamountWithoutTax
          ">
        </app-estimation-card>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-step>

  <!-- Récapitulatif -->
  <mat-step completed="false">
    <ng-template matStepLabel> Récapitulatif </ng-template>

    <mat-grid-list cols="10" rowHeight="12rem" [gutterSize]="'2rem'">
      <mat-grid-tile class="gtmt-ai-start" colspan="8" rowspan="18">
        <app-campagne-recapitulatif style="width: 100%" [stepper]="stepper" *ngIf="_GlobalDataService.isLoaded">
        </app-campagne-recapitulatif>
      </mat-grid-tile>
      <mat-grid-tile class="gtmt-ai-start" colspan="2" rowspan="18">
        <app-estimation-card style="width: 100%" *ngIf="_GlobalDataService.isLoaded"
          [showInfo]="_GlobalDataService.Campagne.showInfo" [showRefresh]="_GlobalDataService.Campagne.showRefresh"
          [showunitAmountWithoutTax]="
            _GlobalDataService.Campagne.showunitAmountWithoutTax
          " [showamountWithoutTax]="
            _GlobalDataService.Campagne.showamountWithoutTax
          ">
        </app-estimation-card>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-step>

  <!-- Fin -->
</mat-horizontal-stepper>