import { Component, OnInit, ViewChild } from '@angular/core';
import { AdressesDestinatairesService, AdressesDestinatairesSfService, GlobalDataService, PersonalisationService, PersonalisationSfService } from '@core/services';
import { MdePopoverTrigger } from '@material-extended/mde';

@Component({
  selector: 'app-suivi-campagne-recapilatif',
  templateUrl: './suivi-campagne-recapilatif.component.html',
  styleUrls: ['./suivi-campagne-recapilatif.component.scss']
})
export class SuiviCampagneRecapilatifComponent implements OnInit {

  @ViewChild(MdePopoverTrigger) trigger: MdePopoverTrigger;

  PersonalisationChampsFusion = this._GlobalDataService.CampagneExt.ChampsFusion.filter(cf => cf.IsPaveAdresse == '0' || cf.IsPaveAdresse == 'false');
  AdressesDestinatairesChampsFusion = this._GlobalDataService.CampagneExt.ChampsFusion.filter(cf => cf.IsPaveAdresse == '1' || cf.IsPaveAdresse == 'true');

  constructor(
    public _GlobalDataService: GlobalDataService,
    // public personalisationSfService: PersonalisationSfService,
    // public personalisationService: PersonalisationService,
    // public adressesDestinatairesSfService: AdressesDestinatairesSfService,
    // public adressesDestinatairesService: AdressesDestinatairesService
  ) { 
    console.log(this.PersonalisationChampsFusion);
    console.log(this.AdressesDestinatairesChampsFusion);    
  }

  ngOnInit(): void {
  }

}
