<div class="gtmt-container">

    <!-- Suivi de campagne courrier -->
    <mat-toolbar style="background: #F9FAFE; border-radius: 0.3rem;">
        <h1> Suivi de campagne courrier</h1>
        <button mat-raised-button color="blue" (click)="gotoSuiviCampagne()">
            Toutes vos campagnes courrier
        </button>
    </mat-toolbar>

    <mat-accordion multi="true">
        <mat-expansion-panel expanded="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    EVOLUTION DE LA CAMPAGNE COURRIER
                </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- ÉVOLUTION DE LA CAMPAGNE COURRIER -->
            <app-suivi-campagne-info></app-suivi-campagne-info>
        </mat-expansion-panel>

        <mat-expansion-panel expanded="true" *ngIf="(_GlobalDataService.isStandaloneContext() && _GlobalDataService.Campagne.importOption === 'upload')">
          <mat-expansion-panel-header>
            <mat-panel-title>
                TÉLÉCHARGEMENT DES FICHIERS
            </mat-panel-title>
          </mat-expansion-panel-header>

          <!-- Info Commande BAT -->
          <app-suivi-campagne-telechargement></app-suivi-campagne-telechargement>
        </mat-expansion-panel>

        <mat-expansion-panel expanded="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    BON À TIRER
                </mat-panel-title>
            </mat-expansion-panel-header>

            <!-- Info Commande BAT -->
            <app-suivi-campagne-bat></app-suivi-campagne-bat>
        </mat-expansion-panel>

        <mat-expansion-panel expanded="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    RECAPITULATIF DE LA CAMPAGNE COURRIER
                </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- RÉCAPITULATIF DE LA CAMPAGNE COURRIER -->
            <app-suivi-campagne-recapilatif></app-suivi-campagne-recapilatif>
        </mat-expansion-panel>
        <mat-expansion-panel expanded="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    OCCURENCES PRECEDENTES DE LA CAMPAGNE COURRIER
                </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- RÉCAPITULATIF DE LA CAMPAGNE COURRIER -->
            <app-suivi-campagne-list-commandes></app-suivi-campagne-list-commandes>
        </mat-expansion-panel>
    </mat-accordion>

</div>
