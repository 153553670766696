import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationBehaviorOptions, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { navPaths, Support } from '@core/models/support.model';
import { CampagneTypologieService, GlobalDataService } from '@core/services';
import { ProduitService } from '@core/services/produit.service';

@Component({
  selector: 'app-typologie-card',
  templateUrl: './typologie-card.component.html',
  styleUrls: ['./typologie-card.component.scss']
})
export class TypologieCardComponent implements OnInit {

  @Input() EditMode?: boolean = false;
  @Input() showPage?: boolean = false;
  @Input() showFormat?: boolean = true;
  @Input() showDimension?: boolean = true;
  @Input() showGrammage?: boolean = true;
  @Input() showFinition?: boolean = true;
  @Input() showEncre?: boolean = true;

  support: Support = new Support();

  nbcols: number = 6;


  navigationOption = { };

  navigationSubscription;

  constructor(
    private router: Router,
    private readonly activatedRoute: ActivatedRoute,
    public _GlobalDataService: GlobalDataService,
    private _CampagneTypologieService: CampagneTypologieService,

  ) {
    // subscribe to the router events. Store the subscription so we can
    // unsubscribe later.
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialise();
      }
    });
  }

  initialise() {
    this.navigationOption = { typologie: 'autre' };
    this.support.typologie = this._GlobalDataService.getLibelleProduit('CodeTypologie', this._GlobalDataService.Campagne.CodeTypologie, 'Typologie')
    this.support.support = this._GlobalDataService.getLibelleProduit('CodeTypologie', this._GlobalDataService.Campagne.CodeTypologie, 'Typologie')
    this.support.dimension = this._GlobalDataService.getLibelleProduit('CodeDimensionsSupport', this._GlobalDataService.Campagne.CodeDimensionsSupport, 'DimensionsSupport')
    this.support.grammage = this._GlobalDataService.getLibelleProduit('CodeGrammageSupport', this._GlobalDataService.Campagne.CodeGrammageSupport, 'GrammageSupport')
    this.support.finition = this._GlobalDataService.getLibelleProduit('CodeFinitionSupport', this._GlobalDataService.Campagne.CodeFinitionSupport, 'FinitionSupport')
    this.support.Encre = this._GlobalDataService.getLibelleProduit('CodeEncreSupport', this._GlobalDataService.Campagne.CodeEncreSupport, 'EncreSupport');
  }


  ngOnInit(): void {
    this.initialise();
  }

  changeFormat(): void {
    this._CampagneTypologieService.navigate(navPaths.format, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  changePage() {
    this._CampagneTypologieService.navigate(navPaths.page, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  changeDimension(): void {
    this._CampagneTypologieService.navigate(navPaths.dimension, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  changeGrammage(): void {
    this._CampagneTypologieService.navigate(navPaths.grammage, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  changeFinition(): void {
    this._CampagneTypologieService.navigate(navPaths.finition, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  changeEncre(): void {
    this._CampagneTypologieService.navigate(navPaths.encre, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}
