import { CampagneAdressesComponent } from "./campagne-adresses/campagne-adresses.component";
import { CampagneCreationComponent } from "./campagne-creation/campagne-creation.component";
import { CampagneDetailsComponent } from "./campagne-details/campagne-details.component";
import { CampagneDuplicationDialogComponent } from "./campagne-duplication-dialog/campagne-duplication-dialog.component";
import { CampagneIdentificationComponent } from "./campagne-identification/campagne-identification.component";
import { CampagnePersonnalisationComponent } from "./campagne-personnalisation/campagne-personnalisation.component";
import { CampagnePlanificationComponent } from "./campagne-planification/campagne-planification.component";
import { CampagneRecapitulatifComponent } from "./campagne-recapitulatif/campagne-recapitulatif.component";
import { CampagneTypologieDimensionComponent } from "./campagne-typologie/campagne-typologie-dimension/campagne-typologie-dimension.component";
import { CampagneTypologieSelectionEnveloppeComponent } from "./campagne-typologie/campagne-typologie-selection-enveloppe/campagne-typologie-selection-enveloppe.component";
import { CampagneTypologieFinitionComponent } from "./campagne-typologie/campagne-typologie-finition/campagne-typologie-finition.component";
import { CampagneTypologieFormatComponent } from "./campagne-typologie/campagne-typologie-format/campagne-typologie-format.component";
import { CampagneTypologieGrammageComponent } from "./campagne-typologie/campagne-typologie-grammage/campagne-typologie-grammage.component";
import { CampagneTypologieSelectionComponent } from "./campagne-typologie/campagne-typologie-selection/campagne-typologie-selection.component";
import { CampagneTypologieComponent } from "./campagne-typologie/campagne-typologie.component";
import { MessageConfirmationComponent } from "./confirmation/message-confirmation/message-confirmation.component";
import { DialogAnnulationCampagneComponent } from "./dialog-annulation-campagne/dialog-annulation-campagne.component";
import { DialogMessageComponent } from "./dialog-message/dialog-message.component";
import { HelpComponent } from "./help/help.component";
import { HomeComponent } from "./home/home.component";
import { ListCampagneComponent } from "./list-campagne/list-campagne.component";
import { ParametrageComponent } from "./parametrage/parametrage.component";
import { CampagneValidationBatComponent } from "./campagne-validation-bat/campagne-validation-bat.component";
import { CampagneTypologiePageComponent } from "./campagne-typologie/campagne-typologie-page/campagne-typologie-page.component";
import { CampagneTypologieSelectionLettreComponent } from "./campagne-typologie/campagne-typologie-selection-lettre/campagne-typologie-selection-lettre.component";
import { CampagneTypologieEnveloppeComponent } from "./campagne-typologie/campagne-typologie-enveloppe/campagne-typologie-enveloppe.component";
import { DialogConfirmCampagneComponent } from "./dialog-confirm-campagne/dialog-confirm-campagne.component";
import { CampagnePersonnalisationSfComponent } from "./campagne-personnalisation-sf/campagne-personnalisation-sf.component";
import { CampagneAdressesDestinatairesComponent } from "./campagne-adresses/campagne-adresses-destinataires/campagne-adresses-destinataires.component";
import { ChoixRecurrenceComponent } from "./campagne-planification/choix-recurrence/choix-recurrence.component";
import { VitesseDistributionComponent } from "./campagne-planification/vitesse-distribution/vitesse-distribution.component";
import { CampagneRecuerrenteComponent } from "./campagne-planification/campagne-recuerrente/campagne-recuerrente.component";
import { CampagneUneSeuleFoisComponent } from "./campagne-planification/campagne-une-seule-fois/campagne-une-seule-fois.component";
import { RythmeEnvoiComponent } from "./campagne-planification/rythme-envoi/rythme-envoi.component";
import { CampagneTypologieEncreComponent } from "./campagne-typologie/campagne-typologie-encre/campagne-typologie-encre.component";
import { CampagneAdressesDestinatairesSfComponent } from "./campagne-adresses/campagne-adresses-destinataires-sf/campagne-adresses-destinataires-sf.component";
import { CampagneAdressesOptionsRetourComponent } from "./campagne-adresses/campagne-adresses-options-retour/campagne-adresses-options-retour.component";
import { CampagneAdresseRetourComponent } from "./campagne-adresses/campagne-adresse-retour/campagne-adresse-retour.component";
import { AdresseRetourCardComponent } from "./campagne-adresses/adresse-retour-card/adresse-retour-card.component";
import { AdresseRetourDialogComponent } from "./campagne-adresses/adresse-retour-dialog/adresse-retour-dialog.component";
import { CampagneAlliageComponent } from "./campagne-adresses/campagne-alliage/campagne-alliage.component";
import { CampagneGestionLogoComponent } from "./campagne-adresses/campagne-gestion-logo/campagne-gestion-logo.component";
import { FichiersCreationsComponent } from "./campagne-creation/fichiers-creations/fichiers-creations.component";
import { FichiersPersonnalisationsComponent } from "./campagne-creation/fichiers-personnalisations/fichiers-personnalisations.component";
import { TabVisualisationComponent } from "./campagne-ciblage/tab-visualisation/tab-visualisation.component";

export const components: any[] = [
    CampagneIdentificationComponent,
    CampagneCreationComponent,
    CampagneTypologieComponent,
    CampagnePersonnalisationComponent,
    CampagnePlanificationComponent,
    RythmeEnvoiComponent,
    ChoixRecurrenceComponent,
    VitesseDistributionComponent,
    CampagneRecuerrenteComponent,
    CampagneUneSeuleFoisComponent,
    CampagneRecapitulatifComponent,
    CampagneAdressesComponent,
    HomeComponent,
    ListCampagneComponent,
    ParametrageComponent,
    MessageConfirmationComponent,
    DialogMessageComponent,
    DialogAnnulationCampagneComponent,
    HelpComponent,
    CampagneDetailsComponent,
    CampagneDuplicationDialogComponent,
    CampagneValidationBatComponent,
    CampagneTypologieSelectionComponent,
    CampagneTypologieFormatComponent,
    CampagneTypologieDimensionComponent,
    CampagneTypologieFinitionComponent,
    CampagneTypologieGrammageComponent,
    CampagneTypologieEncreComponent,
    CampagneTypologieSelectionEnveloppeComponent,
    CampagneTypologieSelectionLettreComponent,
    CampagneTypologiePageComponent,
    CampagneTypologieEnveloppeComponent,
    DialogConfirmCampagneComponent,
    CampagnePersonnalisationSfComponent,
    CampagneAdressesDestinatairesComponent,
    CampagneAdressesDestinatairesSfComponent,
    CampagneAdressesOptionsRetourComponent,
    CampagneAdresseRetourComponent,
    AdresseRetourCardComponent,
    AdresseRetourDialogComponent,
    CampagneAlliageComponent,
    CampagneGestionLogoComponent,
    FichiersCreationsComponent,
    FichiersPersonnalisationsComponent,
    TabVisualisationComponent
];

export * from "./campagne-adresses/campagne-adresses.component";
export * from "./campagne-creation/campagne-creation.component";
export * from "./campagne-identification/campagne-identification.component";
export * from "./campagne-personnalisation/campagne-personnalisation.component";
export * from "./campagne-planification/campagne-planification.component";
export * from "./campagne-recapitulatif/campagne-recapitulatif.component";
export * from "./campagne-typologie/campagne-typologie.component";
export * from "./home/home.component";
export * from "./list-campagne/list-campagne.component";
export * from "./parametrage/parametrage.component";
export * from "./confirmation/message-confirmation/message-confirmation.component";
export * from "./dialog-message/dialog-message.component";
export * from "./dialog-annulation-campagne/dialog-annulation-campagne.component";
export * from "./help/help.component";
export * from "./campagne-details/campagne-details.component";
export * from "./campagne-duplication-dialog/campagne-duplication-dialog.component";
export * from "./campagne-validation-bat/campagne-validation-bat.component";
export * from "./campagne-typologie/campagne-typologie-selection/campagne-typologie-selection.component";
export * from "./campagne-typologie/campagne-typologie-format/campagne-typologie-format.component";
export * from "./campagne-typologie/campagne-typologie-dimension/campagne-typologie-dimension.component";
export * from "./campagne-typologie/campagne-typologie-grammage/campagne-typologie-grammage.component";
export * from "./campagne-typologie/campagne-typologie-finition/campagne-typologie-finition.component";
export * from "./campagne-typologie/campagne-typologie-encre/campagne-typologie-encre.component";
export * from "./campagne-typologie/campagne-typologie-selection-enveloppe/campagne-typologie-selection-enveloppe.component";
export * from "./campagne-typologie/campagne-typologie-selection-lettre/campagne-typologie-selection-lettre.component";
export * from "./campagne-typologie/campagne-typologie-page/campagne-typologie-page.component";
export * from "./campagne-typologie/campagne-typologie-selection-lettre/campagne-typologie-selection-lettre.component";
export * from "./campagne-typologie/campagne-typologie-enveloppe/campagne-typologie-enveloppe.component";
export * from "./dialog-confirm-campagne/dialog-confirm-campagne.component";
export * from "./campagne-personnalisation-sf/campagne-personnalisation-sf.component";
export * from "./campagne-adresses/campagne-adresses-destinataires/campagne-adresses-destinataires.component";
export * from "./campagne-adresses/campagne-adresses-destinataires-sf/campagne-adresses-destinataires-sf.component";
export * from "./campagne-planification/choix-recurrence/choix-recurrence.component";
export * from "./campagne-planification/vitesse-distribution/vitesse-distribution.component";
export * from "./campagne-planification/campagne-recuerrente/campagne-recuerrente.component";
export * from "./campagne-planification/rythme-envoi/rythme-envoi.component";
export * from "./campagne-adresses/campagne-adresses-options-retour/campagne-adresses-options-retour.component";
export * from "./campagne-adresses/campagne-adresse-retour/campagne-adresse-retour.component";
export * from "./campagne-adresses/adresse-retour-card/adresse-retour-card.component";
// export * from "./campagne-adresses/adresse-retour-dialog/adresse-retour-dialog.component";
export * from "./campagne-adresses/campagne-alliage/campagne-alliage.component";
export * from "./campagne-adresses/campagne-gestion-logo/campagne-gestion-logo.component";
export * from "./campagne-creation/fichiers-creations/fichiers-creations.component";
export * from "./campagne-creation/fichiers-personnalisations/fichiers-personnalisations.component";
export * from "./campagne-ciblage/tab-visualisation/tab-visualisation.component"
