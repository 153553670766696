import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationBehaviorOptions, Router } from '@angular/router';
import { Adresse } from '@core/models/adresse.model';
import { AdresseRetour } from '@core/models/adresseRetour.model';
import { Campagne } from '@core/models/campagne.model';
import { ChampsFusion } from '@core/models/champsFusion.model';
import { FichiersCreation } from '@core/models/FichiersCreation.model';
import { Support } from '@core/models/support.model';
import { AdressesDestinatairesService, AdressesDestinatairesSfService, CampagneAdressesService, CampagnePlanificationService, CampagneTypologieService, GlobalDataService, PersonalisationService, PersonalisationSfService } from '@core/services';
import { AdresseService } from '@core/services/adresse.service';
import { CampagneService } from '@core/services/campagne.service';
import { ChampsFusionService } from '@core/services/champs-fusion.service';
import { FichierCreaService } from '@core/services/fichier-crea.service';
import { FichierPersoStandaloneService } from '@core/services/fichier-perso-standalone.service';

@Component({
  selector: 'app-campagne-duplication-dialog',
  templateUrl: './campagne-duplication-dialog.component.html',
  styleUrls: ['./campagne-duplication-dialog.component.scss']
})
export class CampagneDuplicationDialogComponent implements OnInit {

  title: string = "Sélection des paramètres à dupliquer";

  campagneFormGroup!: FormGroup;

  public LibelleSupport!: string;
  public LibelleDimensionsSupport!: string;
  public LibelleGrammageSupport!: string;
  public LibelleFinitionSupport!: string;


  public LibelleEnveloppe!: string;
  public LibelleDimensionsEnveloppe!: string;
  public LibelleGrammageEnveloppe!: string;
  public LibelleFinitionEnveloppe!: string;


  public support: Support = new Support();
  public enveloppe: Support = new Support();

  public adresse: Adresse = new Adresse();

  ListAdresses: Array<AdresseRetour> = [];

  filters: FormGroup;

  public campagne!: Campagne;

  FichiersCreation = new Array<FichiersCreation>();
  ChampsPersonnalisation = new Array<ChampsFusion>();
  ChampsAdresse = new Array<ChampsFusion>();

  navigationOption: NavigationBehaviorOptions = { state: {} };

  canDuplicate: boolean = false;

  allFilters: boolean = false;
  canDuplicateProduit: boolean = true;

  contactFilePresent: boolean = false;
  creationFilePresent: boolean = false;
  champsPersoPresent: boolean = false;
  champsAddressPresent: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CampagneDuplicationDialogComponent>,
    public _GlobalDataService: GlobalDataService,
    public _ChampsFusionService: ChampsFusionService,
    public _AdresseService: AdresseService,
    private _CampagneService: CampagneService,
    public router: Router,
    private _FichierCreaService: FichierCreaService,
    private domSanitizer: DomSanitizer,
    private _campagneTypologieService: CampagneTypologieService,
    public _CampagnePlanificationService: CampagnePlanificationService,
    public _campagneAdressesService: CampagneAdressesService,
    public personalisationService: PersonalisationService,
    public adressesDestinatairesService: AdressesDestinatairesService,
    public personalisationSfService: PersonalisationSfService,
    public adressesDestinatairesSfService: AdressesDestinatairesSfService,
    private _FichierPersoStandalone: FichierPersoStandaloneService,
    fb: FormBuilder

  ) {
    dialogRef.disableClose = true;

    console.log(this._GlobalDataService.CONTEXT);

    this.filters = fb.group({
      allFilters: false,
      Adresse: false,
      FichierCreation: false,
      ChampFusion: false,
      PaveAdresse: false,
      CodeVitesse: false,
      FrequenceEnvoi: false,
      Alliage: false,
      Support: false,
      Enveloppe: false,
      ImportCiblage: false,
    });

    this.campagne = this.data.campagne;

    this.LibelleGrammageSupport = this._GlobalDataService.getLibelleProduit('CodeGrammageSupport', this.campagne.CodeGrammageSupport, 'GrammageSupport');
    this.LibelleFinitionSupport = this._GlobalDataService.getLibelleProduit('CodeFinitionSupport', this.campagne.CodeFinitionSupport, 'FinitionSupport');
    this.LibelleSupport = this._GlobalDataService.getLibelleProduit('CodeSupport', this.campagne.CodeSupport, 'support');
    this.LibelleDimensionsSupport = this._GlobalDataService.getLibelleProduit('CodeDimensionsSupport', this.campagne.CodeDimensionsSupport, 'DimensionsSupport');

    this.LibelleGrammageEnveloppe = this._GlobalDataService.getLibelleProduit('CodeGrammageEnveloppe', this.campagne.CodeGrammageEnveloppe, 'GrammageEnveloppe');
    this.LibelleFinitionEnveloppe = this._GlobalDataService.getLibelleProduit('CodeFinitionEnveloppe', this.campagne.CodeFinitionEnveloppe, 'FinitionEnveloppe');
    this.LibelleEnveloppe = this._GlobalDataService.getLibelleProduit('CodeEnveloppe', this.campagne.CodeEnveloppe, 'enveloppe');
    this.LibelleDimensionsEnveloppe = this._GlobalDataService.getLibelleProduit('CodeDimensionsEnveloppe', this.campagne.CodeDimensionsEnveloppe, 'DimensionsEnveloppe');

    this.support = {
      support: this.LibelleSupport,
      dimension: this.LibelleDimensionsSupport,
      grammage: this.LibelleGrammageSupport,
      finition: this.LibelleFinitionSupport,
      typologie: "",
      Encre: "",
    } as Support

    this.enveloppe = {
      support: this.LibelleEnveloppe,
      dimension: this.LibelleDimensionsEnveloppe,
      grammage: this.LibelleGrammageEnveloppe,
      finition: this.LibelleFinitionEnveloppe,
      typologie: "",
      Encre: "",
    } as Support

    this.campagne.Support = this.support;
    this.campagne.Enveloppe = this.enveloppe;
    // this.campagne.ChampsFusion = new Array<ChampsFusion>();

    this.canDuplicateProduit = this._GlobalDataService.checkCampagneProduit(this.campagne);
    if (!this.canDuplicateProduit) {
      this.filters.get('Support').disable({ emitEvent: false });
      this.filters.get('Enveloppe').disable({ emitEvent: false });
    }
  }
  ngOnInit() {
    this.isContactFilePresent(this.campagne);
    this.isCreationFilePresent(this.campagne);
    this.isChampsFusionPresent(this.campagne);
  }

  ngAfterViewInit() {
    this.filters.get('allFilters').valueChanges.subscribe((checked: boolean) => {
      // if valid
      if (this.filters.get('allFilters').valid) {
        if (!this.canDuplicateProduit) {
          if (!this.campagne.Alliage) {
            Object.keys(this.filters.controls).filter(key => key != 'allFilters' && key != 'Support' && key != 'Enveloppe').forEach((key) => {
              this.filters.get(key)?.setValue(checked, { emitEvent: false });
            });
          }
          else {
            Object.keys(this.filters.controls).filter(key => key != 'allFilters' && key != 'Adresse' && key != 'Support' && key != 'Enveloppe').forEach((key) => {
              this.filters.get(key)?.setValue(checked, { emitEvent: false });
            });

          }
        }
        else if (this.campagne.isPurged) {
          Object.keys(this.filters.controls).filter(key => key != 'allFilters' && key != 'ImportCiblage' && key != 'ChampFusion' && key != 'PaveAdresse' && key != 'FichierCreation').forEach((key) => {
            this.filters.get(key)?.setValue(checked, { emitEvent: false });
          });
        }
        else if (!this.campagne.Alliage) {
          Object.keys(this.filters.controls).filter(key => key != 'allFilters').forEach((key) => {
            this.filters.get(key)?.setValue(checked, { emitEvent: false });
          });
        }
        else {
          Object.keys(this.filters.controls).filter(key => key != 'allFilters' && key != 'Adresse').forEach((key) => {
            this.filters.get(key)?.setValue(checked, { emitEvent: false });
          });
        }
      }
    });
    this.filters.valueChanges.subscribe((checked: boolean) => {
      // if valid
      this.canDuplicate = false;
      Object.keys(checked).forEach((key) => {
        this.canDuplicate = this.canDuplicate || checked[key];
      });

      let allFilters = true;
      if (!this.canDuplicateProduit) {
        if (!this.campagne.Alliage) {
          Object.keys(this.filters.controls).filter(key => key != 'allFilters' && key != 'Support' && key != 'Enveloppe').forEach((key) => {
            allFilters = allFilters && this.filters.get(key).value;
          });
        }
        else {
          Object.keys(this.filters.controls).filter(key => key != 'allFilters' && key != 'Adresse' && key != 'Support' && key != 'Enveloppe').forEach((key) => {
            allFilters = allFilters && this.filters.get(key).value;
          });
        }
      }
      else if (this.campagne.isPurged) {
        Object.keys(this.filters.controls).filter(key => key != 'allFilters' && key != 'ImportCiblage' && key != 'ChampFusion' && key != 'PaveAdresse' && key != 'FichierCreation').forEach((key) => {
          allFilters = allFilters && this.filters.get(key).value;
        });
      }
      else if (!this.campagne.Alliage) {
        Object.keys(this.filters.controls).filter(key => key != 'allFilters').forEach((key) => {
          allFilters = allFilters && this.filters.get(key).value;
        });
      }
      else {
        Object.keys(this.filters.controls).filter(key => key != 'allFilters' && key != 'Adresse').forEach((key) => {
          allFilters = allFilters && this.filters.get(key).value;
        });
      }
      this.filters.get('allFilters').setValue(allFilters, { emitEvent: false });
    });

  }

  refreshFichiersCreation() {
    this._GlobalDataService.CampagneExt.FichiersCreation.forEach(f => {
      this.downloadFile(f.NomFichier);
    })
  }


  downloadFile(fileName: string) {
    // console.log("download file " + fileName);
    return this._FichierCreaService.download(fileName, this._GlobalDataService.Campagne.IdCampagneCopie) // pour éviter la latence réseau
      .subscribe(response => {
        var blob = new Blob([response], { type: response.type });
        //Create a link element, hide it, direct 
        //it towards the blob, and then 'click' it programatically

        //Create a DOMString representing the blob 
        //and point the link element towards it


        if (blob.type.startsWith('image')) {
          let objectURL = URL.createObjectURL(blob);
          this._GlobalDataService.CampagneExt.FichiersCreationsURL[fileName] = this.domSanitizer.bypassSecurityTrustUrl(objectURL);
          this._GlobalDataService.CampagneExt.FichiersCreationsType[fileName] = 'image';

        }
        else {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            this._GlobalDataService.CampagneExt.FichiersCreationsURL[fileName] = new Uint8Array(fileReader.result as ArrayBuffer);
            this._GlobalDataService.CampagneExt.FichiersCreationsType[fileName] = blob.type;
          };
          fileReader.readAsArrayBuffer(blob);
        }
      });
  }

  CheckboxChange(checked: boolean) {
    Object.keys(this.filters.controls).filter(key => key != 'allFilters').forEach((key) => {
      this.filters.get(key)?.setValue(checked, { emitEvent: false });
      console.debug(key + " : " + this.filters.get(key)?.value);
    });
  }

  onCheckboxChange(e: any) {
    // e is a boolean, true if checked, otherwise false
    console.debug("onCheckboxChange :");
    console.debug("e.checked :" + e.checked);
    this.CheckboxChange(e.checked);
  }

  onCheckSupport() {
    if (this.canDuplicateProduit) {
      this.filters.get('Support').setValue(!this.filters.get('Support').value);
      if (this.campagne.CodeEnveloppe)
        this.filters.get('Enveloppe').setValue(this.filters.get('Support').value);
    }
  }

  onCheckEnveloppe() {
    if (this.canDuplicateProduit) {
      this.filters.get('Enveloppe').setValue(!this.filters.get('Enveloppe').value);
      this.filters.get('Support').setValue(this.filters.get('Enveloppe').value);
    }
  }

  duplicateCampagne(campagne: Campagne) {
    let data = {
      message: "Etes-vous sûr de vouloir copier cette campagne?",
      titre: 'Confirmation',
      BouttonConfirmation: true,
    }
    const confirmdialogRef = this._GlobalDataService.confirm(data);

    confirmdialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);

      if (result === true) {
        let request = {
          request: {
            NomCampagne: this.data.NomCampagne,
            Description: this.data.Description,
            BonDeCommande: this.data.BonDeCommande,
            CodeVitesse: this.filters.get('CodeVitesse')?.value,
            FrequenceEnvoi: this.filters.get('FrequenceEnvoi')?.value,
            Alliage: this.filters.get('Alliage')?.value,
            Support: this.filters.get('Support')?.value,
            Adresse: this.filters.get('Adresse')?.value,
            FichierCreation: this.filters.get('FichierCreation')?.value,
            ChampFusion: this.filters.get('ChampFusion')?.value,
            PaveAdresse: this.filters.get('PaveAdresse')?.value,
            Enveloppe: this.filters.get('Enveloppe')?.value,
            ImportCiblage: this.filters.get('ImportCiblage')?.value,
          }
        }

        if (this._GlobalDataService.isSalesforceContext()) {
          request.request['InteractionId'] = this._GlobalDataService.getinteractionIdSF();
          request.request['ExtensionId'] = campagne.ExtensionId;          
        }

        this._CampagneService.advanceDuplicate(campagne.IdCampagne, request)
          .subscribe(
            response => this.SuccessDuplicate(campagne, response),
            error => this.errorDuplicate(error)
          );
      } else {
        return;
      }
    });
  }

  SuccessDuplicate(campagne: Campagne, response: any) {
    if (response.IdCampagneDuplicate != '') {
      this._GlobalDataService.alert("La campagne '" + this.data.NomCampagne + "' a été initialisée avec succès");

      console.log("campagne de copie :", campagne);

      this._GlobalDataService.Campagne.IdCampagneCopie = campagne.IdCampagne;

      if (this._GlobalDataService.isSalesforceContext()) {
        this._GlobalDataService.Campagne.ExtensionId  = campagne.ExtensionId;
      }

      if (this.filters.get('Alliage').value) {
        this._GlobalDataService.Campagne.Alliage = true;
        this._GlobalDataService.Campagne.OptionRetour = 'Alliage';

        this._GlobalDataService.Campagne.IdAdresse = '0';
        this._GlobalDataService.CampagneExt.Adresse = new AdresseRetour(this._GlobalDataService.Annonceur);;
        this._GlobalDataService.CampagneExt.selectedAdresses = [];
        this._GlobalDataService.CampagneExt.selectedAdresses.push(campagne.Adresse);

        this._campagneAdressesService.form.get('OptionRetour').patchValue(this._GlobalDataService.Campagne.OptionRetour, { emitEvent: false });
        this._campagneAdressesService.form.get('Alliage').patchValue(this._GlobalDataService.Campagne.Alliage, { emitEvent: false });

        this._campagneAdressesService.form.get('AdresseRetour').patchValue('0', { emitEvent: false });
        this._campagneAdressesService.form.get('NomAdresse').patchValue('', { emitEvent: false });

        campagne.Adresse = new AdresseRetour(this._GlobalDataService.Annonceur);
        campagne.Adresse = this._GlobalDataService.availableAdresses.filter((a: AdresseRetour) => a.IdAdresse === campagne.IdAdresse)[0];
      }

      if (this.filters.get('Adresse').value) {

        campagne.Adresse = new AdresseRetour(this._GlobalDataService.Annonceur);
        campagne.Adresse = this._GlobalDataService.availableAdresses.filter((a: AdresseRetour) => a.IdAdresse === campagne.IdAdresse)[0];

        this._campagneAdressesService.form.get('AdresseRetour').patchValue(campagne.IdAdresse > 0 ? true : false, { emitEvent: false });
        this._campagneAdressesService.form.get('NomAdresse').patchValue(campagne.Adresse.NomAdresse, { emitEvent: false });

        this._GlobalDataService.Campagne.IdAdresse = campagne.IdAdresse;
        this._GlobalDataService.CampagneExt.Adresse = Object.assign({}, campagne.Adresse);
        this._GlobalDataService.CampagneExt.selectedAdresses = [];
        this._GlobalDataService.CampagneExt.selectedAdresses.push(campagne.Adresse);


        campagne.OptionRetour = '';
        if (!this._GlobalDataService.Annonceur.NumeroContratAlliage || (campagne.IdAdresse != '0' && campagne.IdAdresse)) {
          campagne.OptionRetour = 'AdresseRetour';
        }
        else if (campagne.Alliage) campagne.OptionRetour = 'Alliage';

        this._GlobalDataService.Campagne.Alliage = campagne.Alliage;
        this._GlobalDataService.Campagne.OptionRetour = campagne.OptionRetour;
        // this._campagneAdressesService.form.reset();
        this._campagneAdressesService.form.get('OptionRetour').patchValue(campagne.OptionRetour, { emitEvent: false });
        this._campagneAdressesService.form.get('Alliage').patchValue(campagne.Alliage, { emitEvent: false });
      }


      if (this.filters.get('Support')?.value) {
        this._campagneTypologieService.refreshTypologie(campagne);
        this._GlobalDataService.CampagneExt.Produit = {};
        this._GlobalDataService.CampagneExt.Produit = this._GlobalDataService.getCampagneProduit(campagne);
        this._GlobalDataService.CampagneExt.VitessesProduit = this._GlobalDataService.getVitessesProduit();
      }

      if (this.filters.get('FichierCreation').value) {
        this._GlobalDataService.CampagneExt.FichiersCreationsURL = {};
        this._GlobalDataService.CampagneExt.FichiersCreationsType = {};
        this._GlobalDataService.CampagneExt.FichiersCreation = this.FichiersCreation;
        this._GlobalDataService.CampagneExt.NbFichiersCreation = this._GlobalDataService.getNbFichiersCreation();
        this._GlobalDataService.CampagneExt.NbFichiersPersonnalisation = this._GlobalDataService.getNbFichiersPersonnalisation();
        this._GlobalDataService.CampagneExt.NbFichiersLogo = this._GlobalDataService.getNbFichiersLogo();
        // this.refreshFichiersCreation();
      }

      if (this.filters.get('CodeVitesse').value) {
        this._CampagnePlanificationService.refreshPlanificationVitesse(campagne);
      }

      if (this.filters.get('FrequenceEnvoi').value) {
        this._CampagnePlanificationService.refreshPlanificationFrequenceEnvoi(campagne);
      }

      this._GlobalDataService.checkStepCompletion();

      this._GlobalDataService.Campagne.NomCampagneParente = campagne.NomCampagne;

      this._GlobalDataService.CampagneExt.ChampsFusion = new Array<ChampsFusion>();

      if (this.filters.get('ChampFusion')?.value) {
        this._GlobalDataService.CampagneExt.ChampsFusion = this._GlobalDataService.CampagneExt.ChampsFusion.concat(this.ChampsPersonnalisation);

        if (this._GlobalDataService.isEloquaContext()) {
          this.personalisationService.initialise();
        }
        else if (this._GlobalDataService.isSalesforceContext()) {
          this.personalisationSfService.initialise();
        }
        else if (this._GlobalDataService.isStandaloneContext()) {
          // MàJ champs fusion disponibles après duplication
          this.personalisationService.updateChampFusion();

          this.personalisationService.initialise();
        }
      }

      if (this.filters.get('PaveAdresse')?.value) {
        this._GlobalDataService.CampagneExt.ChampsFusion = this._GlobalDataService.CampagneExt.ChampsFusion.concat(this.ChampsAdresse);
        if (this._GlobalDataService.isEloquaContext()) {
          this.adressesDestinatairesService.initialise();
        }
        else if (this._GlobalDataService.isSalesforceContext()) {
          this.adressesDestinatairesSfService.initialise();
        }

      }

      if (this.filters.get('ImportCiblage')?.value) {
        this._GlobalDataService.Campagne.importOption = campagne['ImportOption'];        
        this._GlobalDataService.Campagne.importFile = campagne['ImportFile'];
        this.personalisationService.updateChampFusion();
      }

      this._GlobalDataService.Campagne.isDirty = true;

      this._GlobalDataService.campagneChanged();

      this.closeDialog();

    }
  }


  InitProduits(campagne: Campagne) {
    // Typologie (Type de support)

    this._campagneTypologieService.dataSupportTypologies = this._GlobalDataService.distinct(this._GlobalDataService.produits, 'CodeTypologie');

    // Support
    this._campagneTypologieService.dataSupports = this._GlobalDataService.produits
      .filter((element: {
        CodeTypologie: string | undefined;
      }) => element.CodeTypologie == campagne.CodeTypologie);
    this._campagneTypologieService.dataSupports = this._GlobalDataService.distinct(this._campagneTypologieService.dataSupports, 'CodeSupport');

    // Dimension
    this._campagneTypologieService.dataDimensionsSupports = this._GlobalDataService.produits
      .filter((element: { CodeSupport: string | undefined; CodeTypologie: string | undefined; }) => element.CodeSupport == campagne.CodeSupport
        && element.CodeTypologie == campagne.CodeTypologie)
    this._campagneTypologieService.dataDimensionsSupports = this._GlobalDataService.distinct(this._campagneTypologieService.dataDimensionsSupports, 'CodeDimensionsSupport');

    // Grammage
    this._campagneTypologieService.dataGrammagesSupports = this._GlobalDataService.produits
      .filter((element: { CodeDimensionsSupport: string | undefined; CodeSupport: string | undefined; CodeTypologie: any; }) => element.CodeDimensionsSupport == campagne.CodeDimensionsSupport
        && element.CodeSupport == campagne.CodeSupport
        && element.CodeTypologie == campagne.CodeTypologie)
    this._campagneTypologieService.dataGrammagesSupports = this._GlobalDataService.distinct(this._campagneTypologieService.dataGrammagesSupports, 'CodeGrammageSupport');

    // Finition
    this._campagneTypologieService.dataFinitionsSupports = this._GlobalDataService.produits
      .filter((element: { CodeGrammageSupport: any; CodeDimensionsSupport: any; CodeSupport: any; CodeTypologie: any; }) => element.CodeGrammageSupport == campagne.CodeGrammageSupport
        && element.CodeDimensionsSupport == this._GlobalDataService.Campagne.CodeDimensionsSupport
        && element.CodeSupport == campagne.CodeSupport
        && element.CodeTypologie == campagne.CodeTypologie)
    this._campagneTypologieService.dataFinitionsSupports = this._GlobalDataService.distinct(this._campagneTypologieService.dataFinitionsSupports, 'CodeFinitionSupport');

    // Encre
    this._campagneTypologieService.dataEncresSupports = this._GlobalDataService.produits
      .filter((element: { CodeFinitionSupport: any; CodeGrammageSupport: any; CodeDimensionsSupport: any; CodeSupport: any; CodeTypologie: any; }) => element.CodeFinitionSupport == campagne.CodeFinitionSupport
        && element.CodeGrammageSupport == campagne.CodeGrammageSupport
        && element.CodeDimensionsSupport == campagne.CodeDimensionsSupport
        && element.CodeSupport == campagne.CodeSupport
        && element.CodeTypologie == campagne.CodeTypologie)
    this._campagneTypologieService.dataEncresSupports = this._GlobalDataService.distinct(this._campagneTypologieService.dataEncresSupports, 'CodeFinitionSupport');

    this._campagneTypologieService.dataEnveloppes = this._GlobalDataService.produits
      .filter((element: { CodeTypologie: string | undefined; CodeSupport: string | undefined; CodeDimensionsSupport: string | undefined; CodeGrammageSupport: string | undefined; CodeFinitionSupport: string | undefined; CodeEncreSupport: string | undefined; }) => element.CodeTypologie == campagne.CodeTypologie
        && element.CodeSupport == campagne.CodeSupport
        && element.CodeDimensionsSupport == campagne.CodeDimensionsSupport
        && element.CodeGrammageSupport == campagne.CodeGrammageSupport
        && element.CodeFinitionSupport == campagne.CodeFinitionSupport
        && element.CodeEncreSupport == campagne.CodeEncreSupport);
    this._campagneTypologieService.dataEnveloppes = this._GlobalDataService.distinct(this._campagneTypologieService.dataEnveloppes, 'CodeEnveloppe');

  }


  errorDuplicate(error: any) {
    this._GlobalDataService.alert("Une erreur s'est produite lors de la duplication");
  }


  ErrorCurrentCampagne(error: any) {
    console.log('CreationCampagneComponent : ErrorCurrentCampagne', error)
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getFichiersCreation(event: any) {
    console.log(event.FichiersCreation);
    this.FichiersCreation = event.FichiersCreation;
  }

  getChampsPersonnalisation(event: any) {
    console.log(event.ChampsPersonnalisation);
    this.ChampsPersonnalisation = event.ChampsPersonnalisation;
  }

  getChampsAdresse(event: any) {
    console.log(event.ChampsAdresse);
    this.ChampsAdresse = event.ChampsAdresse;
  }

  isEmpty(campagne: Campagne) {
    // campagne['ImportFile'] peut avoir "null" type string comme valeur (?)
    return campagne['ImportFile'] === "" || campagne['ImportFile'] === "null";
  }

  public showFusionFields(): boolean {
    return !this._GlobalDataService.isStandaloneContext() || this.campagne['ImportOption'] != 'sftp';
  }
  
  isContactFilePresent(campagne: Campagne): void {
    this._FichierPersoStandalone.readContact(campagne.IdCampagne).subscribe({
      next: (_) => {
        this.contactFilePresent = true;
      },
      error: (_) => {
        this.contactFilePresent = false;
      }
    })
  }

  isCreationFilePresent(campagne: Campagne): void {
    this._FichierCreaService.list(campagne.IdCampagne).subscribe({
      next: (response: {status, message, fichierCreation}) => {
        this.creationFilePresent = typeof response.fichierCreation === 'object' && response.fichierCreation.length > 0;
      },
      error: (_) => {
        this.creationFilePresent = false;
      }
    })
  }

  isChampsFusionPresent(campagne: Campagne): void {
    this._ChampsFusionService.list(campagne.IdCampagne).subscribe({
      next: (response: {status: string, message: string, ChampsFusion: Array<any>}) => {
        if (response.ChampsFusion.length > 0) {
          let resp = response.ChampsFusion.map(champ => champ.IsPaveAdresse);
          this.champsAddressPresent = resp.indexOf(1) > -1;
          this.champsPersoPresent = resp.indexOf(0) > -1;
        }
      },
      error: (_) => {
        this.champsPersoPresent = false;
        this.champsAddressPresent = false;
      }
    })
  }
}


