<mat-card [formGroup]="_campagneAdressesService.form">
    <mat-card-content>
        <mat-grid-list cols="2" rowHeight="3.5rem" [gutterSize]="'7rem'">
            <mat-radio-group formControlName="OptionRetour" aria-label="Choisir une vitesse">
                <mat-grid-tile colspan="1" rowspan="0.5"
                    *ngFor="let option of OptionsRetour"                    
                    [ngClass]="{
                      'gtmt-checkbox-container': true,
                      'gtmt-checkbox-container-disabled': option.code == 'Alliage' && !_GlobalDataService.Annonceur.NumeroContratAlliage
                    }">
                  <label
                    class="full-clickable"
                    [ngClass]="{
                      'gtmt-radio-disabled': option.code == 'Alliage' && !_GlobalDataService.Annonceur.NumeroContratAlliage
                    }"
                    (click)="_GlobalDataService.Annonceur.NumeroContratAlliage && _campagneAdressesService.form.get('OptionRetour').setValue(option.code)">
                    
                    <span>
                      {{ option.title }}
                    </span>
                    <mat-radio-button [value]="option.code"
                      [disabled]="option.code == 'Alliage' && !_GlobalDataService.Annonceur.NumeroContratAlliage">
                    </mat-radio-button>
                  </label>
                </mat-grid-tile>                
              </mat-radio-group>
              
        </mat-grid-list>
        
        <p *ngIf="!_GlobalDataService.Annonceur.NumeroContratAlliage">
            Vous ne disposez pas de l'option alliage, si vous souhaitez l'activer merci de contacter votre commerciale            
        </p>
    </mat-card-content>

</mat-card>

<mat-spinner-overlay [overlay]=true *ngIf="showSpinner">
</mat-spinner-overlay>