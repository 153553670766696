import { Component, OnInit, Input } from '@angular/core';
import { AdressesDestinatairesService, GlobalDataService, PersonalisationService } from '@core/services';

@Component({
  selector: 'app-campagne-save',
  templateUrl: './campagne-save.component.html',
  styleUrls: ['./campagne-save.component.scss'],
})
export class CampagneSaveComponent implements OnInit {
  waitingForServer = false;
  @Input() campaignName: string;

  constructor(
    private _GlobalDataService: GlobalDataService,
    private _PersonalisationService: PersonalisationService,
    private _AdressesDestinatairesService: AdressesDestinatairesService
  ) {}

  ngOnInit(): void {}

  public save(): void {
    // Check campaign name is not empty
    const nomCampagne = this._GlobalDataService.Campagne.NomCampagne;
    if (nomCampagne === undefined 
      || nomCampagne === null 
      || this._GlobalDataService.Campagne.NomCampagne === '') {
        this._GlobalDataService.alert('Enregistrement non autorisé.<br>Le nom de la campagne est obligatoire et doit-être unique. Merci de renseigner un nom de campagne.');
        return;
    }

    // Save with API
    this.waitingForServer = true;
    let saveFusion = this._GlobalDataService.stepIndex === 3;
    
    if (saveFusion) {
      this._PersonalisationService.setChampFusionType();
      this._GlobalDataService.CampagneExt.ChampsFusion = this._PersonalisationService.selectedChampsFusion.concat(this._AdressesDestinatairesService.selectedChampsFusion);
    }

    this._GlobalDataService.saveAll(this._GlobalDataService.Campagne, saveFusion)
    .subscribe({
      complete: () => {
        if (this._GlobalDataService.Campagne.NomCampagne === '') {}
        this._GlobalDataService.alert('Votre campagne a bien été enregistrée.');
        this.waitingForServer = false;
      },
      error: (e) => {
        this._GlobalDataService.openSnackBarInfo(e.error.message),
        this.waitingForServer = false;
      }
    });
  }
}
