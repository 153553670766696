<mat-accordion multi="true">
  <mat-expansion-panel expanded="true" *ngIf="_GlobalDataService.Campagne.importOption !='sftp'">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Pavé adresse destinataires
      </mat-panel-title>
    </mat-expansion-panel-header>

    <app-campagne-adresses-destinataires *ngIf="_GlobalDataService.isEloquaContext() || (_GlobalDataService.isStandaloneContext() && _GlobalDataService.Campagne.importOption ==='upload')">
    </app-campagne-adresses-destinataires>
    <app-campagne-adresses-destinataires-sf *ngIf="_GlobalDataService.isSalesforceContext()">
    </app-campagne-adresses-destinataires-sf>
  </mat-expansion-panel>

  <mat-expansion-panel expanded="true" *ngIf="PaveAdresseSelect() || (_GlobalDataService.isStandaloneContext() && _GlobalDataService.Campagne.importOption !='upload')">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Options de retour
      </mat-panel-title>
    </mat-expansion-panel-header>

    <app-campagne-adresses-options-retour></app-campagne-adresses-options-retour>

    <app-campagne-adresse-retour *ngIf="_GlobalDataService.Campagne.OptionRetour =='AdresseRetour'"></app-campagne-adresse-retour>

    <app-campagne-alliage *ngIf="_GlobalDataService.Campagne.OptionRetour =='Alliage'"></app-campagne-alliage>

  </mat-expansion-panel>

  <mat-expansion-panel expanded="true" *ngIf="_GlobalDataService.Campagne.Alliage">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Gestion du logo
      </mat-panel-title>
    </mat-expansion-panel-header>

    <app-campagne-gestion-logo></app-campagne-gestion-logo>
  </mat-expansion-panel>

</mat-accordion>

<!-- Stepper navigation  -->
<mat-grid-list class="gtmt-stepper-nav" cols="2" rowHeight="10rem" [gutterSize]="'5rem'">
  <mat-grid-tile class="gtmt-jc-end" colspan="1" rowspan="1">
    <button mat-raised-button color="white" matStepperPrevious>
      Etape précédente
    </button>
  </mat-grid-tile>
  <mat-grid-tile class="gtmt-jc-start" colspan="1" rowspan="1">
    <button mat-raised-button color="yellow" (click)="submit()">
      Etape suivante
    </button>
  </mat-grid-tile>
</mat-grid-list>