import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { navPaths } from '@core/models/support.model';
import { CampagneTypologieService, GlobalDataService } from '@core/services';

@Component({
  selector: 'app-enveloppe-card',
  templateUrl: './enveloppe-card.component.html',
  styleUrls: ['./enveloppe-card.component.scss']
})
export class EnveloppeCardComponent implements OnInit {

  navigationOption = {};

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    public _GlobalDataService: GlobalDataService,
    public _CampagneTypologieService: CampagneTypologieService,
  ) {
    this.navigationOption = { typologie: 'enveloppe' };
   }

  ngOnInit(): void {

    // Enveloppes
    this._CampagneTypologieService.dataEnveloppes = this._CampagneTypologieService.filterProductBySelection(
      this._GlobalDataService.Campagne,
      this._GlobalDataService.produits
    );
    this._CampagneTypologieService.dataEnveloppes = this._GlobalDataService.distinct(this._CampagneTypologieService.dataEnveloppes, 'CodeEnveloppe');

  }


  ngAfterViewInit() {
    this._CampagneTypologieService.EnveloppeFormGroup.get('CodeEnveloppe')
        .valueChanges.subscribe((value: string) => {
      // if valid
      if (this._CampagneTypologieService.EnveloppeFormGroup.get('CodeEnveloppe').valid) {

        console.log('CodeEnveloppe value changed')
        console.log(this._CampagneTypologieService.EnveloppeFormGroup.get('CodeEnveloppe').value);
       
        setTimeout(() => {
          console.log(this._CampagneTypologieService.EnveloppeFormGroup.value)
        })

        // update model
        this._GlobalDataService.Campagne.CodeEnveloppe = value;
      }
    });
  }



  Valider() {
    if (!this._GlobalDataService.Campagne.CodeEnveloppe) {
      this._GlobalDataService.alert("Veuillez sélectionner une Enveloppe");
      return;
    }

    this.enveloppeChanged();
    this._CampagneTypologieService.navigate(navPaths.dimension, this.activatedRoute.parent as ActivatedRoute, this.navigationOption);
  }

  enveloppeChanged(): void {

    this._GlobalDataService.Campagne.isDirty = true;

    // Dimension
    this._CampagneTypologieService.dataDimensionsEnveloppes = this._GlobalDataService.produits
      .filter((element: { CodeEnveloppe: any; CodeTypologie: any; }) => element.CodeEnveloppe == this._GlobalDataService.Campagne.CodeEnveloppe
        && element.CodeTypologie == this._GlobalDataService.Campagne.CodeTypologie)
    this._CampagneTypologieService.dataDimensionsEnveloppes = this._GlobalDataService.distinct(this._CampagneTypologieService.dataDimensionsEnveloppes, 'CodeDimensionsEnveloppe');

    if (this._CampagneTypologieService.dataDimensionsEnveloppes.length == 1) {
      this._GlobalDataService.Campagne.CodeDimensionsEnveloppe = this._CampagneTypologieService.dataDimensionsEnveloppes[0].CodeEnveloppe;
    }
    else {
      this._GlobalDataService.Campagne.CodeDimensionsEnveloppe = "";
    } 

    this._CampagneTypologieService.EnveloppeFormGroup.get('CodeDimensionsEnveloppe').patchValue(this._GlobalDataService.Campagne.CodeDimensionsEnveloppe, { emitEvent: false });

    // Grammage
    this._CampagneTypologieService.dataGrammagesEnveloppes = this._GlobalDataService.produits
      .filter((element: { CodeDimensionsEnveloppe: string | undefined; CodeEnveloppe: string | undefined; CodeTypologie: any; }) => element.CodeDimensionsEnveloppe == this._GlobalDataService.Campagne.CodeDimensionsEnveloppe
        && element.CodeEnveloppe == this._GlobalDataService.Campagne.CodeEnveloppe
        && element.CodeTypologie == this._GlobalDataService.Campagne.CodeTypologie)
    this._CampagneTypologieService.dataGrammagesEnveloppes = this._GlobalDataService.distinct(this._CampagneTypologieService.dataGrammagesEnveloppes, 'CodeGrammageEnveloppe');

    if (this._CampagneTypologieService.dataGrammagesEnveloppes.length == 1) {
      this._GlobalDataService.Campagne.CodeGrammageEnveloppe = this._CampagneTypologieService.dataGrammagesEnveloppes[0].CodeGrammageEnveloppe;
    }
    else {
      this._GlobalDataService.Campagne.CodeGrammageEnveloppe = "";
    } 

    this._CampagneTypologieService.EnveloppeFormGroup.get('CodeGrammageEnveloppe').patchValue(this._GlobalDataService.Campagne.CodeGrammageEnveloppe, { emitEvent: false });


    // Finition
    this._CampagneTypologieService.dataFinitionsEnveloppes = this._GlobalDataService.produits
      .filter((element: { CodeGrammageEnveloppe: any; CodeDimensionsEnveloppe: any; CodeEnveloppe: any; CodeTypologie: any; }) => element.CodeGrammageEnveloppe == this._GlobalDataService.Campagne.CodeGrammageEnveloppe
        && element.CodeDimensionsEnveloppe == this._GlobalDataService.Campagne.CodeDimensionsEnveloppe
        && element.CodeEnveloppe == this._GlobalDataService.Campagne.CodeEnveloppe
        && element.CodeTypologie == this._GlobalDataService.Campagne.CodeTypologie)
    this._CampagneTypologieService.dataFinitionsEnveloppes = this._GlobalDataService.distinct(this._CampagneTypologieService.dataFinitionsEnveloppes, 'CodeFinitionEnveloppe');

    if (this._CampagneTypologieService.dataFinitionsEnveloppes.length == 1) {
      this._GlobalDataService.Campagne.CodeFinitionEnveloppe = this._CampagneTypologieService.dataFinitionsEnveloppes[0].CodeFinitionEnveloppe;
    }
    else {
      this._GlobalDataService.Campagne.CodeFinitionEnveloppe = "";
    }

    this._CampagneTypologieService.EnveloppeFormGroup.get('CodeFinitionEnveloppe').patchValue(this._GlobalDataService.Campagne.CodeFinitionEnveloppe, { emitEvent: false });

    // Encre
    this._CampagneTypologieService.dataEncresEnveloppes = this._GlobalDataService.produits
      .filter((element: { CodeFinitionEnveloppe: any; CodeGrammageEnveloppe: any; CodeDimensionsEnveloppe: any; CodeEnveloppe: any; CodeTypologie: any; }) => element.CodeFinitionEnveloppe == this._GlobalDataService.Campagne.CodeFinitionEnveloppe
        && element.CodeGrammageEnveloppe == this._GlobalDataService.Campagne.CodeGrammageEnveloppe
        && element.CodeDimensionsEnveloppe == this._GlobalDataService.Campagne.CodeDimensionsEnveloppe
        && element.CodeEnveloppe == this._GlobalDataService.Campagne.CodeEnveloppe
        && element.CodeTypologie == this._GlobalDataService.Campagne.CodeTypologie)
    this._CampagneTypologieService.dataEncresEnveloppes = this._GlobalDataService.distinct(this._CampagneTypologieService.dataEncresEnveloppes, 'CodeFinitionEnveloppe');

    if (this._CampagneTypologieService.dataEncresEnveloppes.length == 1) {
      this._GlobalDataService.Campagne.CodeEncreEnveloppe = this._CampagneTypologieService.dataEncresEnveloppes[0].CodeEncreEnveloppe;
    }
    else {
      this._GlobalDataService.Campagne.CodeEncreEnveloppe = "";
    }

    this._CampagneTypologieService.EnveloppeFormGroup.get('CodeEncreEnveloppe').patchValue(this._GlobalDataService.Campagne.CodeEncreEnveloppe, { emitEvent: false });


  }
}
