import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import {
  ActivatedRoute,
  NavigationBehaviorOptions,
  NavigationEnd,
  Router,
} from '@angular/router';
import { Campagne } from '@core/models/campagne.model';
import { navPaths } from '@core/models/support.model';
import {
  AdressesDestinatairesService,
  AnnonceurService,
  CampagneTypologieService,
  GlobalDataService,
  PersonalisationService,
} from '@core/services';
import { CampagneService } from '@core/services/campagne.service';
import { EstimationService } from '@core/services/estimation.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-campagne-details',
  templateUrl: './campagne-details.component.html',
  styleUrls: ['./campagne-details.component.scss'],
})
export class CampagneDetailsComponent implements OnInit {
  titre = 'Conception de la campagne courrier';
  Campagne!: Campagne;
  isLoaded = false;
  isLinear: boolean = true;
  isEditable = true;
  MAX_STEP = 7;
  showButtonRetour = false;

  selectedIndex: number = 0;

  step: number = 0;
  page: number = 0;

  @ViewChild(MatStepper) stepper!: MatStepper;

  navigationSubscription: Subscription;
  activeRouteSubscription: Subscription;

  navigationOption: NavigationBehaviorOptions = { state: {} };

  campaignName: string;

  constructor(
    public _GlobalDataService: GlobalDataService,
    private router: Router,
    public _AnnonceurService: AnnonceurService,
    private activeRoute: ActivatedRoute,
    private _CampagneService: CampagneService,
    private readonly activatedRoute: ActivatedRoute,
    private _CampagneTypologieService: CampagneTypologieService,
    private _EstimationService: EstimationService,
    private elementRef: ElementRef,
    private _PersonalisationService: PersonalisationService,
    private _AdressesDestinatairesService: AdressesDestinatairesService
  ) {
    this.activeRoute.queryParams.subscribe((params) => {
      this._GlobalDataService.objectParams = params; // Print the parameter to the console.
      console.debug(this._GlobalDataService.objectParams);
      console.debug('CONTEXT ', this._GlobalDataService.CONTEXT);
    });

    // this.activeRouteSubscription =  this.activeRoute.paramMap.subscribe((params: ParamMap) => {
    //   this.IdCampagne = <string>params.get('IdCampagne');
    // });

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        console.log('NavigationEnd');
        // this.changeStepperStyle();
      }
    });
  }

  ngOnInit() {
    //init pas de change!
    //identification campagne
    this._GlobalDataService.referenceDocHelp = '#new_campaign_step1';
    this._GlobalDataService.stepper = this.stepper;
    this.isLoaded = true;
  }

  ngAfterViewInit() {
    if (this.stepper) {
      this.stepper._getIndicatorType = () => 'number';
      this.changeStepperStyle();
    }
  }

  navigatetoErrorPage(error: any) {
    //une erreur est survenue => redirection page erreur
    console.log('CampagneDetailsComponent : ErrorCurrentCampagne', error);
    this.router.navigate(['/erreur'], {
      queryParams: this._GlobalDataService.objectParams,
      queryParamsHandling: 'merge',
    });
    this.isLoaded = true;
  }

  showTooltip(event: any) {
    console.log(event);
  }

  SuccessCurrentCampagne(campagne) {
    if (typeof campagne === 'object') {
      const NomCampagne = this._GlobalDataService.Campagne.NomCampagne;
      const Description = this._GlobalDataService.Campagne.Description;
      const BonDeCommande = this._GlobalDataService.Campagne.BonDeCommande;

      //affecter la campagne reçu

      this._GlobalDataService.Campagne = campagne;

      this._GlobalDataService.Campagne.NomCampagne = NomCampagne;
      this._GlobalDataService.Campagne.Description = Description;
      this._GlobalDataService.Campagne.BonDeCommande = BonDeCommande;

      if (
        typeof this._GlobalDataService.Campagne.EmailNotification == 'undefined'
      )
        this._GlobalDataService.Campagne.EmailNotification = '';

      console.log('current Campagne : ', this._GlobalDataService.Campagne);

      console.log(
        'Id Campagne : ',
        this._GlobalDataService.Campagne.IdCampagne
      );

      console.log('campagne.StatutCampagne', campagne.StatutCampagne);

      console.log('campagne.CodeStatut', campagne.CodeStatut);
    }
  }

  changeStepperStyle() {
    this.elementRef.nativeElement
      .querySelectorAll('.mat-step-icon')
      .forEach((item: { classList: { remove: (arg0: string) => void } }) => {
        item.classList.remove('step-completed');
      });

    this._GlobalDataService.CampagneExt.completedSteps.forEach((stepIndex) => {
      // let elem = this.elementRef.nativeElement.querySelector(`mat-step-header[aria-posinset="${stepIndex}"] .mat-step-icon`);
      let elem = this.elementRef.nativeElement.querySelector(
        `mat-step-header[aria-posinset="${stepIndex}"]`
      );

      if (!elem) return;
      elem.classList.add('step-completed');
      elem.nextSibling.nextSibling.classList.add('step-completed');
    });
  }

  selectionChange(event: any) {
    console.debug(event);

    // ATA@20230403 - Security enhancements
    if (
      this._GlobalDataService.isTokenExpired(
        this._GlobalDataService.getJwtoken()
      )
    ) {
      this._GlobalDataService.refreshToken();
    }

    if (this._GlobalDataService.Campagne.NomCampagne === '') {
      this._GlobalDataService.alert(
        'Le nom de la campagne est obligatoire et doit-être unique. Merci de renseigner un nouveau nom de campagne'
      );
      return;
    } else {
      if (
        !this._GlobalDataService.Campagne.IdCampagne &&
        this._GlobalDataService.isStandaloneContext()
      ) {
        this._CampagneService.getCurrentCampagne().subscribe(
          (response) => this.SuccessCurrentCampagne(response.campagne),
          (error) => console.log(error)
        );
      }
    }

    this.selectedIndex = event.selectedIndex;

    //call API
    if (this._GlobalDataService.Campagne.isDirty) {
      this._CampagneService.put(this._GlobalDataService.Campagne)
        .subscribe(
          () => {
            this._GlobalDataService.checkStepCompletion();
            this.changeStepperStyle();
            this._GlobalDataService.Campagne.isDirty = false;
          },
          error => {
            console.log(error.error.message);
            this._GlobalDataService.openSnackBarInfo(error.error.message);
          }
        );
    }
    
    // Personalisation Champs Fusion automatic save in stepper
    if (this._GlobalDataService.CONTEXT === 'ELOQUA' && this._GlobalDataService.stepIndex === 3) {
      this._PersonalisationService.setChampFusionType();
      this._GlobalDataService.CampagneExt.ChampsFusion = this._PersonalisationService.selectedChampsFusion.concat(this._AdressesDestinatairesService.selectedChampsFusion);
      this._GlobalDataService
        .saveAll(this._GlobalDataService.Campagne, true)
        .subscribe({
          complete: () => {},
          error: (e) => {
            console.log(e.error.message);
            this._GlobalDataService.openSnackBarInfo(e.error.message);
          },
        });
    }

    this._GlobalDataService.stepIndex = event.selectedIndex;

    if (this._GlobalDataService.Campagne.volume) {
      this._GlobalDataService.formEstimate
        .get('volume')
        .setValue(this._GlobalDataService.Campagne.volume, {
          emitEvent: false,
        });
    }

    // Fil d'Ariane
    this._GlobalDataService.checkStepCompletion();
    this.changeStepperStyle();

    switch (event.selectedIndex) {
      case 0:
        //identification campagne
        this._GlobalDataService.referenceDocHelp = '#new_campaign_step1';
        break;
      case 1:
        // Typologie
        this._GlobalDataService.Campagne.VisualisationStep2 = true;
        this._GlobalDataService.referenceDocHelp = '#new_campaign_step3';

        if (this._GlobalDataService.Campagne.CodeTypologie != '') {
          this._CampagneTypologieService.initDataSupport(
            this._GlobalDataService.Campagne
          );
          this._CampagneTypologieService.navigate(
            navPaths.selection,
            this.activatedRoute,
            this.navigationOption
          );
        } else {
          this._CampagneTypologieService.navigate(
            navPaths.format,
            this.activatedRoute,
            this.navigationOption
          );
        }

        break;
      case 2:
        // Créations
        this._GlobalDataService.referenceDocHelp = '#new_campaign_step6';
        break;
      case 3:
        // Personnalisation
        this._GlobalDataService.referenceDocHelp = '#new_campaign_step6';
        break;
      case 4:
        // Planification
        this._GlobalDataService.referenceDocHelp = '#new_campaign_step7';
        break;
      case 5:
        // Adresses
        this._GlobalDataService.referenceDocHelp = '#new_campaign_step8';
        break;
      case 6:
        //Récapitulatif
        this._GlobalDataService.referenceDocHelp = '#new_campaign_step10';
        if (this._GlobalDataService.Campagne.volume) {
          this.estimate();
        }
        break;
      default:
        //identification campagne
        this._GlobalDataService.referenceDocHelp = '#new_campaign_step1';
        break;
    }
  }

  /**
   * Estimate price.
   * Product, delivery priority and volume are necessary to estimate.
   */
  estimate() {
    if (!this._GlobalDataService.CampagneExt.Produit) {
      this._GlobalDataService.alert(
        "Veuillez renseigner la typologie souhaitée afin d'utiliser l'estimation tarifaire."
      );
      return;
    }
    if (!this._GlobalDataService.Campagne.CodeVitesse) {
      this._GlobalDataService.alert(
        "Veuillez renseigner le type de vitesse souhaitée afin d'utiliser l'estimation tarifaire."
      );
      return;
    }
    if (!this._GlobalDataService.Campagne.volume) {
      this._GlobalDataService.alert(
        "Veuillez renseigner le volume souhaitée afin d'utiliser l'estimation tarifaire."
      );
      return;
    }
    let request = this._GlobalDataService.getEstimationRequest();
    if (request) {
      this._EstimationService.put(request).subscribe(
        (response) => this.estimateRresponseHandler(response),
        (error) => this.estimateErrorHandler(error)
      );
    }
  }

  estimateRresponseHandler(response: any) {
    console.log(response);
    if (
      typeof response.response === 'object' &&
      response.response.status === 'SUCCESS'
    ) {
      this._GlobalDataService.Campagne.unitAmountWithoutTax =
        response.chargeList.length > 0
          ? response.chargeList[0].unitAmountWithoutTax
          : '';
      this._GlobalDataService.Campagne.amountWithoutTax =
        response.chargeList.length > 0
          ? response.chargeList[0].amountWithoutTax
          : '';
      this._GlobalDataService.Campagne.showInfo = true;
      this._GlobalDataService.Campagne.showRefresh = true;
      this._GlobalDataService.Campagne.showunitAmountWithoutTax = true;
      this._GlobalDataService.Campagne.showamountWithoutTax = true;
    } else if (response.status === 500) {
      this._GlobalDataService.alert(
        "Le serveur d'estimation tarifaire est indisponible."
      );
    } else {
      this._GlobalDataService.Campagne.unitAmountWithoutTax = '';
      this._GlobalDataService.Campagne.amountWithoutTax = '';
      this._GlobalDataService.alert(
        "Une erreur est survenue . Merci de relancer l'estimation tarifaire.",
        response.response.errorMessage
      );
    }
  }

  estimateErrorHandler(error: any) {
    console.log('errorHandler :' + error);
  }

  submit() {
    //call API
    this.stepper.next();
  }

  ngOnDestroy() {
    if (this.navigationSubscription) this.navigationSubscription.unsubscribe();
    if (this.activeRouteSubscription)
      this.activeRouteSubscription.unsubscribe();
  }

  public campaignNameChange(name: string): void {
    this.campaignName = name;
  }
}
